<template>
  <div class="product-item">
      <div v-if="isLoading" style="margin-bottom: 50px;">
          <v-skeleton-loader
          :type="'card'"
          :animation="true"
          :width="200"
          :height="300"
          theme="dark"
          
          ></v-skeleton-loader>
      </div>
      <div v-else>
          <button class="card product-card-container" type="button" :id='`card_${index}`' :disabled="isCD" style="width: 100%;" @click="refreshData">
              <div class="card-body">
                  <div class="product-header">
                      <div class="left-content product-title">
                          <div class="icon-container red-image">
                          <img :src="option.classImgUrl" alt="Product Icon" class="icon-class" />
                          </div>
                          <span :class="[ 'product-id']"># {{ option.id }}</span>
                      </div>
                      <img v-if="option.elementUrl !== ''" :src="option.elementUrl" alt="Product Icon" class="icon-element-right" :id='`element_${index}`' />
                  </div>
                  <div class="image-container">
                      <!-- <router-link :to="{ name: 'fighter', query: { id: product.id } }"> -->
                      <img :src="option.image" alt="Product Image" class="product-image" :id='`fighter_${index}`'/>
                      <img src="../assets/shadow.png" class="fighter-shadow" alt="Image" />
                  </div>
                  <div class="" style="height: 20px; display: inline-flex; align-items: center;">
                    <!-- <span style="font-size: 14px;"> CD </span> -->
                    <img v-if="option.timeLeft" :src="`../images/icon-cd.png`" class="icon-class">
                    <span> {{ option.timeLeft }}</span>
                  </div>
              </div>
          </button>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { getClassImageUrl, getElementImageUrl } from '@/utils/imageUtils.js';
import { ethers } from 'ethers';
import FighterERC721ABI from '@/contracts/FighterERC721ABI.json';

export default {
  props: {
      option: {
          type: Object,
          required: true
      },
      index: Number
  },
  data() {
      return {
          isLoading: true,
          isCD: false
      }
  },
  methods: {

      async fetchImage() {
          try {
              const response = await axios.get(`https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/fighter/${this.option.id}.json`);
              var imageUrl = response.data.image;
              //classIconUrl.value = response.data.properties.class_id;
              imageUrl = imageUrl.replace("image/4x/", "card/no-bg/");

              // Update the product object with the obtained image URL
              console.log(response);
              //console.log(' fetching elementUrl:' + this.product.id + " - " + response.data.properties.element + " - " + this.elementUrl);
              this.option.image = imageUrl;
              this.option.class_id = response.data.properties.class_id;
              this.option.element_id = response.data.properties.element;

              this.option.classImgUrl = getClassImageUrl(response.data.properties.class_id);
              this.option.elementUrl = getElementImageUrl(response.data.properties.element);

              this.option.timeLeft = await this.getLeftTime();
              const image = new Image();

              image.onload = () => {
                  this.option.image = imageUrl;
                  this.isLoading = false;
              };
              image.src = imageUrl;
          } catch (error) {
              console.error('Error fetching image:', error);
              
              // Handle error loading image if necessary
          }
      },
      refreshData() {
          console.log("masterItem "+this.option.id);
          this.$emit('refreshData', this.option.id);
      },
      async getLeftTime(){
        try{
          const provider = new ethers.providers.JsonRpcProvider(process.env.VITE_RPC_URL);
          const fighterContractAddress = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;
          const fighterContract = new ethers.Contract(fighterContractAddress, FighterERC721ABI, provider);

          const metadata = await fighterContract.getTokenMetadata(this.option.id);
          // console.log("Metadata:", metadata);

          const now = new Date();
          const pastDate = new Date(metadata.last_coach_date.toNumber() * 1000);

          const timeDifference = now - pastDate;
          const millisecondsInAnHour = 1000 * 60 * 60;
          const hoursDifference = timeDifference / millisecondsInAnHour;
          
          // console.log(`Created Date: ${timeDifference} , ${millisecondsInAnHour}`);

          let timeLeft = 24-Math.ceil(hoursDifference);
          if(timeLeft < 0){
            timeLeft = 0;
            return "";
          }
          else{
            this.isCD = true;
            this.$nextTick(() => {
              setTimeout(() => {
                const cardElement = document.getElementById(`card_${this.index}`);
                if(cardElement) {
                  cardElement.style.backgroundColor = "#2f2f2f4d";
                }

                const fighterElement = document.getElementById(`fighter_${this.index}`);
                if(fighterElement) {
                  fighterElement.style.filter = 'grayscale(100%)';
                }

                const elementElement = document.getElementById(`element_${this.index}`);
                if(elementElement) {
                  elementElement.style.filter = 'grayscale(100%)';
                }
              }, 100); 
            });
            return `${timeLeft} Hours left`;
          }
        } catch (error) {
            console.error('Error getLeftTime:', error);
        }
      }
  },
  mounted() {
      // Fetch image URL when the component is mounted
      // console.log("gen1Response ",this.option);
      this.fetchImage();
  }
};
</script>

<style scoped>

.product-item {
  text-align: center;
  margin-bottom: 0px;
}

.product-image {
  width: 160px;
  object-fit: cover;
  margin-bottom: 10px;
}

.product-name {
  font-size: 16px;
  margin-top: 5px;
  text-align: left;
  display: inline-flex;
  /* Set display to inline-flex */
  align-items: center;
  /* Center items vertically */
  justify-content: space-between;
  width: 100%;
}


.product-usd-price {
  margin-top: 0px;
  font-size: 14px;
  color: var(--secondary-text-color);
}

.product-time {
  margin-top: 0px;
  padding-top: 0px;
  font-size: 14px;
  color: var(--secondary-text-color);
}



.product-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.with-class-icon {
  background-image: url('../assets/icon-stats-kroma.png');
  background-size: 20px 20px;
  /* Set the size of your icon */
  background-repeat: no-repeat;
  padding-left: 30px;
  /* Adjust the padding to make space for the icon */
}



.icon-element-right {
  width: 24px;
  height: 24px;
  margin-left: 10px;
  /* Adjust margin to create space between the two icons */
}

.left-content {
  display: flex;
  align-items: center;
}

.link {
  text-decoration: none;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Margin between product image and shadow */
.shadow-image {
  margin-top: 10px;
  /* Adjust margin as needed */
  width: 100px;
  height: 10px;
  object-fit: contain;
  /* Ensure the shadow image fits within its container */
}


.product-card-container:hover {
  background: linear-gradient(180deg, rgba(13, 10, 22, 0.6), rgba(255, 255, 255, 0.13)); 
}

.product-card-container:disabled:hover {
  /* background: var(--secondary-background-color); */
  background: #2f2f2f4d;
  cursor: auto;
}

.fighter-shadow {
  position: relative; 
  top: -30px;
  width: 100px; 
  height: 10px;
}

.product-title{
  background-color: var(--soft-background-color);
  padding: 2px 6px;
  border-radius: 5px;
}

.text-element-ROCK {
  color: rgb(232, 163, 36);
}
.text-element-WATER {
  color: rgb(55, 118, 226);
}

.text-element-DARK {
  /* -webkit-text-stroke-width: 0.1px; 
  -webkit-text-stroke-color: white; */
  color: rgb(158, 84, 208)}
.text-element-CHAOS {
  color: rgb(211, 80, 211);
}
.text-element-FIRE {
  color: rgb(234, 102, 62);
}
.text-element-METAL {
  color: rgb(179, 187, 198);
}
.text-element-WIND {
  color: rgb(72, 197, 220);
}
.text-element-EARTH {
  color: rgb(73, 208, 73);
}
.text-element-LIGHT {
  color: rgb(235, 233, 233);
}

.red-image {
filter: hue-rotate(45deg); /* Change the hue to red (45 degrees) */
}

@media (max-width: 768px) {
  .product-image { 
      width: 120px;

      margin-bottom: 0;
      padding: 0 7px;
  }

  .card-body {
      padding: 0.5rem 0.5rem;
  }

  .product-header {
      margin: 0;
  }

  .icon-class {
      margin-right: 5px;
  }
  .icon-element-right {
      margin-left: 5px;
      width: 20px;
      height: 20px;
  }

  .fighter-shadow {
      top: -10px;
      width: 70px; 
      height: 10px;
  }

}
</style>