<template>
  <div class="page-content content-wrap">

    <div class="main-content">
      <div class="d-flex align-items-center justify-content-between default-top-space head-title">
        <div>
          <h1>Scholarship</h1>
          <!-- <p>Description</p> -->
        </div>
      </div>
      <div class="d-flex" style="flex-direction: column; gap: 20px;">
        <div class="d-flex justify-content-end">
          <button 
            class="btn" 
            style="border: 1px solid white;" 
            data-bs-toggle="modal" 
            data-bs-target="#editScholarshipPopup"
            @click="setSelectedIndex(-1)">
              Add schorlar
          </button>
        </div>
        <div v-for="(scholar, index) in totalScholar" style="width: 100%; border: 1px solid white; border-radius: 8px; padding: 10px; display: flex; flex-direction: column; gap: 10px;">
          <div style="padding: 5px 10px;">
            <div class="detail-row">
              <div class="d-flex" style="gap: 15px;">
                <div class="crop-container crop-container-profile" style="">
                  <img :src="require(`@/assets/kf-logo-2.png`)" 
                  alt="profile">
                </div>
                <div class="detail-column" style="">
                  <div class="text-section" style="font-size: 20px;">{{ scholar.name }}</div>
                  <div>
                    <span class="text-section">Wallet Address :</span> {{ scholar.wallet }}
                  </div>
                  <div>
                    <span class="text-section">Winrate :</span> {{ scholar.winrate }}
                  </div>
                </div>
              </div>
              <div class="detail-column" style="">
                <div class="d-flex text-center" style="gap: 10px;">
                  <div>
                    <span class="text-section">Fighter Assigned :</span> {{ scholar.fighters? scholar.fighters.length:0 }}
                  </div>
                  <!-- <button class="btn btn-mini btn-custom d-flex justify-content-center align-items-center" style="font-size: 24px;">+</button> -->
                </div>
                <div class="d-flex text-center" style="gap: 10px;">
                  <div>
                    <span class="text-section">Share Rate :</span> {{ scholar.share_rate }}%
                  </div>
                  <!-- <button class="btn btn-mini btn-custom d-flex justify-content-center align-items-center" style="">
                    <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="white">
                      <path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z"/>
                    </svg>
                  </button> -->
                </div>
              </div>
              <div class="detail-column" style="">
                <div>
                  <span class="text-section">Total Jab Produce :</span> {{ scholar.jab_produce }}
                </div>
                <div>
                  <span class="text-section">Total Jab :</span> {{ scholar.jab }}
                </div>
                <button class="btn btn-custom" style="margin-top: 10px;">Claim Rewards</button>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <button 
                  class="btn btn-custom d-flex justify-content-center align-items-center" 
                  style="" 
                  id="editBtn" 
                  data-bs-toggle="modal" 
                  data-bs-target="#editScholarshipPopup"
                  @click="setSelectedIndex(index)">
                  <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="white">
                    <path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z"/>
                  </svg>
                  <span class="text-section">Edit</span>
                </button>
              </div>
              <button 
                :class="['collapse-trigger-toggle', { 'collapse-trigger-toggle-transform': fightersCollapsed[index].isCollapsed }]" 
                data-bs-toggle="collapse" 
                :data-bs-target="'#fightersContent' + index"
                aria-expanded="false" 
                :aria-controls="'#fightersContent' + index"
                @click="toggleCollapse(index)">
                <svg xmlns="http://www.w3.org/2000/svg" height="60px" viewBox="0 -960 960 960" width="60px" fill="#e8eaed" style="right: 0">
                  <path d="M480-360 280-559.33h400L480-360Z"/>
                </svg>
              </button>
            </div>
          </div>
          <div class="collapse" :id="'#fightersContent' + index" :class="{ show: fightersCollapsed[index].isCollapsed }" style="width: 100%; background-color: #181822; border-radius: 5px; padding: 15px;">
            <div class="fighter-content">
              <div class="d-flex" style="gap: 20px; flex-wrap: wrap;">
                <div v-for="i in 5" class="crop-container crop-container-fighter" style="">
                  <!-- <img :src="require(`@/assets/kf-logo-2.png`)" 
                  alt="profile"> -->
                </div>
              </div>
              <button class="btn" style="border: 1px solid white;">Assign Fighters</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <EditScholarshipPopup :data="getScholarshipData()" @updateEditData="updateEditData"/>
</template>

<script>
import EditScholarshipPopup from "@/components/popup/EditScholarshipPopup.vue";

export default {
  components: {
    EditScholarshipPopup
  }, 
  data() {
    return {
      fightersCollapsed: [{index: 0, isCollapsed: true}],
      totalScholar: [{name: 'A', wallet: '0xAAA', winrate: 10, share_rate: 10, jab_produce: 10, jab: 10, fighters: null}],
      selectedIndex: 0
    }
  },
  methods: {
    toggleCollapse(index) {
      this.fightersCollapsed[index].isCollapsed = !this.fightersCollapsed[index].isCollapsed;
    },
    clickAddSchorlar(){
      this.totalScholar.push({name: 'B', wallet: 'xxx', winrate: 0, share_rate: 0, jab_produce: 0, jab: 0, fighters: null});
      this.fightersCollapsed.push({index: this.totalScholar.length-1, isCollapsed: true});
    },
    setSelectedIndex(index){
      this.selectedIndex = index;
    },
    getScholarshipData(){
      console.log('this.selectedIndex '+this.selectedIndex);
      if(this.selectedIndex == -1){
        return { name: 'Name', 
                wallet: '', 
                winrate: 0, 
                share_rate: 0, 
                jab_produce: 0, 
                jab: 0, 
                fighters: null 
              };
      }
      else{
        return this.totalScholar[this.selectedIndex];
      }
    },
    updateEditData(newData){
      if(this.selectedIndex == -1){
        this.totalScholar.push(newData);
        this.fightersCollapsed.push({index: this.totalScholar.length-1, isCollapsed: true});
      }
      else{
        this.totalScholar[this.selectedIndex] = newData;
      }
    }
  }
};
                    
</script>

<style scoped>
.crop-container-profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid white;
}

.crop-container-fighter {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    background-color: #100f17;
}

.crop-container {
    overflow: hidden;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    margin: 0;
}

.crop-container img {
    width: auto; 
    height: 100%;
}

.text-section {
  font-weight: 500;
}

.btn-mini {
  padding: 0;
  margin: 0;
  height: 30px;
  width: 30px;
}

.btn-custom {
  border: 1px solid white;
}

.text-center {
  align-items: center;
}

.detail-column {
  display: flex;
  flex-direction: column; 
  gap: 3px;
}

.detail-row {
  display: flex;
  justify-content: space-between;
}

.collapse-trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-right: 10px;
}

.collapse-trigger-toggle {
  transition: transform 0.3s;
}

.collapse-trigger-toggle.collapse-trigger-toggle-transform {
  transform: rotate(180deg);
}

.collapse.show {
  display: block;
}

.fighter-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 768px) {
  .detail-row {
    flex-direction: column;
  }

  .fighter-content {
    flex-direction: column;
    gap: 15px;
  }
}


</style>