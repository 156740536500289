<template>
  <div class="product-item">
    <div
      v-if="isLoading"
      style="margin-bottom: 50px;"
    >
      <v-skeleton-loader
        :type="'card'"
        :animation="true"
        :width="200"
        :height="300"
        theme="dark"
      />
    </div>
    <div v-else style="width: 100%;">
      <div :class="combinedClass" id="rowElement">
        <div class="col-4">
            <div style="display: flex; gap: 10px; align-items: center; height: 90px;">
              <div style="height: 100%; width: 90px; margin-left: 10px;">
                <div class="crop-container" style="display: flex; justify-content: center; align-items: center; margin: 0;">
                  <img :id='`orderImg_${index}`' :src="order.image?? ''" alt="order Image">
                </div>
              </div>
              <span class="badge bg-custom-secondary">
                  # {{ order?.tokenId?? '' }}
              </span>
            </div>
        </div>
        <div class="col-2" style="display: flex; align-items: center;"> {{ order?.amount?? 0 }} </div>
        <div class="col-3" style="display: flex; align-items: center;">
            <div style="display: flex; gap: 5px; align-items: center;">
                <img
                src="~@/assets/currency/JAB.png"
                alt="no data"
                style="width: 20px; height: auto;"
                >
                <span class="" style="font-weight: 500;">
                  {{ order?.highestPrice?? 0 }}
                </span>
            </div>
        </div>
        <div class="col-3" style="display: flex; align-items: center; justify-content: flex-end; padding-right: 30px;"> 
          <div style="width: 100%;">
            <button 
                class="btn default-button"
                @click="acceptOffer"
                :disabled="isAcceptLoading || acceptBtnDisable"
                style="width: 90%;"
              >
                <div style="width:100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                  <span v-if="isAcceptLoading"><i class="fa fa-circle-o-notch fa-spin" /></span>
                  <span v-else> Accept Highest Offer </span>
                </div>
            </button>
          </div>
        </div>
    </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { getClassImageUrl } from '@/utils/imageUtils.js';
import NFTTicketPopup from '@/components/popup/NFTTicketPopup.vue';
import {useToast} from 'vue-toast-notification';
import { ethers } from 'ethers';
import { createRaribleSdk } from "@rarible/sdk";
import { toItemId, toOrderId } from "@rarible/types";

export default {
    components: {
        NFTTicketPopup,
    },
    props: {
        product: {
            type: Object,
            required: true
        },
        index: Number,
    },
    computed: {
      existingClass() {
        return 'row list-row'; // Replace with your actual existing class
      },
      additionalClass() {
        return (this.index % 2 === 0) ? 'even-class' : 'odd-class';
      },
      combinedClass() {
        return [this.existingClass, this.additionalClass];
      }
    },
    data() {
        return {
            showModal: Boolean,
            isCancelLoading: false,
            isLoading: false,
            order: {},
            isAcceptLoading: false,
            acceptBtnDisable: true
        }
    },
    mounted() {
        this.fetchNftData();
        // console.log("indexxxxxxx ", this.product);
    },
    methods: {
        async fetchNftData() {
          this.isLoading = true;
          try{
            const fighterCollection = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;
            const ticketCollection = process.env.VITE_TICKET_CONTRACT_ADDRESS;
            var image = '';
            var rest;

            if(this.product.item.data.itemCollection.id.slice(6).toLowerCase() == fighterCollection.toLowerCase()){
              const url = `https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/fighter/${this.product.item.data.tokenId}.json`;
              rest = await axios.get(url);
              // console.log("rest:", rest);

              image = rest.data.properties.fighter_sheet_url;
            }
            else{
              image = this.product.item.data.meta.content[0].url;
            }

            this.product.activities.sort((a, b) => {  
                const price1 = Number(b.price);
                const price2 = Number(a.price);
                return price1 - price2;
            });

            // console.log(' this.product.activities ', this.product.activities);

            this.order = {
              image: image,
              tokenId: this.product.item.data.tokenId,
              amount: this.product.activities.length,
              highestPrice: this.product.activities[0].price
            }

            if(this.product.activities.length > 0){
              this.acceptBtnDisable = false;
            }
            else{
              this.acceptBtnDisable = true;
            }
            
            this.isLoading = false;
            
            this.$nextTick(() => {
              setTimeout(() => {
                      const getElement = document.getElementById(`orderImg_${this.index}`);
                      var crop = {};
                      if(this.product.item.data.itemCollection.id.slice(6).toLowerCase() == fighterCollection.toLowerCase()){
                        crop = this.getImageCropSize("fighter", rest.data.properties.class_id);
                      }
                      else if(this.product.item.data.itemCollection.id.slice(6).toLowerCase() == ticketCollection.toLowerCase()){
                        crop = this.getImageCropSize("ticket");
                      }
                      else{
                        crop = this.getImageCropSize("equipment");
                      }

                      if (getElement && crop) {
                        getElement.style.top = crop.top;
                        getElement.style.left = crop.left;
                        getElement.style.height = crop.height;
                      }
                    }, 100); // Adjust the timeout if necessary
            });
          }
          catch (error) {
            console.error(`Error fetching data for token ID ${this.product.item.data.tokenId}:`, error);
            this.isLoading = false;
          }
        },
        getImageCropSize(type, classId=0){
          if(type == "fighter"){
            switch(classId){
                case 1: // ard
                case 4: return { top: '-60px', left: '-60px', height: '800%'}; break; // stk
                case 2: return { top: '-35px', left: '-60px', height: '800%'}; break; // bdg
                case 7: return { top: '-45px', left: '-70px', height: '800%'}; break; // dty
                default: return { top: '-70px', left: '-60px', height: '800%'}; break;
            }
          }
          else if(type == "ticket"){
            return { top: 'auto', left: 'auto', height: '80%'};
          }
          else{
            return { top: 'auto', left: 'auto', height: '150%'};
          }
        },
        async acceptOffer(){
          try{
            this.isAcceptLoading = true;
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = await provider.getSigner();
            const apiKey = process.env.VITE_RARIBLE_API_KEY; 
            const raribleSdk = createRaribleSdk(signer, "prod", { apiKey });

            const item = await raribleSdk.apis.item.getItemById({ itemId: toItemId(`${this.product.item.data.itemCollection.id}:${this.product.item.data.tokenId}`) });

            console.log("bodyRequest: item", item);
            const bodyRequest = {
              orderId: item.bestBidOrder.id,
              amount: 1,
            };

            // console.log("bodyRequest: item", item.bestBidOrder.id);

            var order = await raribleSdk.order.acceptBid(bodyRequest);
            // console.log("order:", order);
            var result = await order.wait()
            // console.log("result:", result);

            useToast().open({
                            message: 'Successfully accept offer.',
                            position: 'top-right',
                            type: 'success',
                        });

            this.isAcceptLoading = false;
            this.refreshData();
          }
          catch(error){
            console.error('Error acceptOffer:', error);
            const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
            useToast().open({
                            message: 'Error: ' + errorMessage,
                            position: 'top-right',
                            type: 'error',
                        });
            this.isAcceptLoading = false;
          }
        },
        refreshData() {
          this.$emit('refreshData');
        }
    }
};
</script>
  
<style scoped>
.list-row {
        background-color: var(--primary-background-color); 
        padding: 10px 0; 
        border-top: 0px solid #24203a;
        margin: 0;
    }

.crop-container {
    margin: 5px 25px;
    width: 80px; /* Width of the cropped area */
    height: 80px; /* Height of the cropped area */
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    /* border: 0.5px solid darkslateblue; */
}
.crop-container img {
    top: -50px; /* Adjust this to position the image within the cropped area */
    left: -50px; /* Adjust this to position the image within the cropped area */
    width: auto; /* Ensure the image scales properly */
    height: 800%; /* Ensure the image scales properly */
    /* background-color: #2c2a3d; */
    position: absolute;
}

.even-class {
  background-color: var(--secondary-background-color); /* Example styling for even indices */
}

.odd-class {
  background-color: unset; /* Example styling for odd indices */
}

@media (max-width: 768px) {
    
}

</style>