<template>
  <div class="page-content content-wrap">
    <!-- <img class="content-bg" src="/src/assets/backgrounds/background-default.png" alt="">-->

    <div class="main-content">
      <div class="collection-info">
        <div class="d-flex align-items-left">
          <img
            :src="`../images/item/FIGHTER_TICKET.png`"
            alt="NFT Icon"
            class="header-icon mr-3"
          >
          <h1>Tickets</h1>
        </div>
        <!-- <div class="text-secondary">
          The Fighter is represented as a NFT character, a unique digital asset that can
          be
          <br>battled, coach, collected, and even used to earn resources & collectibles.
        </div> -->
        <div class="d-flex justify-content-between align-items-center default-row flex-wrap">
          <div v-if=" total_supply">
            <h4>{{ total_supply }} Tickets </h4>
          </div>
        </div>


        <div class="card">
          <div class="card-body">
            <div v-if="isLoading">
              <div style="display: flex; flex-direction: row; overflow-x: auto;">
                <div
                  v-for="index in 6"
                  :key="index"
                >
                  <v-skeleton-loader
                    :type="'card'"
                    :animation="true"
                    :width="200"
                    :height="360"
                    theme="dark"
                    style="margin-right: 24px;"
                  />
                </div>
              </div>
            </div>
            <div v-else>
              <div v-if="products.length > 0" class="product-list">
                <ProductItem
                  v-for="(product, index) in products"
                  :key="product.id"
                  :product="product"
                />
              </div>
              <div v-else>
                <img
                  src="~@/assets/no-data.png"
                  alt="no data"
                  style="display: block; margin-left: auto; margin-right: auto; width: 160px;"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import ProductItem from '@/components/NftItem.vue'; // Update the path based on your project structure
import { timeAgo } from '@/utils/timeUtils.js';
import { convertToUSD } from '@/utils/exchangeUtils.js';
import { useExchangeStore } from '@/stores/exchangeRate.ts';
import { getClassImageUrl, getElementImageUrl } from '@/utils/imageUtils.js';
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';

import Web3 from 'web3';
import NFTContractABI from '@/contracts/NFTContractABI.json';

import { useStore } from '@/stores/index.ts';

import {shortenedWalletAddress_rarible} from '@/utils/walletUtils.js';

export default {
    components: {
        ProductItem,
        getClassImageUrl,
        
    }, data() {
        return {
            products: [],
            
            selectedOrderBy: 1,
            isLoading: true,
            total_supply: null
            
        };
    },
    mounted() {
        this.fetchFighterData();
        this.fetchTicketStats();
    },
    methods: {
        async fetchTicketStats() {
            const web3 = new Web3("https://polygon-mainnet.infura.io/v3/851bc9b8af734a6a999810181589649a");
            const products = [];
            try{

                const nftContract = new web3.eth.Contract(NFTContractABI, process.env.VITE_TICKET_CONTRACT_ADDRESS);

                const totalSupply = await nftContract.methods.totalSupply().call();
                console.log(`Total NFTs in the contract: ${totalSupply}`);
                this.total_supply = totalSupply;

            } catch (error) {
                console.error('Error fetching total supply:', error);
            }
        },
        async fetchFighterData() {
            try {
                const store = useExchangeStore();
                store.fetchExchangeRates();
                const wallet = useStore().getWalletAddress;

                const ethToUsdExchangeRate = store.getEthToUsdExchangeRate;
                const maticToUsdExchangeRate = store.getMaticToUsdExchangeRate;

                // const response = await axios.get('https://api.opensea.io/api/v2/listings/collection/kf-genesis-ticket/all?limit=100', {
                //     headers: {
                //         'accept': 'application/json',
                //         'x-api-key': process.env.VITE_OPENSEA_API_KEY
                //     }
                // });

                const ticketCollection = process.env.VITE_TICKET_CONTRACT_ADDRESS;
                const ticketApiUrl = `https://api.rarible.org/v0.1/activities/byCollection?type=LIST&type=SELL&type=CANCEL_LIST&collection=KROMA:${ticketCollection}&size=256`;
                const ticketResponse = await axios.get(ticketApiUrl, {
                    headers: {
                        'accept': 'application/json',
                        'x-api-key': process.env.VITE_RARIBLE_API_KEY
                    }
                });

                console.log("ticketResponse market" , ticketResponse);

                const combinedData = [...ticketResponse.data.activities];

                const recentSell = combinedData.filter(activity => activity['@type'] == 'SELL');
                const recentList = combinedData.filter(activity => activity['@type'] == 'LIST');
                const cancelList = combinedData.filter(activity => activity['@type'] == 'CANCEL_LIST');

                // console.log("recentSell: " , recentSell);
                // console.log("recentList: " , recentList);

                const sellMap = new Map();
                recentSell.forEach(sell => {
                  const tokenId = sell.nft.type.tokenId;
                  const lastUpdatedAt = new Date(sell.lastUpdatedAt);
                  if (!sellMap.has(tokenId) || new Date(sellMap.get(tokenId).lastUpdatedAt) < lastUpdatedAt) {
                    sellMap.set(tokenId, sell);
                  }
                });

                const cancelMap = new Map();
                cancelList.forEach(sell => {
                  const tokenId = sell.make.tokenId;
                  const lastUpdatedAt = new Date(sell.lastUpdatedAt);
                  if (!cancelMap.has(tokenId) || new Date(cancelMap.get(tokenId).lastUpdatedAt) < lastUpdatedAt) {
                    cancelMap.set(tokenId, sell);
                  }
                });

                // Remove items in recentList that are also in recentSell
                const filteredList = recentList.filter(list => {
                  const tokenId = list.make.type.tokenId;
                  const lastUpdatedAt = new Date(list.lastUpdatedAt);
                  const sellItem = sellMap.get(tokenId);
                  const cancelItem = cancelMap.get(tokenId);

                  return (!sellItem  || lastUpdatedAt > new Date(sellItem.lastUpdatedAt)) && (!cancelItem || lastUpdatedAt > new Date(cancelItem.lastUpdatedAt) );
                });

                console.log("filteredList: " , filteredList);

                filteredList.sort((a, b) => {  
                    const date1 = new Date(b.lastUpdatedAt);
                    const date2 = new Date(a.lastUpdatedAt);
                    return date1 - date2;
                });

                this.products = filteredList.map(listing => {
                  const date = new Date(listing.lastUpdatedAt);
                  const unixTimestamp = Math.floor(date.getTime() / 1000);
                  const tokenID = listing.make.type.tokenId;
                  const dateString = timeAgo(unixTimestamp);
                  const price = parseFloat(listing.price);
                  const usdPrice = convertToUSD(price, "JAB", ethToUsdExchangeRate, maticToUsdExchangeRate);
                  const collection = listing.make.type.collection.slice(6).toLowerCase();
                  const isFighterProduct = false;

                  return {
                    id: tokenID,
                    price: price,
                    currency: "JAB",
                    listDate: listing.lastUpdatedAt,
                    dateString : dateString,
                    usdPrice : 0,
                    isFighter: isFighterProduct,
                    itemId: (isFighterProduct)? 0:100,
                    isOwner: shortenedWalletAddress_rarible(listing.maker) == wallet
                  };
                });

                // const baseURI = 'https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/ticket/gen1_normal_ticket.json';
                // const nft = await axios.get(baseURI);
                // Extract relevant data from the API response and update recentProducts
                // this.products = response.data.listings.map(listing => {
                //     const tokenID = listing.protocol_data.parameters.offer[0].identifierOrCriteria;
                //     const dateString = timeAgo(listing.protocol_data.parameters.startTime);
                //     const price = parseFloat(listing.price.current.value) / Math.pow(10, listing.price.current.decimals);
                //     const usdPrice = convertToUSD(price, listing.price.current.currency, ethToUsdExchangeRate, maticToUsdExchangeRate);
                //     //console.log("usdPrice" + usdPrice);
                //     return {
                //         id: tokenID,
                //         itemId: 100,
                //         price: price,
                //         currency: listing.price.current.currency,
                //         listDate: listing.protocol_data.parameters.startTime,
                //         dateString: dateString,
                //         usdPrice: usdPrice,
                //         image: nft.data.image,
                //         type: nft.data.name,
                //         description: nft.data.description,
                //         isOwner: false
                //     };
                // });

                // console.log("selectedClasses ticket" + response.data);




                //this.products = this.products.slice(0, 50);
                this.isLoading = false;
            } catch (error) {
                console.error('Error fetching data from OpenSea:', error);
            }
        }
    }
}
</script>
  
  
<style scoped>
/* Add your component-specific styles here */

.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /* Adjust minmax values as per your design */
    gap: 20px;
    /* Adjust gap between grid items */
}

.product-item {
    /* Add additional styling for each grid item if needed */
}

.text-secondary {
    font-size: 16px;
    color: var(--secondary-text-color) !important;
    font-weight: 300;
}


</style>