<!-- FightersTab.vue -->
<template>
  <div>
    <div class="d-flex align-items-center justify-content-between default-row">
      <h4>{{ products.length }} Fighters </h4>
      <div class="d-flex align-items-center justify-content-between ">
        <div class="dropdown">
          <button
            id="classDropdown"
            class="btn btn-secondary dropdown-toggle dropdown-filter-toggle"
            style="margin-right: 8px;"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Class
          </button>
          <ul
            class="dropdown-menu"
            aria-labelledby="classDropdown"
          >
            <li
              v-for="classOption in classOptions"
              :key="classOption.id"
            >
              <input
                v-model="selectedClasses"
                class="form-check-input"
                type="checkbox"
                :value="classOption.id"
              >
              <label class="form-check-label">{{ classOption.name }}</label>
            </li>
          </ul>
        </div>

        <div class="dropdown">
          <button
            id="classDropdown"
            class="btn btn-secondary dropdown-toggle dropdown-filter-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ getSelectedOrderByText() }}
          </button>
          <ul
            class="dropdown-menu"
            aria-labelledby="orderByDropdown"
          >
            <li
              v-for="orderByOption in orderByOptions"
              :key="orderByOption.id"
            >
              <a
                class="dropdown-item"
                href="#"
                @click="selectOrderBy(orderByOption.id)"
              >{{
                orderByOption.name
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div v-if="products.length > 0">
          <div class="product-list">
            <ProductItem
              v-for="(product, index) in products"
              :key="index"
              :product="product"
            />
          </div>
        </div>
        <div v-else>
          <img
            src="~@/assets/no-data.png"
            alt="no data"
            style="display: block; margin-left: auto; margin-right: auto; width: 160px;"
          >
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import Web3 from 'web3';
import ProductItem from '@/components/ProductFighterItem.vue'; // Update the path based on your project structure
import { timeAgo, convertToTimestamp } from '@/utils/timeUtils.js';
import { convertToUSD } from '@/utils/exchangeUtils.js';
import { useExchangeStore } from '@/stores/exchangeRate.ts';
import { useStore } from '@/stores/index.ts';


import { ref, onMounted, watch } from 'vue';
import axios from 'axios';

export default {
    components: {
        ProductItem,
    },
    props: {
        amounts: {}
    }, data() {
        return {
            products: [],
            classOptions: [
                { id: 1, name: 'All-Rounder' },
                { id: 2, name: 'Acrobat' },
                { id: 3, name: 'Bodyguard' },
                { id: 4, name: 'Brawler' },
                { id: 5, name: 'Destroyer' },
                { id: 6, name: 'Duelist' },
                { id: 7, name: 'Stalker' },
                { id: 8, name: 'Tricker' },
                // Add more class options as needed
            ],
            selectedClasses: [], // Store selected class IDs
            orderByOptions: [
                { id: 1, name: 'Latest' },
                { id: 2, name: 'Lowest Price' },
                { id: 3, name: 'Highest Price' },
            ],
            selectedOrderBy: 1,
        };
    },
    mounted() {

        this.store = useStore();
        if(this.store.getWalletAddress!=''){
            this.fetchFighterData();
        }else{
            watch(() => this.store.getWalletAddress, () => {
                this.fetchFighterData();
            });
        }
        

        watch(
            () => this.selectedClasses,
            () => {
                this.fetchFighterData();
            }
        );
    },
    methods: {
        activateTab(tabName) {
            this.activeTab = tabName;
        },
        selectOrderBy(orderById) {
            this.selectedOrderBy = orderById;
            this.applySorting();
        },
        getSelectedOrderByText() {
            const selectedOption = this.orderByOptions.find(option => option.id === this.selectedOrderBy);
            return selectedOption ? selectedOption.name : '';
        },
        applySorting() {
            if (this.selectedOrderBy == 2) {
                this.products.sort((a, b) => {
                    return a.usdPrice - b.usdPrice;
                });

            } else if (this.selectedOrderBy == 3) {
                this.products.sort((a, b) => {
                    return b.usdPrice - a.usdPrice;
                });
            } else {
                this.products.sort((a, b) => {
                    return b.listDate - a.listDate;
                });
            }
        },
        async fetchFighterData() {
            try {
                const exchangeStore = useExchangeStore();
                exchangeStore.fetchExchangeRates();
                const store = useStore();

                const apiKey = process.env.VITE_RARIBLE_API_KEY; // Replace with your API key
                const accountAddress = store.getWalletAddress;
                console.log("accountAddress" + accountAddress);
                // const collection = 'kf-genesis-fighter';
                // const apiUrl = `https://api.opensea.io/api/v2/chain/matic/account/${accountAddress}/nfts?collection=${collection}`;
                const collection = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;
                const requestBody = {
                    size: 50,
                    filter: {
                        blockchains: ['KROMA'],
                        owners: [`ETHEREUM:${accountAddress}`],
                        collections: [`KROMA:${collection}`]
                    }
                };
                console.log("gen1 requestBody ",requestBody);
                const apiUrl = `https://api.rarible.org/v0.1/ownerships/search`;
                console.log("gen1Response ",apiUrl);
                const response = await axios.post(apiUrl, requestBody, {
                    headers: {
                        'accept': 'application/json',
                        'x-api-key': process.env.VITE_RARIBLE_API_KEY
                    }
                });
                console.log("gen1Response ",response);

                // const apiGenXUrl = `https://api.opensea.io/api/v2/chain/matic/account/${accountAddress}/nfts?collection=kf-genx-fighter`;
                // const collectionGenX = '0xd9bb9824a1Ad49E1B967f719807d82110fbED28E';
                // const requestBodyGenX = {
                //     size: 50,
                //     filter: {
                //         blockchains: ['POLYGON'],
                //         owners: [`ETHEREUM:${accountAddress}`],
                //         collections: [`POLYGON:${collectionGenX}`]
                //     }
                // };
                // const apiGenXUrl = `https://api.rarible.org/v0.1/ownerships/search`;
                // const genXResponse = await axios.post(apiGenXUrl, requestBodyGenX, {
                //     headers: {
                //         'accept': 'application/json',
                //         'x-api-key': 'b11d4b91-8972-4bc5-9a2f-44ffb4dc16a5'
                //     }
                // });

                // console.log("genXResponse ",genXResponse);

                const ethToUsdExchangeRate = exchangeStore.getEthToUsdExchangeRate;
                const maticToUsdExchangeRate = exchangeStore.getMaticToUsdExchangeRate;

                // const combinedData = [...response.data.nfts, ...genXResponse.data.nfts];
                const combinedData = [...response.data.ownerships];
                // Extract relevant data from the API response and update recentProducts
                this.products = combinedData.map(nft => {
                    const tokenID = nft.tokenId;
                    const dateString = timeAgo(convertToTimestamp(nft.lastUpdatedAt));
                    const price = 0;
                    const usdPrice = 0;
                
                    return {
                        id: tokenID,
                        price: price,
                        currency: '',
                        listDate: '',
                        dateString: dateString,
                        usdPrice: usdPrice,
                        isOwner: true,
                        isListing: Boolean(nft.bestSellOrder)
                    };
                });

                /*console.log("selectedClasses" + this.selectedClasses);
                this.products = this.products.filter(product => {
                    if (this.selectedClasses.length > 0) {
                        return this.selectedClasses.includes(product.class_id);
                    }
                    return true;
                });*/

                this.products.sort((a, b) => {
                    return b.listDate - a.listDate;
                });
                this.amounts["fighter"] =  this.products.length;
                //this.products = this.products.slice(0, 36);
            } catch (error) {
                console.error('Error fetching data from OpenSea:', error);
            }
        }
    }
}
</script>

<style scoped>
/* Add your component-specific styles here */

.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}
</style>
  