<template>
  <div class="page-content content-wrap">

    <div class="main-content">
      <div class="d-flex align-items-left">
        <img
          src="../assets/icon-dojo.png"
          alt="NFT Icon"
          class="header-icon mr-3"
          style="width:50px;"
        >
        <h1>Dojo (Simulation)</h1>
      </div>
      <div
        class="no-space"
        style="display: flex; flex-direction: column;"
      >
        <form @submit.prevent="Coach">
          <div
            class="row no-space material-container"
            style="height: auto;"
          >
            <div class="material-box">
              <div style="font-size: 20px; font-weight: bold; margin-bottom: 10px;">
                Master 1
              </div>
              <div style="display: flex; width: 72.5%; display: inline-flex; justify-content: center; gap: 10px; align-items: center; margin-bottom: 15px;">
                <div style="width: auto; white-space: nowrap;">
                  Token ID
                </div>
                <form class="input-form">
                  <div
                    class=""
                    style="flex: 5 1;"
                  >
                    <div
                      class=""
                      style="box-sizing: border-box;"
                    >
                      <div
                        class=""
                        style="position: relative;"
                      >
                        <input
                          id="token_master1"
                          v-model="token_master1"
                          type="text"
                          class="input-field"
                          placeholder="Enter token ID"
                          required
                          @input="GenereateMasterImage(1)"
                        >
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="material-use">
                <img
                  v-if="isShow_img_master1"
                  id="image_master1"
                  class="material-icon"
                  alt="master 1 image"
                >
                <div v-else>
                  <span v-if="isLoading_img_master1"><i class="fa fa-circle-o-notch fa-spin" /></span>
                  <span
                    v-else
                    style="height: auto;"
                  >Master 1 Image</span>
                </div>
              </div>
            </div>
            <div
              class="material-box"
              style="display: flex; justify-content: center; width: 400px; padding: 0; gap: 5px;"
            >
              <div class="row gx-3 info-row">
                <div
                  class="col text-end info-img-display"
                  style="justify-content: flex-end;"
                >
                  <img
                    v-if="master1?.properties?.class_name?? '' !== ''"
                    :src="GetClassImage(master1.properties.class_id)"
                    alt="Class Icon"
                    class="info-img-icon"
                  >
                  {{ master1?.properties?.class_name?? "" }}
                </div>
                <div class="col-5 text-center info-text">
                  Class
                </div>
                <div
                  class="col text-start info-img-display"
                  style="justify-content: flex-start;"
                >
                  <img
                    v-if="master2?.properties?.class_name?? '' !== ''"
                    :src="GetClassImage(master2.properties.class_id)"
                    alt="Class Icon"
                    class="info-img-icon"
                  >
                  {{ master2?.properties?.class_name?? "" }}
                </div>
              </div>
              <div class="row gx-3 info-row">
                <div
                  class="col text-end info-img-display"
                  style="justify-content: flex-end;"
                >
                  <img
                    v-if="GetElementImage(master1?.properties?.element?? '') !== ''"
                    :src="GetElementImage(master1.properties.element)"
                    alt="Element Icon"
                    class="info-img-icon"
                  >
                  {{ master1?.properties?.element?? "" }}
                </div>
                <div class="col-5 text-center info-text">
                  Element
                </div>
                <div
                  class="col text-start info-img-display"
                  style="justify-content: flex-start;"
                >
                  <img
                    v-if="GetElementImage(master2?.properties?.element?? '') !== ''"
                    :src="GetElementImage(master2.properties.element)"
                    alt="Element Icon"
                    class="info-img-icon"
                  >
                  {{ master2?.properties?.element?? "" }}
                </div>
              </div>
              <div class="row gx-3 info-row">
                <div
                  class="col text-end info-img-display"
                  style="justify-content: flex-end;"
                >
                  <img
                    v-if="(master1?.properties?.spirit_socket_1?? 0 > 0) && (master1?.properties?.spirit_socket_1?? 0 < 6)"
                    :src="`/images/runes/shape-${master1.properties.spirit_socket_1}.png`"
                    alt="Spirit socket 1 Icon"
                    class="info-img-icon"
                  >
                  <img
                    v-if="(master1?.properties?.spirit_socket_2?? 0 > 0) && (master1?.properties?.spirit_socket_2?? 0 < 6)"
                    :src="`/images/runes/shape-${master1.properties.spirit_socket_2}.png`"
                    alt="Spirit socket 2 Icon"
                    class="info-img-icon"
                  >
                </div>
                <div class="col-5 text-center info-text">
                  Spirit Socket
                </div>
                <div
                  class="col text-start info-img-display"
                  style="justify-content: flex-start;"
                >
                  <img
                    v-if="(master2?.properties?.spirit_socket_1?? 0 > 0) && (master2?.properties?.spirit_socket_1?? 0 < 6)"
                    :src="`/images/runes/shape-${master2.properties.spirit_socket_1}.png`"
                    alt="Spirit socket 1 Icon"
                    class="info-img-icon"
                  >
                  <img
                    v-if="(master2?.properties?.spirit_socket_2?? 0 > 0) && (master2?.properties?.spirit_socket_2?? 0 < 6)"
                    :src="`/images/runes/shape-${master2.properties.spirit_socket_2}.png`"
                    alt="Spirit socket 2 Icon"
                    class="info-img-icon"
                  >
                </div>
              </div>
              <div class="row gx-3 info-row">
                <div
                  class="col text-end info-img-display"
                  style="justify-content: flex-end;"
                >
                  <div v-if="(master1?.properties?.spirit_points?? '') !== ''">
                    <div
                      v-for="(value, key) in ParseSpiritPoint(master1.properties.spirit_points)"
                      :key="key"
                    >
                      <div v-if="key !== ''">
                        <img
                          v-if="GetElementImage(key) !== ''"
                          :src="GetElementImage(key)"
                          alt="Spirit Point Icon"
                          class="info-img-icon"
                        >
                        <span> {{ value }} </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-5 text-center info-text">
                  Spirit Point
                </div>
                <div
                  class="col text-start info-img-display"
                  style="justify-content: flex-start;"
                >
                  <div v-if="(master2?.properties?.spirit_points?? '') !== ''">
                    <div
                      v-for="(value, key) in ParseSpiritPoint(master2.properties.spirit_points)"
                      :key="key"
                    >
                      <div v-if="key !== ''">
                        <img
                          v-if="GetElementImage(key) !== ''"
                          :src="GetElementImage(key)"
                          alt="Spirit Point Icon"
                          class="info-img-icon"
                        >
                        <span> {{ value }} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row gx-3 info-row">
                <div
                  class="col text-end info-img-display"
                  style="justify-content: flex-end;"
                >
                  <div
                    v-if="skills_master1.length > 0"
                    class="info-img-display"
                    style="justify-content: flex-end;"
                  >
                    {{ skills_master1[0]?.skill_name?? '' }},<br>
                    {{ skills_master1[1]?.skill_name?? '' }},<br>
                    {{ skills_master1[2]?.skill_name?? '' }}
                    <!-- <div v-for="(x, i) in skills_master1">
                                            <img v-if="x" :src="`/images/skills/${x.skill_code?? ''}.png`" alt="Super Move Icon" class="info-img-icon" 
                                            onerror="this.onerror=null; this.src='/images/cards/card/0.png';"/>
                                        </div> -->
                  </div>
                </div>
                <div class="col-5 text-center info-text">
                  Normal Skill
                </div>
                <div
                  class="col text-start info-img-display"
                  style="justify-content: flex-start;"
                >
                  <div
                    v-if="skills_master2.length > 0"
                    class="info-img-display"
                    style="justify-content: flex-start;"
                  >
                    {{ skills_master2[0]?.skill_name?? '' }},<br>
                    {{ skills_master2[1]?.skill_name?? '' }},<br>
                    {{ skills_master2[2]?.skill_name?? '' }}
                    <!-- <div v-for="(x, i) in skills_master2">
                                            <img v-if="x" :src="`/images/skills/${x.skill_code?? ''}.png`" alt="Super Move Icon" class="info-img-icon" 
                                            onerror="this.onerror=null; this.src='/images/cards/card/0.png';"/>
                                        </div> -->
                  </div>
                </div>
              </div>
              <div class="row gx-3 info-row">
                <div
                  class="col text-end info-img-display"
                  style="justify-content: flex-end;"
                >
                  <div
                    v-if="cards_master1.length > 0"
                    class="info-img-display"
                    style="justify-content: flex-end;"
                  >
                    <div v-for="(x, i) in cards_master1">
                      <img
                        :src="`/images/cards/card/${x}.png`"
                        alt="Super Move Icon"
                        class="info-img-icon"
                      >
                    </div>
                  </div>
                </div>
                <div class="col-5 text-center info-text">
                  Super Move
                </div>
                <div
                  class="col text-start info-img-display"
                  style="justify-content: flex-start;"
                >
                  <div
                    v-if="cards_master2.length > 0"
                    class="info-img-display"
                    style="justify-content: flex-start;"
                  >
                    <div v-for="(x, i) in cards_master2">
                      <img
                        :src="`/images/cards/card/${x}.png`"
                        alt="Super Move Icon"
                        class="info-img-icon"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="material-box">
              <div style="font-size: 20px; font-weight: bold; margin-bottom: 10px;">
                Master 2
              </div>
              <div style="display: flex; width: 72.5%; display: inline-flex; justify-content: center; gap: 10px; align-items: center; margin-bottom: 15px;">
                <div style="width: auto; white-space: nowrap;">
                  Token ID
                </div>
                <form class="input-form">
                  <div
                    class=""
                    style="flex: 5 1;"
                  >
                    <div
                      class=""
                      style="box-sizing: border-box;"
                    >
                      <div
                        class=""
                        style="position: relative;"
                      >
                        <input
                          id="token_master2"
                          v-model="token_master2"
                          type="text"
                          class="input-field"
                          placeholder="Enter token ID"
                          required
                          @input="GenereateMasterImage(2)"
                        >
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="material-use">
                <img
                  v-if="isShow_img_master2"
                  id="image_master2"
                  class="material-icon"
                  alt="master 2 image"
                >
                <div v-else>
                  <span v-if="isLoading_img_master2"><i class="fa fa-circle-o-notch fa-spin" /></span>
                  <span
                    v-else
                    style="height: auto;"
                  >Master 2 Image</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row material-container"
            style="height: 15%; margin: -20px 0 0 0"
          >
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="isLoading"
              style="width: 120px;"
            >
              <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin" /></span>
              <span v-else> Coach </span>
            </button>
          </div>
        </form>
        <div
          v-if="isShow_img_disciple"
          class="row no-space material-container"
          style="height: auto; display: flex;"
        >
          <div
            class="material-box"
            style="height: auto;"
          >
            <canvas
              ref="canvas"
              :width="canvasWidth"
              :height="canvasHeight"
              style="display:none"
            />
            <img
              v-if="mergedImage"
              :src="mergedImage"
              alt="Merged Image"
              style="width: 100%; height: auto;"
            >
          </div>
          <div
            v-if="disciple && Object.keys(disciple).length > 0"
            class=""
            style="display: flex; flex-direction: column; width: 20%; justify-content: flex-end; padding-top: 30px"
          >
            <div
              class=""
              style="height: 20%;"
            >
              <div style="justify-content: center;">
                <p class="stat-title">
                  Class
                </p>
                <div style="display: flex;">
                  <img
                    v-if="disciple?.class?? '' !== ''"
                    :src="GetClassImage(disciple.class)"
                    alt="Class Icon" 
                    class="info-img-icon"
                    style="margin-right: 6px;"
                  >
                  <h6 class="">
                    {{ GetClassName(disciple.class) }}
                  </h6>
                </div>
              </div>
            </div>
            <div
              class=""
              style="height: 20%;"
            >
              <div style="justify-content: center;">
                <p class="stat-title">
                  Element
                </p>
                <div style="display: flex;">
                  <img
                    v-if="disciple?.element?? '' !== ''"
                    :src="GetElementImage(disciple.element)"
                    alt="Element Icon" 
                    class="info-img-icon"
                    style="margin-right: 6px;"
                  >
                  <h6 class="">
                    {{ disciple?.element?? '' }}
                  </h6>
                </div>
              </div>
            </div>
            <div
              class=""
              style="height: 20%;"
            >
              <div style="justify-content: center;">
                <p class="stat-title">
                  Spirit Socket
                </p>
                <div
                  class="info-img-display"
                  style="display: flex;"
                >
                  <img
                    v-if="(disciple?.spirit_socket1?? 0 > 0) && (disciple?.spirit_socket1?? 0 < 6)"
                    :src="`/images/runes/shape-${disciple.spirit_socket1}.png`"
                    alt="Spirit socket 1 Icon"
                    class="info-img-icon"
                  >
                  <img
                    v-if="(disciple?.spirit_socket2?? 0 > 0) && (disciple?.spirit_socket2?? 0 < 6)"
                    :src="`/images/runes/shape-${disciple.spirit_socket2}.png`"
                    alt="Spirit socket 2 Icon"
                    class="info-img-icon"
                  >
                </div>
              </div>
            </div>
            <div
              class=""
              style="height: 20%;"
            >
              <div style="justify-content: center;">
                <p class="stat-title">
                  Spirit Point
                </p>
                <div
                  v-if="(disciple?.spirit_points?? '') !== ''"
                  class="info-img-display"
                  style="display: flex;"
                >
                  <div
                    v-for="(value, key) in disciple.spirit_points"
                    :key="key"
                  >
                    <div v-if="key !== ''">
                      <img
                        v-if="GetElementImage(key) !== ''"
                        :src="GetElementImage(key)"
                        alt="Spirit Socket Icon"
                        class="info-img-icon"
                      >
                      <span> {{ value }} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class=""
              style="height: auto;"
            >
              <div style="justify-content: center;">
                <p class="stat-title">
                  Normal Skill
                </p>
                <div
                  v-if="skills_disciple.length > 0"
                  class="info-img-display"
                  style="justify-content: flex-end;"
                >
                  {{ skills_disciple[0]?.skill_name?? '' }},<br>
                  {{ skills_disciple[1]?.skill_name?? '' }},<br>
                  {{ skills_disciple[2]?.skill_name?? '' }}
                  <!-- <div v-for="(x, i) in skills_disciple">
                                        <img v-if="x" :src="`/images/skills/${x.skill_code}.png`" alt="Super Move Icon" class="info-img-icon" 
                                        onerror="this.onerror=null; this.src='/images/cards/card/0.png';"/>
                                    </div> -->
                </div>
              </div>
            </div>
            <div
              class=""
              style="height: 20%;"
            >
              <div style="justify-content: center;">
                <p class="stat-title">
                  Super Move
                </p>
                <div
                  v-if="cards_disciple.length > 0"
                  class="info-img-display"
                  style="justify-content: flex-end;"
                >
                  <div v-for="(x, i) in cards_disciple">
                    <img
                      :src="`/images/cards/card/${x}.png`"
                      alt="Super Move Icon"
                      class="info-img-icon"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import Web3 from 'web3';
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import { getClassImageUrl ,getElementImageUrl } from '@/utils/imageUtils.js';
import { useCardStore } from '@/stores/card.ts';
import {useToast} from 'vue-toast-notification';
import { useSkillStore } from '@/stores/skill.ts';

export default {
    components: {
    }, data() {
        return {
            isLoading: false,
            master1: {},
            isLoading_img_master1: false,
            isShow_img_master1: false,
            token_master1: "",
            cards_master1: [],
            skills_master1: [],
            master2: {},
            isLoading_img_master2: false,
            isShow_img_master2: false,
            token_master2: "",
            cards_master2: [],
            skills_master2: [],
            disciple: {},
            cards_disciple: [],
            skills_disciple: [],
            isShow_img_disciple: false,
            isLoading_img_disciple: false,
            mergedImage: null,
            canvasWidth: 0,
            canvasHeight: 0
        };
    },
    computed: {
    },
    async mounted() {
        try {
            await useCardStore().fetchCardData();
            //await useCardStore().fetchPartMapper();
            await useSkillStore().fetchMapSkillData();
        } catch (error){ 
            console.error('Error:', error);
        }
    },
    methods: {
        GetCards(selectedNo){
            var selectedFighter = {};
            if(selectedNo == 1){
                this.cards_master1 = [];
                selectedFighter = this.master1;
            }
            else if(selectedNo == 2){
                this.cards_master2 = [];
                selectedFighter = this.master2;
            }
            else{
                this.cards_disciple = [];
                selectedFighter = this.disciple;
            }

            var body_part = [];
            if(selectedNo == 1 || selectedNo == 2){
                body_part = [{id: selectedFighter.properties.body_part_top_id, name: selectedFighter.properties.body_part_top},
                            {id: selectedFighter.properties.body_part_body_id, name: selectedFighter.properties.body_part_body}];
            }
            else{
                body_part = [{id: selectedFighter.body_part_top_id, name: selectedFighter.body_part_top},
                            {id: selectedFighter.body_part_body_id, name: selectedFighter.body_part_body}];
            }

            body_part.forEach((data, index) => {
                // console.log("part idddddd"+data.id);
                // console.log("part nameeeee"+data.name);
                if(selectedNo == 1){
                    this.cards_master1.push(useCardStore().getCardIdFromPart(data.id));
                }
                else if(selectedNo == 2){
                    this.cards_master2.push(useCardStore().getCardIdFromPart(data.id));
                }
                else{
                    this.cards_disciple.push(useCardStore().getCardIdFromPart(data.id));
                }
            });

            // console.log("cards" + this.cards_master1.length);
        },
        GetSkills(selectedNo){
            var selectedFighter = {};
            if(selectedNo == 1){
                this.skills_master1 = [];
                selectedFighter = this.master1;
            }
            else if(selectedNo == 2){
                this.skills_master2 = [];
                selectedFighter = this.master2;
            }
            else{
                this.skills_disciple = [];
                selectedFighter = this.disciple;
            }

            const _newSkill = [];
        
            const _firstSkill = useSkillStore().getDefaultSkillData((selectedNo == 1 || selectedNo == 2)? selectedFighter.properties.class_id:selectedFighter.class);
            if((_firstSkill?.skill_code?? '') != ''){
                _newSkill.push(_firstSkill);
            }
            else{
                if(selectedNo == 1 || selectedNo == 2){
                    _newSkill.push(selectedFighter.properties.skills[0]);
                }
            }

            var body_part = [];
            if(selectedNo == 1 || selectedNo == 2){
                body_part = [{id: selectedFighter.properties.body_part_top_face_id, name: selectedFighter.properties.body_part_top_face},
                            {id: selectedFighter.properties.body_part_pant_id, name: selectedFighter.properties.body_part_pant}];
            }
            else{
                body_part = [{id: selectedFighter.body_part_face_id, name: selectedFighter.body_part_face},
                            {id: selectedFighter.body_part_pant_id, name: selectedFighter.body_part_pant}];
            }

            body_part.forEach((data, index) => {
                const _mapSkill = useSkillStore().getMapSkillData(data.id);
                if((_mapSkill?.skill_code?? '') != ''){
                    _newSkill.push(_mapSkill);
                }
                else{
                    if(selectedNo == 1 || selectedNo == 2){
                        _newSkill.push(selectedFighter.properties.skills[index+1]);
                    }
                }
            });

            // if(selectedNo == 1 || selectedNo == 2){
            //     var selectFighterSkill = selectedFighter.properties.skills;

            //     if(_newSkill.length < 3){
            //         const _diff = 3-_newSkill.length;
            //         for (let i=0; i<_diff; i++){
            //             _newSkill.push(selectFighterSkill[i]);
            //         }
            //     }
            // }

            if(selectedNo == 1){
                this.skills_master1 = _newSkill;
            }
            else if(selectedNo == 2){
                this.skills_master2 = _newSkill;
            }
            else{
                this.skills_disciple = _newSkill;
            }

            // body_part.forEach((data, index) => {
            //     console.log("part idddddd"+data.id);
            //     console.log("part nameeeee"+data.name);
            //     if(selectedNo == 1){
            //         this.skills_master1.push(useSkillStore().getMapSkillData(data.id));
            //     }
            //     else if(selectedNo == 2){
            //         this.skills_master2.push(useSkillStore().getMapSkillData(data.id));
            //     }
            //     else{
            //         this.skills_disciple.push(useSkillStore().getMapSkillData(data.id));
            //     }
            // });

            // console.log("cards" + this.cards_master1.length);
        },
        GetClassName(classId){
            var classList = ['AllRounder', 'Bodyguard', 'Duelist', 'Stalker', 'Acrobat', 'Trickster', 'Destroyer', 'Brawler'];
            return classList[classId-1];
        },
        GetClassImage(classId){
            return getClassImageUrl(classId);
        },
        GetElementImage(element){
            return getElementImageUrl(element);
        },
        ParseSpiritPoint(pointList){
            return JSON.parse(pointList);
        },
        async GenereateMasterImage(masterNo){
            var token = masterNo == 1 ? this.token_master1 : this.token_master2;

            if(masterNo == 1){
                this.isLoading_img_master1 = true;
            }
            else{
                this.isLoading_img_master2 = true;
            }

            if(token != ""){
                try {
                    if(masterNo == 1){
                        this.isShow_img_master1 = true;
                    } else {
                        this.isShow_img_master2 = true;
                    }
                    
                    const response = await axios.get(`https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/fighter/${parseInt(token)}.json`);

                    console.log('Response data:', response);
                    if(masterNo == 1){
                        this.master1 = response.data;
                    } else {
                        this.master2 = response.data;
                    }

                    this.$nextTick(() => {
                        var imgId = masterNo == 1 ? 'image_master1' : 'image_master2';
                        var imgElement = document.getElementById(imgId);
                        var imageUrl = response.data.image;
                        imageUrl = imageUrl.replace("image/4x/", "card/no-bg/");
                        imgElement.src = imageUrl;
                    });

                    this.GetCards(masterNo);
                    this.GetSkills(masterNo);

                } catch (error) {
                    console.error('Error:', error);
                }
            }
            else{
                this.disciple = {};
                this.isShow_img_disciple = false;
                if(masterNo == 1){
                    this.isShow_img_master1 = false;
                    this.master1 = {};
                    this.cards_master1 = [];
                    this.skills_master1 = [];
                } else {
                    this.isShow_img_master2 = false;
                    this.master2 = {};
                    this.cards_master2 = [];
                    this.skills_master2 = [];
                }
            }

            if(masterNo == 1){
                this.isLoading_img_master1 = false;
            }
            else{
                this.isLoading_img_master2 = false;
            }
        },
        async Coach(){
            if(this.token_master1 != "" && this.token_master2 != ""){
                this.isLoading = true;
                this.isShow_img_disciple = false;
                try {
                    const requestBody = {
                        master1_id: this.token_master1,
                        master2_id: this.token_master2,
                    };

                    const response = await axios.post(`https://reward-portal.kryptofighters.io/api/dojo/coach`, requestBody);

                    this.disciple = response.data;
                    this.GetCards(3);
                    this.GetSkills(3);
                    
                    this.$nextTick(() => {
                        this.isShow_img_disciple = true;
                        console.log('Response data:', response);
                        var url1 = `https://asset.kryptofighters.io/fighter-bodypart/${response.data.body_part_top}.png`;
                        var url2 = `https://asset.kryptofighters.io/fighter-bodypart/${response.data.body_part_face}.png`;
                        var url3 = `https://asset.kryptofighters.io/fighter-bodypart/${response.data.body_part_skin}.png`;
                        var url4 = `https://asset.kryptofighters.io/fighter-bodypart/${response.data.body_part_body}.png`;
                        var url5 = `https://asset.kryptofighters.io/fighter-bodypart/${response.data.body_part_pant}.png`;
                        this.mergeImages(url1, url2, url3, url4, url5);
                    });

                } catch (error) {
                    console.error('Error:', error);

                    useToast().open({
                        message: 'Coach error : ' + error.message,
                        position: 'top-right',
                        type: 'error',
                    });

                    this.disciple = {};
                    this.cards_disciple = [];
                    this.skills_disciple = [];
                }
                this.isLoading = false;
            }
        },
        async mergeImages(url1, url2, url3, url4, url5) {
            this.isLoading_img_disciple = true;
            try {
                const img1 = await this.loadImage(url1);
                const img2 = await this.loadImage(url2);
                const img3 = await this.loadImage(url3);
                const img4 = await this.loadImage(url4);
                const img5 = await this.loadImage(url5);

                // Create a canvas with the desired dimensions
                const canvas = this.$refs.canvas;
                const ctx = canvas.getContext('2d');

                // Clear the canvas before drawing the new image
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                const scaleFactor = 2;

                // Set canvas size
                this.canvasWidth = img1.width/5 * scaleFactor;
                this.canvasHeight = img1.height/3 * scaleFactor;

                // Apply the new canvas size
                canvas.width = this.canvasWidth;
                canvas.height = this.canvasHeight;

                // Disable image smoothing for pixel-perfect scaling
                ctx.imageSmoothingEnabled = false;

                // Scale the canvas
                ctx.scale(scaleFactor, scaleFactor);

                // Draw images on the canvas
                ctx.drawImage(img3, 0, 0);

                ctx.drawImage(img5, 0, 0);
                ctx.drawImage(img4, 0, 0);
                ctx.drawImage(img2, 0, 0);
                ctx.drawImage(img1, 0, 0);


                // Convert canvas to data URL and set to mergedImage
                this.mergedImage = canvas.toDataURL('image/png');
                this.isLoading_img_disciple = false;
                this.isShow_img_disciple = true;
            } catch (error) {
                console.error('Error merging images:', error);
                this.isLoading_img_disciple = false;
            }
        },
        loadImage(src) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = "Anonymous";
                img.onload = () => resolve(img);
                img.onerror = reject;
                img.src = src;
            });
        }
    }
}
</script>
  
<style scoped>
.no-space {
    margin: 0;
    padding: 0;
}

.material-container {
    display: flex;
    justify-content: center;
    align-content: center;
    /* border: 1px solid green;  */
}

.body-content {
    border: 1px solid green;
    height: 100%; 
    width: 25%;
    justify-content: center;
    align-content: center;
}

.material-box {
    /* border: 1px solid lightslategrey; */
    border-radius: 10px;
    height: 400px;
    width: 300px;
    justify-self: center;
    align-self: center;
    padding: 0px 15px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.material-use {
    border: 1.5px solid lightslategrey;
    border-radius: 10px;
    height: 70%;
    width: 79%;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.material-icon {
    /* border: 1px solid red;  */
    height: 100%; 
    width: 100%; 
    margin: 0; 
    padding: 0;
}

.material-total {
    /* border: 1px solid red;  */
    height: 25%; 
    width: 100%; 
    text-align: center; 
    align-items: center; 
    display: flex; 
    justify-content: center;
    font-size: 100%;
    color: white;
}

.material-total .current-fragment {
    color: lightgreen;
}

.craft-btn {
    width: 10%;
}

.input-form{
    height: 40px;
    color: white;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: contents;
    align-items: center;
    width: auto;
}

.input-field {
    border: 1px solid #3c424d;
    appearance: none;
    background-clip: padding-box;
    border-radius: 8px;
    display: block;
    outline: none;
    padding: 5px 10px;
    transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    width: 100%;
}

.info-text {
    font-weight: bold; 
    font-size: 20px; 
    color: grey;
}

.info-row {
    width: 100%; 
    display: flex; 
    align-items: center; 
    font-size: 15px;
}

.info-img-icon {
    width: 20px;
    height: 20px;
}

.info-img-display {
    display: inline-flex; 
    gap: 6px;
}

.trait-row .col-md-3 {
    width: 50%;
  }

@media (max-width: 768px) {
    .craft-btn {
        width: 30%;
    }

    .material-box {
        height: 300px;
        width: 300px;
    }

    .material-use {
        height: 60%;
        width: auto;
        min-width: 49%;
    }
}

</style>