<template>
  <div class="page-content content-wrap">

    <div class="main-content">
      <div class="d-flex align-items-left" style="margin-bottom: 20px;">
        <!--<img
          src="../assets/Icon-stats-owners.png"
          alt="NFT Icon"
          class="header-icon mr-3"
          style="width:50px;"
        >-->
        <h1>Offers</h1>
      </div>
      <div>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: activeTab === 'received' }"
              @click="activateTab('received')"
            >
              Received <!-- {{ formattedFrameAmount(amounts["fighter"]) }} -->
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: activeTab === 'sent' }"
              @click="activateTab('sent')"
            >
              Sent <!-- {{ formattedFrameAmount(amounts["ticket"]) }} -->
            </a>
          </li>
        </ul>

        <!-- Tab content -->
        <div
          class="tab-content"
          style="margin-top: 10px;"
        >
          <div
            class="tab-pane"
            :class="{ active: activeTab === 'received' }"
          >
            <ReceivedTab :amounts="amounts" />
          </div>
          <div
            class="tab-pane"
            :class="{ active: activeTab === 'sent' }"
          >
            <SentTab :amounts="amounts" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import Web3 from 'web3';
import { timeAgo, convertToTimestamp } from '@/utils/timeUtils.js';
import { convertToUSD } from '@/utils/exchangeUtils.js';
import { useStore } from '@/stores/index.ts';

import ReceivedTab from '@/components/inventory/ReceivedTab.vue';
import SentTab from '@/components/inventory/SentTab.vue';

import { ref, onMounted, watch } from 'vue';
import axios from 'axios';

export default {
    components: {
        ReceivedTab,
        SentTab,
    }, data() {
        return {
          activeTab: 'received',
        };
    },
    mounted() {
        watch(
            () => this.selectedClasses,
            () => {
                // Fetch image URL when the component is mounted
                this.fetchFighterData();
            }
        );
    },
    methods: {
        activateTab(tabName) {
            this.activeTab = tabName;
        }
    }
}
</script>
  
  
<style scoped>
/* Add your component-specific styles here */

.nav-tabs {
    color: gray;
    border: 0;
    border-bottom: 1px solid var(--primary-border-color);
}

.nav-tabs .nav-link.active {
    background-color: var(--soft-background-color);
}

.nav-tabs .nav-link.inactive {
    background-color: var(--soft-background-color);
}


/* Change the background color of inactive tabs */
.nav-tabs .nav-link :hover {
    border-color: var(--primary-border-color);

}
</style>