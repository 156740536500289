<template>
  <div class="product-item">
    <div
      v-if="isLoading"
      style="margin-bottom: 50px;"
    >
      <v-skeleton-loader
        :type="'card'"
        :animation="true"
        :width="200"
        :height="300"
        theme="dark"
      />
    </div>
    <div v-else>
      <router-link
        :to="product.isOwner ? { name: 'inventory_fighter', query: { id: product.id } } : { name: 'fighter', query: { id: product.id } }"
        class="link"
      >
        <div class="card product-card-container">
          <div class="card-body">
            <div class="product-header">
              <div class="left-content product-title">
                <div class="icon-container red-image">
                  <img
                    :src="product.classImgUrl"
                    alt="Product Icon"
                    class="icon-class"
                  >
                </div>
                <span :class="[ 'product-id']"># {{ product.id }}</span>
              </div>
              <img
                v-if="product.elementUrl !== ''"
                :src="product.elementUrl"
                alt="Product Icon"
                class="icon-element-right"
              >
            </div>
            <div class="image-container">
              <!-- <router-link :to="{ name: 'fighter', query: { id: product.id } }"> -->
              <img
                :src="product.image"
                alt="Product Image"
                class="product-image"
              >
              <img
                src="../assets/shadow.png"
                class="fighter-shadow"
                alt="Image"
              >
            </div>
            <div style="width: 100%; display: inline-flex; justify-content: center;">
              <div v-if="product.isListing == true" class="product-title" style="width: auto; color: aqua; font-weight: 500; padding-right: 15px; padding-left: 15px;">
                listed
              </div>
            </div>
            <!-- </router-link> -->
            <div v-if="product.price !== 0" style="display: flex; justify-content: center; align-items: center; gap: 5px; flex-direction: column;">
              <div class="d-flex justify-content-center">
                <img
                  :src="require(`@/assets/currency/${product.currency}.png`)"
                  alt="currency icon"
                  style="width: 20px; height: auto;"
                >
                <h4 class="product-price" style="margin: 0; margin-left: 3px; text-align: center;">
                  {{ Number(product.price).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 3 }) }}
                </h4>
              </div>
              <h4 v-if="product.usdPrice > 0" class="product-usd-price">
                $ {{ product.usdPrice.toFixed(2) }}
              </h4>
              <div v-else>
              </div>
              <h4 class="product-usd-price">
               
              </h4>
            </div>
          </div>
        </div>
      </router-link>
      <div class="product-time">
        {{ product.dateString }}
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { getClassImageUrl, getElementImageUrl } from '@/utils/imageUtils.js';
import { kfServiceStore } from '@/stores/kfServiceStore.ts';
export default {
    props: {
        product: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            isLoading: true,
        }
    },
    mounted() {
        // Fetch image URL when the component is mounted
        this.fetchImage();
    },
    methods: {

        async fetchImage() {
            try {
                var response = {};
                if(this.product.s3ProductData){
                  response = this.product.s3ProductData;
                }
                else{
                  response = await axios.get(`https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/fighter/${this.product.id}.json`);
                }
                // const response = await axios.get(`https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/fighter/${this.product.id}.json`);
                var imageUrl = response.data.image;
                //classIconUrl.value = response.data.properties.class_id;
                imageUrl = imageUrl.replace("image/4x/", "card/no-bg/");

                // Update the product object with the obtained image URL
                //console.log(response.data.properties);
                //console.log(' fetching elementUrl:' + this.product.id + " - " + response.data.properties.element + " - " + this.elementUrl);
                this.product.image = imageUrl;
                this.product.class_id = response.data.properties.class_id;
                this.product.element_id = response.data.properties.element;

                this.product.classImgUrl = getClassImageUrl(response.data.properties.class_id);
                this.product.elementUrl = getElementImageUrl(response.data.properties.element);
                const image = new Image();

                image.onload = () => {
                    this.product.image = imageUrl;
                    this.isLoading = false;
                };
                image.src = imageUrl;
            } catch (error) {
                console.error('Error fetching image:', error);
                this.mintNewFighter();
                
                // Handle error loading image if necessary
            }
        },
        async mintNewFighter(){
            //const store = kfServiceStore();
            //await store.fetchAccessToken();
          try{
              const requestBody = {
                          token_id: this.product.id,
                        };
              const mintResponse = await axios.post(`https://reward-portal.kryptofighters.io/api/dojo/testnet/mint`, requestBody);
              this.fetchImage();
            } catch (error) {
                  console.error('Error fetching image:', error);
                //  this.mintNewFighter();
                
                // Handle error loading image if necessary
            }
           // console.log("mintResponse", mintResponse);
            

            /*const url = process.env.VITE_KF_SERVICE_BASE_URL + '/fighter/mint';
            const data = {
                tokenId: this.product.id,
                ticketType: 0,
            };
            const mintResponse = await axios.post(url, data, {
                    headers: {
                        Authorization: `Bearer ${store.accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });*/
            
        },
        navigateToFighter() {
            // Navigate to the '/fighter' route and pass the product data
            this.$router.push({
                name: 'fighter', // Assuming you have a named route for the '/fighter' route
                params: {
                    id: this.product.id,
                }
            });
        }
    }
};
</script>
  
<style scoped>
.product-item {
    text-align: center;
    margin-bottom: 0px;
}

.product-image {
    width: 160px;
    object-fit: cover;
    margin-bottom: 10px;
}

.product-name {
    font-size: 16px;
    margin-top: 5px;
    text-align: left;
    display: inline-flex;
    /* Set display to inline-flex */
    align-items: center;
    /* Center items vertically */
    justify-content: space-between;
    width: 100%;
}


.product-usd-price {
    margin-top: 0px;
    font-size: 14px;
    color: var(--secondary-text-color);
}

.product-time {
    margin-top: 0px;
    padding-top: 0px;
    font-size: 14px;
    color: var(--secondary-text-color);
    max-width: 205px;
}



.product-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.with-class-icon {
    background-image: url('../assets/icon-stats-matic.png');
    background-size: 20px 20px;
    /* Set the size of your icon */
    background-repeat: no-repeat;
    padding-left: 30px;
    /* Adjust the padding to make space for the icon */
}



.icon-element-right {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    /* Adjust margin to create space between the two icons */
}

.left-content {
    display: flex;
    align-items: center;
}

.link {
    text-decoration: none;
}

.image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Margin between product image and shadow */
.shadow-image {
    margin-top: 10px;
    /* Adjust margin as needed */
    width: 100px;
    height: 10px;
    object-fit: contain;
    /* Ensure the shadow image fits within its container */
}


.product-card-container:hover {
  background: linear-gradient(180deg, rgba(13, 10, 22, 0.6), rgba(255, 255, 255, 0.13)); 
}

.product-card-container {
  max-width: 205px;
  background: linear-gradient(180deg, rgba(13, 10, 22, 0.8), rgba(255, 255, 255, 0.07)); 
}

.fighter-shadow {
    position: relative; 
    top: -30px;
    width: 100px; 
    height: 10px;
}

.product-title{
    background-color: var(--soft-background-color);
    padding: 2px 6px;
    border-radius: 5px;
}

.text-element-ROCK {
    color: rgb(232, 163, 36);
}
.text-element-WATER {
    color: rgb(55, 118, 226);
}

.text-element-DARK {
    /* -webkit-text-stroke-width: 0.1px; 
    -webkit-text-stroke-color: white; */
    color: rgb(158, 84, 208)}
.text-element-CHAOS {
    color: rgb(211, 80, 211);
}
.text-element-FIRE {
    color: rgb(234, 102, 62);
}
.text-element-METAL {
    color: rgb(179, 187, 198);
}
.text-element-WIND {
    color: rgb(72, 197, 220);
}
.text-element-EARTH {
    color: rgb(73, 208, 73);
}
.text-element-LIGHT {
    color: rgb(235, 233, 233);
}

.red-image {
  filter: hue-rotate(45deg); /* Change the hue to red (45 degrees) */
}

.card-body {
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 768px) {
    .product-image { 
        width: 120px;

        margin-bottom: 0;
        padding: 0 7px;
    }

    .card-body {
        padding: 0.5rem 0.5rem;
        height: 250px;
    }

    .product-header {
        margin: 0;
    }

    .icon-class {
        margin-right: 5px;
    }
    .icon-element-right {
        margin-left: 5px;
        width: 20px;
        height: 20px;
    }

    .fighter-shadow {
        top: -10px;
        width: 70px; 
        height: 10px;
    }

}
</style>