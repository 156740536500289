<!-- RewardTab.vue -->
<template>
  <div>
    <div class="d-flex align-items-center justify-content-between default-row">
      <h4>{{ items.length }} Items </h4>
    </div>
    <div class="card">
      <div class="card-body">
        <div v-if="!storeNetwork.state.isPolygonConnected" style="text-align: center;">
          Please switch to the Polygon network.
        </div>
        <div v-else>
          <div v-if="items.length > 0">
            <div class="product-list">
              <div v-for="(item, index) in items">
                <SendItem
                  :key="index"
                  :item="item"
                  @rewardSended="fetchData"
                />
              </div>
            </div>
          </div>
          <div v-else>
            <img
              src="~@/assets/no-data.png"
              alt="no data"
              style="display: block; margin-left: auto; margin-right: auto; width: 160px;"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import Web3 from 'web3';
import SendItem from '@/components/SendItem.vue';
import { useStore } from '@/stores/index.ts';
import { useNetwork } from '@/stores/network.ts';

import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import NFTERC1155ABI from '@/contracts/NFTERC1155ABI.json';

export default {
    components: {
      SendItem,
    },
    props: {
    }, 
    data() {
        return {
            // items: [{item_id: 1, name: "Holobar", amount: 10},
            //         {item_id: 2, name: "Goldbar", amount: 20},
            //         {item_id: 3, name: "Microchip", amount: 30}],
            items: [],
            storeNetwork: useNetwork(),
        };
    },
    watch: {
    },
    async mounted() {
        if(useStore().getWalletAddress!=''){
          if(this.storeNetwork.state.isPolygonConnected){
            this.fetchData();
          }
        }else{
          watch(
              () => useStore().getWalletAddress,
              (newValue) => {
                if (newValue !='' && this.storeNetwork.state.isPolygonConnected) {
                  // console.log("[pol] this.storeNetwork.state.isPolygonConnected "+this.storeNetwork.state.isPolygonConnected);
                  this.fetchData();
                }
              }
            );

            watch(
              () => this.storeNetwork.state.isPolygonConnected,
              (newValue) => {
                if (newValue && useStore().getWalletAddress !='') {
                  // console.log("[pol] this.storeNetwork.state.isPolygonConnected "+this.storeNetwork.state.isPolygonConnected);
                  this.fetchData();
                }
              }
            );
        }
    },
    methods: {
        async fetchData() {
          try {
                const web3 = new Web3(window.ethereum);
                const accounts = await web3.eth.getAccounts();
                const accountAddress = accounts[0];

                const contractAddress =  "0x67c79d7f8fc85dcaf46147cd9837c93a6ca0c9d1"; 
                const contract = new web3.eth.Contract(NFTERC1155ABI, contractAddress);
                const ids = [0,1,2,3]; // Example token IDs
                const balances = await contract.methods.balanceOfBatch([accountAddress,accountAddress,accountAddress,accountAddress], ids).call();
                console.log("balances", balances);
                const jabContractAddress = "0x4eD9047663501071083617D1c865FDa2808AFEA3";
                const jabContractAbi = [
                    {
                        constant: true,
                        inputs: [{ name: '_owner', type: 'address' }],
                        name: 'balanceOf',
                        outputs: [{ name: 'balance', type: 'uint256' }],
                        type: 'function',
                    },
                ];

                const jabTokenContract = new web3.eth.Contract(jabContractAbi, jabContractAddress);
                const jabBalanceWei = await jabTokenContract.methods.balanceOf(accountAddress).call();
                const jabBalanceWith2DecimalPlaces = parseFloat(web3.utils.fromWei(jabBalanceWei, 'ether')).toFixed(0);
                console.log("jabBalanceWei Balances:", jabBalanceWith2DecimalPlaces);

                const combinedData = [...balances, jabBalanceWith2DecimalPlaces];
                console.log(combinedData);

                const itemCode = ['ITM_FM_HLB', 'ITM_FM_GLB', 'ITM_FM_MCC', 'ITM_LT_BOX'];
                this.items = await Promise.all(combinedData.map(async (balance, index) => {
                  if(balance > 0 && index !== 4){
                    try {
                        const response = await axios.get(`https://metadata.kryptofighters.io/fragment/${parseInt(ids[index]) + 1}.json`);
                        console.log("Response for Token ID:", ids[index], response.data);

                        return {
                            id: response.data.item_id,
                            item_code: itemCode[index],
                            name: response.data.name,
                            image: response.data.image,
                            amount: Number(balance)
                        };
                    } catch (error) {
                        console.error(`Error fetching metadata for token ID ${ids[index]}:`, error);
                    }
                  }
                  else if(index === 4){
                    return {
                            id: 11,
                            item_code: 'JAB',
                            name: 'JAB',
                            image: '',
                            amount: Number(balance)
                        };
                  }
                  return null;
                }));
                this.items = this.items.filter(item => item !== null);
                console.log(this.items);
                return;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }
}
</script>

<style scoped>
/* Add your component-specific styles here */

.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}
</style>
  