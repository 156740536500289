<template>
  <div class="page-content content-wrap">
    <!--<img class="content-bg" src="../assets/backgrounds/background-default.png" alt="">-->

    <div class="main-content">
      <div class="d-flex align-items-left">
        <!--<img
          src="../assets/icons/icon-portal.png"
          alt="NFT Icon"
          class="header-icon mr-3"
          style="width:50px;"
        >-->
        <h1>Portal</h1>
      </div>

      <div class="d-flex align-items-center justify-content-between default-top-space">
        <button
          id="withdrawButton"
          class="btn btn-primary"
          type="button"
          aria-expanded="false"
          :disabled="isWithdrawDisabled"
          data-bs-toggle="modal"
          :data-bs-target="isItem? '#withdrawItemPopup':'#withdrawPopup'"
          @click="showModal=true"
        >
          Withdraw
        </button>
        <div class="dropdown">
          <button
            id="classDropdown"
            class="btn btn-secondary dropdown-toggle dropdown-filter-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ getSelectedByText() }}
          </button>
          <ul
            class="dropdown-menu"
            aria-labelledby="orderByDropdown"
          >
            <li
              v-for="option in selectOptions"
              :key="option.id"
            >
              <a
                class="dropdown-item"
                href="#"
                @click="selectOrderBy(option.id)"
              >
                {{ option.name }}
              </a>
            </li>
          </ul>
        </div>
        <button
          id="depositButton"
          class="btn btn-primary"
          style="margin-right: 8px;"
          type="button"
          :disabled="isDepositDisabled"
          aria-expanded="false"
          data-bs-toggle="modal"
          :data-bs-target="isItem? '#depositItemPopup':'#depositPopup'"
          @click="showModal=true"
        >
          Deposit
        </button>
      </div>

      <div style="margin-top:20px; ">
        <div class="row ">
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <h5 class="stat-value">
                  In game assets
                </h5>
                <hr>
                <div v-if="isItem == true">
                  <div
                    v-if="inGameItems.length > 0"
                    class="item-grid"
                    style=""
                  >
                      <div
                        v-for="x in inGameItems"
                        class="item-section product-card-container"
                        :class="{ 'item-section-active': selectedStat === ('INGAME_' + x.item_id) }"
                        @click="selectStat('INGAME_' + + x.item_id)"
                        style="padding: 15px 20px; display: flex; flex-direction: column;"
                      >
                          <div>
                            <span class="badge bg-custom-secondary">
                              # {{ x.item_id }}
                            </span>
                          </div>
                          <div style="display: flex; justify-content: center; flex-direction: column; align-items: center;">
                            <img
                              :src="getItemImage(x.item_code)"
                              alt="item image"
                              class="stat-icon"
                              style="height: auto; width: 50%;"
                            >
                            <span class="badge" :class="['text-rarity-' + x.rarity]" style="margin-top: -5px;">
                              {{ getItemRarityName(x.rarity) }}
                            </span>
                            <span class="item-name" style="margin-top: 10px; font-size: 15px;">
                              {{ x.item_name }}
                            </span>
                            <span class="item-name" style="margin-top: 5px; font-weight: bold; font-size: 17px;">
                              x {{ x.amount }}
                            </span>
                          </div>
                      </div>
                  </div>
                  <div v-else style=" padding: 20px 0;">
                      <img
                      src="~@/assets/no-data.png"
                      alt="no data"
                      style="display: block; margin-left: auto; margin-right: auto; width: 160px;"
                      >
                  </div>
                </div>
                <div v-else>
                  <div
                    class="stat-section stats-logo d-flex align-items-center"
                    :class="{ 'active': selectedStat === 'INGAME_KF' }"
                    style="margin-bottom: 20px;"
                    @click="selectStat('INGAME_KF')"
                  >
                    <img
                      src="../assets/icons/icon-kf.png"
                      alt="NFT Icon"
                      class="stat-icon"
                    >
                    <div class="stat-content">
                      <h5 class="stat-value">
                        {{ 0 }} KF
                      </h5>
                    </div>
                  </div>
                  <div
                    class="stat-section stats-logo d-flex align-items-center"
                    :class="{ 'active': selectedStat === 'INGAME_JAB' }"
                    @click="selectStat('INGAME_JAB')"
                  >
                    <img
                      src="../assets/icons/icon-jab.png"
                      alt="Owner Icon"
                      class="stat-icon"
                    >
                    <div class="stat-content">
                      <h5 class="stat-value">
                        {{ ingameWallets.jabBalance }} JAB
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-body">
                <h5 class="stat-value">
                  In wallet assets
                </h5>
                <hr>
                <div v-if="isItem == true">
                  <div
                    v-if="inWalletItems.length > 0"
                    class="item-grid"
                    style=""
                  >
                      <div
                        v-for="x in inWalletItems"
                        class="item-section product-card-container"
                        :class="{ 'item-section-active': selectedStat === ('INWALLET_' + x.id) }"
                        @click="selectStat('INWALLET_' + + x.id)"
                        style="padding: 15px 20px; display: flex; flex-direction: column;"
                      >
                          <div>
                            <span class="badge bg-custom-secondary">
                              # {{ x.id }}
                            </span>
                          </div>
                          <div style="display: flex; justify-content: center; flex-direction: column; align-items: center;">
                            <img
                              :src="x.image"
                              alt="item image"
                              class="stat-icon"
                              style="height: auto; width: 50%;"
                            >
                            <span class="badge" :class="['text-rarity-' + x.rarity]" style="margin-top: -5px;">
                              {{ getItemRarityName(x.rarity) }}
                            </span>
                            <span class="item-name" style="margin-top: 10px; font-size: 15px;">
                              {{ x.name }}
                            </span>
                            <span class="item-name" style="margin-top: 5px; font-weight: bold; font-size: 17px;">
                              x {{ x.amount }}
                            </span>
                          </div>
                      </div>
                  </div>
                  <div v-else style=" padding: 20px 0;">
                      <img
                      src="~@/assets/no-data.png"
                      alt="no data"
                      style="display: block; margin-left: auto; margin-right: auto; width: 160px;"
                      >
                  </div>
                </div>     
                <div v-else>
                  <div
                    class="stat-section stats-logo d-flex align-items-center"
                    :class="{ 'active': selectedStat === 'INWALLET_KF' }"
                    style="margin-bottom: 20px;"
                    @click="selectStat('INWALLET_KF')"
                  >
                    <img
                      src="../assets/icons/icon-kf.png"
                      alt="NFT Icon"
                      class="stat-icon"
                    >
                    <div class="stat-content">
                      <h5 class="stat-value">
                        {{ wallets.kfBalance }} KF
                      </h5>
                    </div>
                  </div>
                  <div
                    class="stat-section stats-logo d-flex align-items-center"
                    :class="{ 'active': selectedStat === 'INWALLET_JAB' }"
                    @click="selectStat('INWALLET_JAB')"
                  >
                    <img
                      src="../assets/icons/icon-jab.png"
                      alt="Owner Icon"
                      class="stat-icon"
                    >
                    <div class="stat-content">
                      <h5 class="stat-value">
                        {{ wallets.jabBalance }} JAB
                      </h5>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <DepositPopup />
  <DepositItemPopup :inWalletItem="getInWalletItem()"/>
  <WithdrawPopup :inGameWallet="ingameWallets"/>
  <WithdrawItemPopup :inGameItem="getInGameItem()"/>
</template>
  
<script>

import Web3 from 'web3';
import { timeAgo, convertToTimestamp } from '@/utils/timeUtils.js';
import { convertToUSD } from '@/utils/exchangeUtils.js';
import { useExchangeStore } from '@/stores/exchangeRate.ts';
import { useStore } from '@/stores/index.ts';

import DepositPopup from "@/components/popup/DepositPopup.vue";
import DepositItemPopup from "@/components/popup/DepositItemPopup.vue";
import WithdrawPopup from "@/components/popup/WithdrawPopup.vue";
import WithdrawItemPopup from "@/components/popup/WithdrawItemPopup.vue";

import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import { ethers } from 'ethers';

import { kfServiceStore } from '@/stores/kfServiceStore.ts';

export default {
    components: {
        DepositPopup,
        DepositItemPopup,
        WithdrawPopup,
        WithdrawItemPopup
    }, data() {
        return {
            ingameWallets: {
                maticBalance: 0,
                kfBalance: 0,
                jabBalance: 0,
            },
            wallets: {
                maticBalance: 0,
                kfBalance: 0,
                jabBalance: 0,
            },
            selectedStat: null,
            isWithdrawDisabled: true, 
            isDepositDisabled: true,
            showModal: false,
            selectOptions: [
                { id: 1, name: 'Token' },
                { id: 2, name: 'Artifacts' },
                { id: 3, name: 'Stones' },
                // Add more class options as needed
            ],
            selectId: 1,
            isItem: false,
            inGameItems: [],
            inWalletItems: []
        };
    },
    mounted() {
        this.fetchWalletBalance().then(() => {
            // this.fetchFighterData();
            this.fetchInGameWallet().then(() => {

            });
        });
    },
    methods: {
        getItemRarityName(rarityId){
          switch(rarityId){
            case 1: return 'Common'; break;
            case 3: return 'Rare'; break;
            case 4: return 'Epic'; break;
            default: return ''; break;
          }
        },
        getInGameItem(){
          if(this.isItem && this.selectedStat){
            const item_id = this.selectedStat.slice(-5);
            // console.log('getItem', this.inGameItems);
            // console.log('getItem' ,this.inGameItems.find(f=>f.rid === Number(_rid)));
            return this.inGameItems.find(f=>f.item_id === Number(item_id));
          }
          else{
            return null;
          }
        },
        getInWalletItem(){
          if(this.isItem && this.selectedStat){
            const item_id = this.selectedStat.slice(-5);
            // console.log('getItem', this.inWalletItems);
            // console.log('getItem' ,this.inWalletItems.find(f=>f.id === item_id));
            return this.inWalletItems.find(f=>f.id === item_id);
          }
          else{
            return null;
          }
        },
        getItemImage(item_code){
          // console.log('getItemImage'+item_code);
          var path = `/images/item/`;
          if(item_code.includes('ITM_SS')){
            path += `stone`;
          }
          else if(item_code.includes('ITM_ATF')){
            path += `artifact`;
          }

          path += `/${item_code}.png`;
          return path;
        },
        selectOrderBy(id) {
            this.isWithdrawDisabled = true;
            this.isDepositDisabled = true;
            this.selectId = id;
            if(id != 1){
              this.isItem = true;
              this.fetchInGameItem(id);
              this.fetchInWalletItem(id);
            }
            else{
              this.isItem = false;
            }
        },
        getSelectedByText() {
            const selectedOption = this.selectOptions.find(option => option.id === this.selectId);
            return selectedOption ? selectedOption.name : '';
        },
        selectStat(stat) {
            if (this.selectedStat === stat) {
                this.selectedStat = null;
            } else {
                this.selectedStat = stat;
            }

            if(this.selectedStat !== null){
              if(isNaN(Number(stat.slice(-5)))){
                this.isDepositDisabled = true;
                if(stat == 'INWALLET_JAB'){
                    if(this.wallets.jabBalance>0){
                        this.isDepositDisabled = false;
                    }
                }
                this.isWithdrawDisabled = true;
                if(stat == 'INGAME_JAB'){
                    if(this.ingameWallets.jabBalance>0){
                        this.isWithdrawDisabled = false;
                    }
                }
              }
              else{
                this.isDepositDisabled = true;
                if(stat.includes('INWALLET')){
                  this.isDepositDisabled = false;
                }

                this.isWithdrawDisabled = true;
                if(stat.includes('INGAME')){
                  this.isWithdrawDisabled = false;
                }
              }
            }
            else{
              this.isWithdrawDisabled = true;
              this.isDepositDisabled = true;
            }
        },
        async fetchInGameWallet() {
            try {
                const accountAddress = useStore().getWalletAddress;
                const apiUrl = 'https://service.kryptofighters.io/api/public/user/wallet?wallet_address=' + accountAddress ;
                console.log('apiUrl:', apiUrl);
                const response = await axios.get(apiUrl, {
                    headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                    }
                });

                // console.log('Response data:', useStore().ingameWallet);
                this.ingameWallets.jabBalance = response.data.jab;
                
                //if(response.data.reponseCode === '200'){
                    //console.log('Response data: 200');
                //}
                //else{
                    //console.log('Response data:', response.data);
                //}

            } catch (error) {
                console.error('Error:', error);
            }
        },
        async fetchWalletBalance() {
            // Check if MetaMask is installed
            if (typeof window.ethereum === 'undefined') {
                console.error('MetaMask is not installed');
                return;
            }

            const jabContractAddress = process.env.VITE_JAB_CONTRACT_ADDRESS;
            const jabContractAbi = [
                {
                    constant: true,
                    inputs: [{ name: '_owner', type: 'address' }],
                    name: 'balanceOf',
                    outputs: [{ name: 'balance', type: 'uint256' }],
                    type: 'function',
                },
            ];


            try {
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                const networkId = await window.ethereum.request({ method: 'net_version' });
                if (networkId === process.env.VITE_NETWORK_ID ||networkId === '80001') {
                    // Get the current account address from MetaMask
                    const accounts = await window.ethereum.request({ method: 'eth_accounts' });
                    const account = accounts[0];

                    const web3 = new Web3(window.ethereum);

                    const balanceWei = await web3.eth.getBalance(account);
                    const balanceEther = web3.utils.fromWei(balanceWei, 'ether');

                    console.log(`Matic Balance for ${account}: ${balanceEther} MATIC`);
                    this.wallets.maticBalance = parseFloat(balanceEther).toFixed(2);

                    const jabTokenContract = new web3.eth.Contract(jabContractAbi, jabContractAddress);
                    const jabBalanceWei = await jabTokenContract.methods.balanceOf(account).call();
                    const jabBalanceWith2DecimalPlaces = parseFloat(web3.utils.fromWei(jabBalanceWei, 'ether')).toFixed(0);

                    this.wallets.jabBalance = jabBalanceWith2DecimalPlaces;

                    return balanceEther;
                } else {
                    console.error('Please switch to the Matic network in MetaMask');
                    return 0;
                }
            } catch (error) {
                console.error('Error fetching Matic balance:', error);
                return 0;
            }
        },
        async fetchInGameItem(id) {
            try {
                const store = kfServiceStore();
                await store.fetchAccessToken();
                const accessToken = store.accessToken;

                const filterType = (id == 2)? 'artifact':'stone';

                const accountAddress = useStore().getWalletAddress;
                const apiUrl = 'https://service.kryptofighters.io/api/public/inventory/item';
                const requestBody = {
                  wallet_address: accountAddress,
                  password: '&L8*lXuMlweVefa44e=u'
                };
                const response = await axios.post(apiUrl, requestBody, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                console.log('Response data:', response);

                var _tempList = [];
                const groupItem = response.data.items.filter(f=>f.item_type == filterType && f.rarity > 1).reduce((accumulator, currentItem) => {
                  const index = accumulator.findIndex(item => item.item_id === currentItem.item_id);
    
                  if (index > -1) {
                    // If item_id already exists, increment its amount
                    accumulator[index].amount += currentItem.amount;
                  } else {
                    // If item_id doesn't exist, add the current item to the accumulator
                    accumulator.push(currentItem);
                  }
                  
                  return accumulator; // Return the updated accumulator
                }, _tempList); // Initialize the accumulator with _tempList

                this.inGameItems = groupItem;
                console.log('Response groupItem:', _tempList);

            } catch (error) {
                console.error('Error:', error);
            }
        },
        async fetchInWalletItem(id) {
            try {
                const filterType = (id == 2)? 'artifact':'stone';
                const accountAddress = useStore().getWalletAddress;
                var collection = (id == 2)? process.env.VITE_ARTIFACT_CONTRACT_ADDRESS:process.env.VITE_STONE_CONTRACT_ADDRESS;
                console.log("generalResponse ",collection);
                const requestBody = {
                    size: 50,
                    filter: {
                        blockchains: ['KROMA'],
                        owners: [`ETHEREUM:${accountAddress}`],
                        collections: [`KROMA:${collection}`]
                    }
                };
                console.log("generalResponse ",requestBody);
                const apiUrl = `https://api.rarible.org/v0.1/ownerships/search`;
                const response = await axios.post(apiUrl, requestBody, {
                    headers: {
                        'accept': 'application/json',
                        'x-api-key': process.env.VITE_RARIBLE_API_KEY
                    }
                });
                console.log("generalResponse ",response);

                await this.fetchNFTData(id, response.data.ownerships);

                // var _tempList = [];
                // const groupItem = response.data.items.filter(f=>f.item_type == filterType)
                // this.inGameItems = groupItem;
                // console.log('Response groupItem:', _tempList);

            } catch (error) {
                console.error('Error:', error);
            }
        },
        async fetchNFTData(type, itemList) {
          const filterType = (type == 2)? 'artifact':'stone';
          const contractAddress = (type == 2)? process.env.VITE_ARTIFACT_CONTRACT_ADDRESS:process.env.VITE_STONE_CONTRACT_ADDRESS;
          // console.log("ticket tab:", nftResponse);
          this.inWalletItems = [];
          for (const row of itemList) {
              try { 
                 
                  const nftResponse = await axios.get(`https://metadata.kryptofighters.io/item/${filterType}/${row.tokenId}.json`);
                  console.log("ticket tab:", nftResponse);
                  // const apiUrl = `https://testnet-api.rarible.org/v0.1/items/KROMA:${contractAddress}:${row.tokenId}`;

                  // const headers = {
                  //     'accept': 'application/json',
                  //     'X-API-KEY': process.env.VITE_RARIBLE_API_KEY,
                  //     };

                      
                  // const nftResponse = await axios.get(apiUrl, {headers}  );
                  // console.log("ticket tab:", nftResponse);
                  if(nftResponse.data.attributes.type.toLowerCase() == filterType){
                    var rarity = 1;
                    switch(nftResponse.data.properties.rarity.toLowerCase()){
                      case 'rare': rarity = 3; break;
                      case 'epic': rarity = 4; break;
                      default: rarity = 1; break;
                    }
                    const temp = {
                        id: row.tokenId,
                        image: nftResponse.data.image,
                        rarity: rarity,
                        name: nftResponse.data.name,
                        amount: row.value,
                        type: nftResponse.data.attributes.type
                    };

                    this.inWalletItems.push(temp);
                  }
                  
              } catch (error) {
                  console.error(`Error fetching data for token ID ${row.tokenId}:`, error);
              }
          }
          },
    }
}
</script>
  
<style scoped>
/* Add your component-specific styles here */

.nav-tabs {
    color: gray;
    border: 0;
    border-bottom: 1px solid var(--primary-border-color); 
}

.nav-tabs .nav-link.active {
  background-color:  var(--soft-background-color); 
}

.nav-tabs .nav-link.inactive {
  background-color:  var(--soft-background-color); 
}


/* Change the background color of inactive tabs */
.nav-tabs .nav-link :hover{
    border-color: var(--primary-border-color); 
    
}

.active {
  background-color: var(--soft-background-color);
  border-radius: 6px;
  cursor: pointer;
}

.stat-section:hover {
  background-color: var(--soft-background-color);
  border-radius: 6px;
  cursor: pointer;
}

.item-section {
  background-color: #0C0E13;
  border-radius: 6px;
  cursor: pointer;
}

.item-section-active {
  background-color: var(--soft-background-color);
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid white;
}

.item-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(200px, 1fr));
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  padding: 0 10px;
}

.item-name {
  display: block; 
  /* max-width: 150px;  */
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
  max-width: 100%;
}

.text-rarity-1 {
    background-color: #B4B4B4; 
}
.text-rarity-3 {
    background-color: #06D8DB;
}
.text-rarity-4 {
    background-color: #B069FF; 
}

@media (max-width: 768px) {
  .item-grid {
    padding: 0;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }

  .item-name {
    /* max-width: 50px;  */
  }
}
</style>