<template>
    <OpeningItemPopup
        :show-modal="isOpening"
        :item="openItem"
        :is-confirmed="isConfirmed"
    />
    <RecieveItemPopup
        :show-modal="isRecieved"
        :item="recievedItem"
    />
  <div class="product-item">
    <!-- <div v-if="isLoading" style="margin-bottom: 50px;">
            <v-skeleton-loader :type="'card'" :animation="true" :width="200" :height="300" theme="dark"></v-skeleton-loader>
        </div>
        <div v-else> -->
    <div class="card product-card-container">
      <div class="card-body">
        <div class="product-header">
          <div class="left-content">
            <div class="product-id">
              # {{ item.name }}
            </div>
          </div>
        </div>
        <div
          class="image-container"
          style=" margin-bottom: 16px;padding:  16px;"
        >
          <img
            style="width: 96px; height: 96px;"
            :src="(item.image)? item.image:`/images/item/${item.item_code}.png`"
            alt="reward image"
            class="product-image"
          >
        </div>

        <div v-if="item.amount">
          <h4 class="product-usd-price">
            {{ item.amount }} PCS
          </h4>
        </div>

        <div v-if="!item.claimed">
          <button
            v-if="item.item_code !== 'ITM_LT_BOX'"
            type="button"
            class="btn btn-primary"
            @click="clickSend"
          >
            Send to Kroma
          </button>
          <button
            v-else
            type="button"
            class="btn btn-primary"
            @click="clickOpen"
          >
            Open
          </button>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
  <TransactionPendingPopup :message="message" />
</template>
  
<script>

import axios from 'axios';
import { ref, onMounted } from 'vue';
import { useStore } from '@/stores/index.ts';
import {useToast} from 'vue-toast-notification';
import TransactionPendingPopup from "@/components/popup/TransactionPendingPopup.vue";
import { ethers } from 'ethers';
import PortalContractABI from '@/contracts/PortalContractABI.json';
import NFTPortalABI from '@/contracts/NFTPortalABI.json';
import SuccessPopup from "@/components/popup/SuccessPopup.vue";
import OpeningItemPopup from "@/components/popup/OpeningItemPopup.vue";
import RecieveItemPopup from "@/components/popup/RecieveItemPopup.vue";
import LootboxABI from '@/contracts/LootboxABI.json';

export default {
    components: {
        TransactionPendingPopup,
        SuccessPopup,
        OpeningItemPopup,
        RecieveItemPopup
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            message: {},
            openItem: {},
            recievedItem: {
                id: '',
                name: '',
                url: '',
                amount: 0,
            },
        }
    },
    mounted() {
        //this.fetchNftData();
    },
    methods: {
        async clickSend() {
            var $ = jQuery.noConflict();

            var contractAddress = "0xf3e0E795CEbC5bf41cB521E22ef7D0F5769921D5";

            this.message = {
                    title: 'Transaction pending',
                    image: '../images/loading/transaction-loading.gif',
                    text: "Please do not close this page."
                }
            $(document).ready(function() {
                $('#transactionPendingPopup').modal('show');
            });

            try {

                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = await provider.getSigner(); // Use this if you have a signer (e.g., MetaMask)
                // Create an ethers.js contract instance
                console.log("this.item.id", this.item.id);
                console.log("this.item.amount", this.item.amount);

                var contractABI;
                if(this.item.id !== 11){
                    contractAddress = "0xC4c68B1c2492B8994385ECd273c5C239792392f4";
                    contractABI = NFTPortalABI; 
                }
                else {
                    contractABI = PortalContractABI;
                }
                console.log(contractAddress);

                const contract = new ethers.Contract(contractAddress, contractABI, signer);
                const depositAmountInEther = ethers.utils.parseEther((this.item.amount-1).toString());
                //const depositAmountInEther = ethers.utils.parseEther("1");
                if(this.item.id !== 11){
                    const tx = await contract.deposit(this.item.id-1, this.item.amount);
                    console.log("tx", tx);
                    await tx.wait();
                }else{
                    const tx = await contract.deposit(depositAmountInEther);
                    console.log("tx", tx);
                    await tx.wait();

                }
                

                useToast().open({
                        message: 'Send item completed.',
                        position: 'top-right',
                        type: 'success',
                    });

                $(document).ready(function() {
                    $('#transactionPendingPopup').modal('hide');
                });
                this.$emit('rewardSended');
            } catch (error) {
                console.error('Error deposit:', error);
                const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
                useToast().open({
                        message: 'Error: ' + errorMessage,
                        position: 'top-right',
                        type: 'error',
                    });
                
                $(document).ready(function() {
                    $('#transactionPendingPopup').modal('hide');
                });
                this.$emit('rewardSended');
            }
        },
        async clickOpen(){
            var openingAudio = document.getElementById('openingItemSFX');
            var recievedAudio = document.getElementById('recievedItemSFX');
            var $ = jQuery.noConflict();

            this.isOpening = true;
            this.isConfirmed = false;
            this.isRecieved = false;

            this.openItem = { ...this.item };
            this.openItem.itemCode = 'Lootbox';
            console.log(this.item);
            console.log(this.openItem);

            // $(document).ready(function() {
            //     $('#openingItemPopup').modal('show');
            // });
            $(document).ready(function() {
                $('#openingItemPopup').on('shown.bs.modal', function () {
                    var openingAudio = document.getElementById('openingItemSFX');
                    if (openingAudio) {
                        openingAudio.play().catch(error => {
                            console.error('Error playing audio:', error);
                        });
                    } else {
                        console.error('Audio element not found');
                    }
                });

                $('#openingItemPopup').modal('show');
            });
            // openingAudio.play();

            try {
                const contractAddress =  "0x69173289FA628a8772BC943dA27Bfe2AE25C3E0C";

                // console.log(ethers);
                
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = await provider.getSigner();
                //const walletWithProvider = new ethers.Wallet(getRandomPrivateKey(config.walletPrivateKeys), provider);
                const contract = new ethers.Contract(contractAddress, LootboxABI, signer);
                console.log("contractAddress", contractAddress);
                const tx = await contract.open();
                console.log("tx", tx);

                this.isConfirmed = true;
                openingAudio.play();

                await tx.wait();

                console.log(tx);
                const receipt = await provider.getTransactionReceipt(tx.hash);

                if (receipt && receipt.logs) {
                    // Filter logs for the "LootBoxOpened" event
                    console.log("receipt.logs", receipt.logs);
                    //const eventFragment = contract.interface.getEvent("LootBoxOpened");

                   // const topicHash = keccak256("LootBoxOpened");
                    //console.log("topicHash", topicHash);
                    //console.log("eventFragment", eventFragment);
                    
                    const lootBoxOpenedLogs = receipt.logs.filter(log => {
                        try {
                            const parsedLog = contract.interface.parseLog(log);
                            return parsedLog.name === "LootBoxOpened";
                        } catch (error) {
                            return false;
                        }
                    });
                    
                    // Parse and access event data
                    lootBoxOpenedLogs.forEach(log => {
                        const eventData = contract.interface.parseLog(log);
                        console.log("LootBoxOpened:", eventData.args.user, eventData.args.rewards.toString());

                        this.getItemDetail(eventData.args.rewards.toString());
                    });

                    useToast().open({
                        message: 'Lootbox Opened',
                        position: 'top-right',
                        type: 'success',
                    });

                    if(this.item.amount != null){
                        this.item.amount--;
                    }

                } else {
                    console.log("No logs found in the transaction.");
                }

                this.isOpening = false;
                $(document).ready(function() {
                    $('#openingItemPopup').modal('hide');
                });

                this.isRecieved = true;
                $(document).ready(function() {
                    $('#recieveItemPopup').modal('show');
                });
                recievedAudio.play();
            } catch (error) {
                console.error("Error:", error);
                const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
                useToast().open({
                    message: 'Error: ' + errorMessage,
                    position: 'top-right',
                    type: 'error',
                })
                
                this.isOpening = false;
                $(document).ready(function() {
                    $('#openingItemPopup').modal('hide');
                });
                    
               // this.item.image = saveImageUrl;
            }
        },
        getItemDetail(id){
            console.log(id);
            switch(id){
                case '1':   this.recievedItem.id = 'JAB';
                            this.recievedItem.name = 'JAB';
                            this.recievedItem.amount = 20;
                            break;
                case '2':   this.recievedItem.id = 'JAB';
                            this.recievedItem.name = 'JAB';
                            this.recievedItem.amount = 40;
                case '3':   this.recievedItem.id = 'JAB';
                            this.recievedItem.name = 'JAB';
                            this.recievedItem.amount = 80;
                            break;
                case '4':   this.recievedItem.id = 'JAB';
                            this.recievedItem.name = 'JAB';
                            this.recievedItem.amount = 200;                           
                            break;
                case '7':   this.recievedItem.id = 'ITM_FM_MCC';
                            this.recievedItem.name = 'Microchip';
                            this.recievedItem.amount = 1;
                            break;
                case '8':   this.recievedItem.id = 'ITM_FM_GLB';
                            this.recievedItem.name = 'Gold Bar';
                            this.recievedItem.amount = 1;
                            break;
                case '9':   this.recievedItem.id = 'ITM_FM_HLB';
                            this.recievedItem.name = 'Holo Bar';
                            this.recievedItem.amount = 1;
                            break;
                case '10':   this.recievedItem.id = 'NFT_TICKET';
                            this.recievedItem.name = 'NFT Ticket';
                            this.recievedItem.amount = 1;
                            break;
            }

            console.log(this.recievedItem);
        }
    }
};
</script>
  
<style scoped>
.product-item {
    display: flex;
    text-align: center;
    margin-bottom: 0px;
    align-items: center;
}

.product-image {
    max-width: 100%;
    height: 100%;
    width: auto;
    object-fit: cover;
}

.product-name {
    font-size: 16px;
    text-align: center;
    align-items: center;
    /* Center items vertically */
    justify-content: space-between;
    width: 100%;
}



.product-usd-price {
    margin-top: 0px;
    font-size: 14px;
    color: #A4A5A5;
}

.product-time {
    margin-top: 0px;
    padding-top: 0px;
    font-size: 14px;
    color: #A4A5A5;
}



.product-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.with-class-icon {
    background-image: url('@/assets/icon-stats-kroma.png');
    background-size: 20px 20px;
    /* Set the size of your icon */
    background-repeat: no-repeat;
    padding-left: 30px;
    /* Adjust the padding to make space for the icon */
}



.icon-element-right {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    /* Adjust margin to create space between the two icons */
}

.left-content {
    display: flex;
    align-items: center;
}

.link {
    text-decoration: none;
}

.open-button {
    height: 20%;
    width: 100%;
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    font-size: 16px;
    border: 2px solid #2980b9;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: background-color 0.3s, transform 0.3s;
}

.open-button:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
}

.clickable-image {
    cursor: pointer;
}

@media (max-width: 768px) {
    .product-image {
    width: 120px;
    margin-bottom: 0px;
}
}

</style>