<template>
  <div class="page-content">
    <ListForSalePopup :product="fighter" :type="'fighter'"/>
    <TransferPopup :product="fighter" :type="'fighter'"/>
    <ConfirmPurchasePopup :product="fighter" @purchaseSuccess="purchaseSuccess"/>
    <OfferPopup :product="fighter" @fetchEvent="fetchEvent"/>
    <ProfilePicturePopup :imgUrl="fighter?.properties?.fighter_sheet_url?? ''" :classId="fighter?.properties?.class_id?? 0"/>
    <div v-if="fighter?.image" >
      <div
        class="container  "
        style=""
      >
      <div
        class="left-container"
        style="width: 100%; position: relative; display: flex; flex-direction: column; align-items: flex-start;"
      >
        <!-- Background Loading -->
        <div 
          v-if="isBGLoading"
          :style="{ width: '100%', height: '360px' , backgroundColor: 'var(--primary-background-color)' }"
        >
        </div>

        <!-- Fighter Background -->
        <div 
          v-else
          class="card fighter-background"
          :style="{ '--fighter-background-url': `url(${fighterBackgroundUrl})` }"
          style="position: absolute; height: 100%; top: 0; width: 100%;"
        >
        </div>

        <!-- Loader -->
        <div v-if="isFighterLoading" class="loader" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"></div>

        <!-- Card Body -->
        <div 
          v-else
          class="card-body"
          style="text-align: center; position: absolute; top: 0; width: 100%;"
        >
          <SpriteAnimation
            :sprite-sheet-url="fighter.properties.fighter_sheet_url"
            :total-frames="15"
            style="margin: auto;"
          />
          <img
            src="../assets/shadow.png"
            alt="Image"
            style="position: relative; top: -15px; width: 120px; height: 13px;"
          >
        </div>
      </div>
        <div class="right-container">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <img
                        :src="fighter.classImgUrl"
                        style="width: auto; height: 32px"
                        alt="Sample Image"
                      >
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h3>Fighter #{{ fighter.properties.token_id }} </h3>
                    </div>
                    <div v-if="isOwner == true">
                      <div class="dropdown">
                        <button
                          id="dropdownMenuButton1"
                          class="btn dropdown-toggle custom-dropdown-more-menu align-items-center"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <vue-feather type="more-vertical" />
                        </button>
                        <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li
                            class="dropdown-item"
                            data-bs-toggle="modal"
                            data-bs-target="#transferPopup"
                            @click="showModal = true"
                          >
                            Transfer
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row default-row" style="padding-bottom: 20px; padding-top: 10px;">
                <div class="col">
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <img
                        src="../assets/class-users-default.png"
                        style="width: auto; height: 40px;padding-top: 4px;"
                        alt="Sample Image"
                      >
                    </div>
                    <div class="flex-grow-1 ms-3">
                      Owner <br><span class="badge bg-custom-secondary">#{{ fighter.owner_address }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="isItemListed == true"
                style="margin-bottom: 20px;"
              >
                <div class="text-solf-light">
                  Current Price
                </div>
                <div class="d-flex ">
                  <h4>{{ currentPriceCurrency }} {{ currentPrice.toFixed(3) }}</h4>
                  <!-- <div
                    class="text-solf-light"
                    style="margin-left: 8px; margin-top: 4px;"
                  >
                    ${{
                      currentPriceCurrencyUsd.toFixed(2) }}
                  </div> -->
                </div>
              </div>
             
                <div v-if="isOwner == false">
                  <div v-if="isItemListed == true">
                    <button
                      class="btn btn-primary d-flex justify-content-center align-items-center"
                      style="width: 100%; margin-bottom: 20px;"
                      data-bs-toggle="modal"
                      data-bs-target="#purchaseConfirmationModal"
                      @click="makeOffer"
                    >
                      <vue-feather
                        type="shopping-cart"
                        style="margin-right: 6px;"
                      /> Buy Now
                    </button>
                    <button
                      class="btn default-button d-flex justify-content-center align-items-center"
                      style="width: 100%;"
                      data-bs-toggle="modal"
                      data-bs-target="#offerPopup"
                      @click="makeOffer"
                    >
                      <vue-feather
                        type="tag"
                        style="margin-right: 6px;"
                      /> Make Offer
                    </button>
                  </div>
                </div>

                <div v-else>
                  <div v-if="isListable">
                    <div v-if="isItemListed == true">
                      <button
                        class="btn default-button d-flex justify-content-center align-items-center"
                        style="width: 100%; background-color: darkred;"
                        @click="cancelListing"
                        :disabled="isCancelLoading"
                      >
                      <span v-if="isCancelLoading"><i class="fa fa-circle-o-notch fa-spin" /></span>
                      <span v-else> Cancel list </span>
                      </button>
                      <!-- <div v-else>
                        <button
                          class="btn default-button d-flex justify-content-center align-items-center"
                          style="width: 100%;"
                          data-bs-toggle="modal"
                          data-bs-target="#listForSalePopup"
                          @click="showModal = true"
                        >
                          Edit listing
                        </button>
                      </div> -->
                    </div>
                    <div v-else>
                      <button
                        class="btn default-button d-flex justify-content-center align-items-center"
                        style="width: 100%;"
                        data-bs-toggle="modal"
                        data-bs-target="#listForSalePopup"
                        @click="showModal = true"
                      >
                        List for sale
                      </button>
                    </div>
                    <br>
                  </div>

                  <button
                    class="btn default-button d-flex justify-content-center align-items-center"
                    style="width: 100%;"
                    @click="coach"
                  >
                    Coach
                  </button>
                  <br>
                  <button
                    class="btn default-button d-flex justify-content-center align-items-center"
                    style="width: 100%;"
                    data-bs-toggle="modal"
                    data-bs-target="#profilePicturePopup"
                    @click="showModal=true"
                    :disabled="true"
                  >
                    Generate Profile Picture
                  </button>
                </div>
              <div v-if="offerEvent.length > 0 && isItemListed == true" class="card product-card-container" style="margin-top: 20px; padding: 8px 0 18px 0;">
                <div style="padding: 0px 10px 5px 20px; display: inline-flex; justify-content: space-between; align-items: center;">
                  <div> Offers </div>
                  <button 
                    v-if="isOwner"
                    class="btn btn-green"
                    @click="acceptOffer"
                    :disabled="isAcceptLoading"
                    style="width: 30%;"
                  >
                    <span v-if="isAcceptLoading"><i class="fa fa-circle-o-notch fa-spin" /></span>
                    <span v-else> Accept </span>
                  </button>
                </div>
                <div class="" style="border-bottom: 0.3px solid var(--secondary-text-color); border-top: 0.3px solid var(--secondary-text-color);">
                  <div class="row" style="padding: 0px 10px 0 20px; margin: 0; color: var(--secondary-text-color); font-weight: 200; font-size: 14px;">
                    <div class="col" style="padding: 0px;">
                      JAB Price
                    </div>
                    <div class="col" style="padding: 0px;">
                      USD Price
                    </div>
                    <div class="col" style="padding: 0px;">
                      From
                    </div>
                  </div>
                </div>
                <div v-for="(x,index) in offerEvent" :key="index" class="row" style="padding: 5px 10px 0 20px; margin: 0; ">
                  <div class="col" style="padding: 0px;">
                    {{ x.offerPrice }}
                  </div>
                  <div class="col" style="padding: 0px;">
                    -
                  </div>
                  <div class="col" style="padding: 0px;">
                    {{ x.maker }}
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </div>
      </div>
      <div class="left-content default-row">
        <div
          class="card"
          style="width: 100%;"
        >
          <div class="card-body">
            <h5 class="card-title">
              Traits
            </h5>
            <div class="">
              <div class="row trait-row">
                <div
                  class="col-md-3"
                  style=""
                >
                  <div style="justify-content: center;">
                    <p class="stat-title">
                      Class
                    </p>
                    <div style="display: flex;">
                      <img
                        :src="fighter.classImgUrl"
                        alt="Product Icon"
                        style="width: 20px;height: 20px; margin-right: 6px;"
                      >
                      <h6 class="stat-value">
                        {{ fighter.properties.class_name }}
                      </h6>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-3"
                  style=""
                >
                  <div style="justify-content: center;">
                    <p class="stat-title">
                      Coaching Count
                    </p>
                    <h6 class="stat-value">
                      {{ fighter.properties.coaching_count }} / 6
                    </h6>
                  </div>
                </div>
                <div
                  class="col-md-3"
                  style=""
                >
                  <div style="justify-content: center;">
                    <p class="stat-title">
                      Element
                    </p>
                    <div style="display: flex;">
                      <img
                        v-if="fighter.elementUrl !== ''"
                        :src="fighter.elementUrl"
                        alt="Product Icon"
                        style="width: 20px;height: 20px; margin-right: 6px;"
                      >
                      <h6 class="stat-value">
                        {{ fighter.element }}
                      </h6>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-3"
                  style=""
                >
                  <div style="justify-content: center;">
                    <p class="stat-title">
                      Spirit Socket
                    </p>
                    <div style="display: flex;">
                      <img
                        v-if="fighter.properties.spirit_socket_1 > 0 && fighter.properties.spirit_socket_1 < 6"
                        :src="`/images/runes/shape-${fighter.properties.spirit_socket_1}.png`"
                        alt="Spirit socket 1 Icon"
                        style="width: 20px;height: 20px; margin-right: 6px;"
                      >
                      <img
                        v-if="fighter.properties.spirit_socket_2 > 0 && fighter.properties.spirit_socket_2 < 6"
                        :src="`/images/runes/shape-${fighter.properties.spirit_socket_2}.png`"
                        alt="Spirit socket 2 Icon"
                        style="width: 20px;height: 20px; margin-right: 6px;"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div
                class="col-md-3"
                style=""
              >
                <div style="justify-content: center;">
                  <p class="stat-title">
                    Season
                  </p>

                  <h6 class="stat-value">
                    {{ fighter.properties.season_id }}
                  </h6>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div style="min-width: 160px;">
                <div style="justify-content: center;">
                  <p class="stat-title">
                    Body Part
                  </p>
                  <div class="accessories-container">
                    <figure
                      v-for="x in fighter.bodyparts"
                      class="col-md-3 text-center"
                      style=""
                    >
                      <v-tooltip location="top">
                        <template #activator="{ props }">
                          <div v-bind="props">
                            <div
                              v-if="isHasSuperMove"
                              class="accessory"
                            >
                              <div
                                class="accessory-image"
                                style="position: relative;"
                              >
                                <div
                                  v-if="x.element !== ''"
                                  class="body-element-frame"
                                  :class="'element-color-'+x.element.toLowerCase()"
                                />
                                <img
                                  :src="`../images/parts/${x.id}.png`"
                                  alt="Accessory Image"
                                  style="position: absolute;"
                                >
                              </div>
                              <h6 class="accessory-title">
                                {{ x.name }}
                              </h6>
                            </div>
                          </div>
                        </template>
                        <span>
                          <div
                            class="text-center"
                            style="width: 200px; padding: 10px;"
                          >
                            <div style="display: inline-flex">
                              <img
                                :src="x.element_url"
                                alt="Product Icon"
                                onerror="this.onerror=null; this.src='/images/elements/frames/EMPTY.png';"
                                style="width: 20px;height: 20px; margin-right: 6px;"
                              >
                              <h6><b>Bonus Stats</b></h6>
                            </div>
                          
                            <div v-for="s in GetExtraStat(x.element.toUpperCase())">
                              <div v-if="s">
                                {{ s.stat?? '' }} +{{ s.magnitude?? 0 }}
                              </div>
                            </div>
                            <div style="display: inline-flex; font-weight: bold;">
                              <img
                                v-if="x.skill.skill_code != ''"
                                :src="`/images/skills/${x.skill.skill_code?? ''}.png`"
                                alt="Product Icon"
                                onerror="this.onerror=null; this.src='/images/cards/card/0.png';"
                                style="width: 20px;height: 20px; margin-right: 6px;"
                              >
                              <img
                                v-else
                                :src="`/images/cards/card/${x.skill.skill_id}.png`"
                                alt="Product Icon"
                                onerror="this.onerror=null; this.src='/images/cards/card/0.png';"
                                style="width: 20px;height: 20px; margin-right: 6px;"
                              >
                              {{ x.skill?.skill_name?? '' }}
                            </div>
                          </div>
                        </span>
                      </v-tooltip>
                    </figure>
                  <!-- Accessory 1 -->
                  <!-- <div v-if="this.isHasSuperMove" class="accessory">
                    <div class="accessory-image" style="position: relative;">
                      <div v-if="this.fighter.properties.body_part_top_element !== ''" class="body-element-frame" :class="'element-color-'+fighter.properties.body_part_top_element.toLowerCase()"></div>
                      <img :src="`../images/parts/${this.fighter.properties.body_part_top_id}.png`" alt="Accessory Image" style="position: absolute;" />
                    </div>
                    <h6 class="accessory-title">{{ this.fighter.properties.body_part_top }}</h6>
                  </div> -->
                  
                  <!-- Accessory 2 -->
                  <!-- <div v-if="this.isHasSuperMove" class="accessory">
                    <div class="accessory-image" style="position: relative;">
                      <div v-if="this.fighter.properties.body_part_face_element !== ''" class="body-element-frame" :class="'element-color-'+fighter.properties.body_part_face_element.toLowerCase()"></div>
                      <img :src="`../images/parts/${this.fighter.properties.body_part_top_face_id}.png`" alt="Accessory Image" style="position: absolute;" />
                    </div>
                    <h6 class="accessory-title">{{ this.fighter.properties.body_part_top_face }}</h6>
                  </div> -->
                  
                  <!-- Accessory 3 -->
                  <!-- <div v-if="this.isHasSuperMove" class="accessory">
                    <div class="accessory-image" style="position: relative;">
                      <div v-if="this.fighter.properties.body_part_body_element !== ''" class="body-element-frame" :class="'element-color-'+fighter.properties.body_part_body_element.toLowerCase()"></div>
                      <img :src="`../images/parts/${this.fighter.properties.body_part_body_id}.png`" alt="Accessory Image" style="position: absolute;" />
                    </div>
                    <h6 class="accessory-title">{{ this.fighter.properties.body_part_body }}</h6>
                  </div> -->
                  
                  <!-- Accessory 4 -->
                  <!-- <div v-if="this.isHasSuperMove" class="accessory">
                    <div class="accessory-image" style="position: relative;">
                      <div v-if="this.fighter.properties.body_part_pant_element !== ''" class="body-element-frame" :class="'element-color-'+fighter.properties.body_part_pant_element.toLowerCase()"></div>
                      <img :src="`../images/parts/${this.fighter.properties.body_part_pant_id}.png`" alt="Accessory Image" style="position: absolute;" />
                    </div>
                    <h6 class="accessory-title">{{ this.fighter.properties.body_part_pant }}</h6>
                  </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="left-content">
        <div
          class="card"
          style="width: 100%;"
        >
          <div class="card-body">
            <h5 class="card-title">
              Utilities
            </h5>
            <br>
            <div class="">
              <div
                class="row ultilities-row"
                style="justify-content: center;"
              >
                <div
                  class="col-md-3"
                  style="justify-content: center;"
                >
                  <div class="text-center">
                    <p class="stat-title">
                      HP
                    </p>
                    <h6 class="stat-value">
                      {{ currentHp }}
                    </h6>
                  </div>
                </div>
                <div
                  class="col-md-3"
                  style=""
                >
                  <div class="text-center">
                    <p class="stat-title">
                      Attack
                    </p>
                    <h6 class="stat-value">
                      {{ currentAttack }}
                    </h6>
                  </div>
                </div>
                <div
                  class="col-md-3"
                  style=""
                >
                  <div class="text-center">
                    <p class="stat-title">
                      Speed
                    </p>
                    <h6 class="stat-value">
                      {{ currentSpeed }}
                    </h6>
                  </div>
                </div>
                <div
                  class="col-md-3"
                  style=""
                >
                  <div class="text-center">
                    <p class="stat-title">
                      Spirit Points
                    </p>
                    <div v-if="(fighter?.properties?.spirit_points?? '') !== ''" style="display: flex; gap: 10px; justify-content: center;">
                      <div
                        v-for="(value, key) in ParseSpiritPoint(fighter.properties.spirit_points)"
                        :key="key"
                      >
                        <div v-if="key !== ''">
                          <img
                            v-if="GetElementImage(key) !== ''"
                            :src="GetElementImage(key)"
                            alt="Spirit Point Icon"
                            style="width: 20px; height: 20px; margin-right: 6px;"
                          >
                          <span> {{ value }} </span>
                        </div>
                      </div>
                      <!-- <img
                        :src="fighter.elementUrl"
                        alt="Product Icon"
                        style="width: 20px; height: 20px; margin-right: 6px;"
                      >
                      <h6 class="stat-value">
                        10
                      </h6> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr>

            <div class="row">
              <div style="justify-content: center;">
                <p class="stat-title">
                  Basic Move
                </p>
              </div>
            </div>
            <div
              class=""
              style="margin-top: 20px;"
            >
              <div class="row skill-row">
                <figure
                  v-for="x in fighter.properties.skills"
                  class="col-md-3 text-center"
                  style=""
                >
                  <v-tooltip location="top">
                    <template #activator="{ props }">
                      <div v-bind="props">
                        <img
                          :src="x.image_url || `/images/skills/${x.skill_code?? ''}.png`"
                          style="width: auto; height: 64px; margin-bottom: 8px;"
                          alt="Skill Image"
                          onerror="this.onerror=null; this.src='/images/cards/card/0.png';"
                        >
                        <h6 class="stat-value">
                          {{ x.skill_name }}
                        </h6>
                      </div>
                    </template>
                    <span>
                      <div
                        class="text-center"
                        style="width: 240px; padding-top: 10px;"
                      >
                        <div
                          class="row"
                          style="justify-content: center;"
                        >
                          <div
                            v-if="x.use == 2 || x.use == 3"
                            style="display: inline-flex; gap: 10px; justify-content: center;"
                          >
                            <img
                              :src="GetSkillPositionUrl('buff', x.required_position, x.target_position)"
                              style="width: auto; height: 16px"
                              alt="Sample Image"
                            >
                            <img
                              :src="GetSkillPositionUrl('none', '', 'xxxx')"
                              style="width: auto; height: 16px"
                              alt="Sample Image"
                            >
                          </div>
                          <div
                            v-else
                            class=""
                            style="display: inline-flex; gap: 10px; justify-content: center;"
                          >
                            <img
                              :src="GetSkillPositionUrl('prefer', x.required_position)"
                              style="width: auto; height: 16px"
                              alt="Sample Image"
                            >
                            <img
                              :src="GetSkillPositionUrl('target', x.target_position)"
                              style="width: auto; height: 16px"
                              alt="Sample Image"
                            >
                          </div>
                        </div>
                        <div class="row">
                          <p>
                            <span v-html="x.description" />
                          </p>
                        </div>
                      </div>
                    </span>
                  </v-tooltip>
                </figure>
              </div>
            </div>
            <hr>
            <div class="row">
              <div>
                <p class="stat-title">
                  Super Move
                </p>
                <div v-if="isHasSuperMove">
                  <div
                    v-if="cards.length > 0"
                    class="bootster-row"
                    style="margin-top: 20px; display: flex; flex-wrap: wrap; align-items: center; gap: 10px;"
                  >
                    <div
                      v-for="(x, i) in cards"
                      :key="i"
                      style=""
                    >
                      <div v-if="x !== 0">
                        <v-tooltip location="top">
                          <template #activator="{ props }">
                            <div v-bind="props">
                              <CardDetail
                                :card-id="x"
                                :body-part="body_part[i]"
                              />
                            </div>
                          </template>

                          <span>
                            <div
                              class="text-center"
                              style="width: 240px; padding-top: 10px;"
                            >
                              <div
                                class="row"
                                style="justify-content: center;"
                              >
                                <div
                                  v-if="GetCardData(x).card_type == 'buff'"
                                  style="display: inline-flex; gap: 10px; justify-content: center;"
                                >
                                  <img
                                    :src="GetSkillPositionUrl('buff', GetCardData(x).card_use_pos, GetCardData(x).card_target_pos)"
                                    style="width: auto; height: 16px"
                                    alt="Sample Image"
                                  >
                                  <img
                                    :src="GetSkillPositionUrl('none', '', 'xxxx')"
                                    style="width: auto; height: 16px"
                                    alt="Sample Image"
                                  >
                                </div>
                                <div
                                  v-else
                                  style="display: inline-flex; gap: 10px; justify-content: center;"
                                >
                                  <img
                                    :src="GetSkillPositionUrl('prefer', GetCardData(x).card_use_pos)"
                                    style="width: auto; height: 16px"
                                    alt="Sample Image"
                                  >
                                  <img
                                    :src="GetSkillPositionUrl('target', GetCardData(x).card_target_pos)"
                                    style="width: auto; height: 16px"
                                    alt="Sample Image"
                                  >
                                </div>
                              </div>
                              <div class="row">
                                <p>
                                  <span v-html="GetCardDescription(x)" />
                                </p>
                              </div>
                            </div>
                          </span>
                        </v-tooltip>
                      </div>
                      <div v-else>
                        <div style="height: 100%;">
                          <div class="text-center card-text-title">
                            <img
                              src="/images/cards/card-default.png"
                              class="card-image"
                              style="min-height: 330px; max-height: 330px; margin-left: -10px;"
                              alt="Default Card Image"
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <CardDetail v-for="(cardId, index) in visibleCards" :key="index" :cardId="cardId" /> -->
                  </div>
                  <div
                    v-else
                    class="row bootster-row"
                    style="margin-top: 20px; gap: 10px; "
                  >
                    <div
                      class="col-md-4"
                      style=""
                    >
                      <div class="text-center card-text-title">
                        <img
                          src="/images/cards/card-default.png"
                          class="card-image"
                          style="min-height: 330px; max-height: 330px; margin-left: -10px;"
                        >
                      </div>
                    </div>
                    <div
                      class="col-md-4"
                      style=""
                    >
                      <div class="text-center card-text-title">
                        <img
                          src="/images/cards/card-default.png"
                          class="card-image"
                          style="min-height: 330px; max-height: 330px; margin-left: -10px;"
                        >
                      </div>
                    </div>                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="left-content default-row">
        <div
          class="card"
          style="width: 100%;"
        >
          <div class="card-body">
            <h5 class="card-title">
              Appendix
            </h5>
            <div class="row">
              <div style="justify-content: center;">
                <p class="stat-title">
                  Recommended Position
                </p>
                <img
                  :src="skillPositionImage"
                  class="pos-image"
                  style=""
                  alt="Sample Image"
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="left-content">
        <div
          class="card"
          style="width: 100%;"
        >
          <div class="card-body">
            <h5 class="card-title">
              Mastery Relation
            </h5>
            <div class="row">
              <div style="justify-content: center;">
                <p class="stat-title">
                  Masters
                </p>
                <div
                  class="row"
                  style="margin-top: 20px;"
                >
                  <div
                    class="col-md-6"
                    style=" "
                  >
                    <div class="card product-card-container text-center">
                      <button v-if="fighter.properties.m1id !== null && fighter.properties.m1id !== '0'"
                          style="text-decoration: none;"
                          @click="redirectToFighter(master1?.properties?.token_id)"
                        >
                        <div style="display: flex; color: white; text-decoration: none;">
                            <div class="crop-container">
                                <img id="master1Id" :src="master1?.properties?.fighter_sheet_url?? ''" alt="master1 Image">
                            </div>
                            <div style="display: flex; justify-content: center; flex-direction: column;">
                              <h5 style="font-weight: bold;"> Fighter #{{ master1?.properties?.token_id?? '' }} </h5>
                              <div style="display: flex; width: 100%;">
                                <img
                                  :src="master1?.classImgUrl?? ''"
                                  alt="Product Icon"
                                  style="width: 20px;height: 20px; margin-right: 6px;"
                                >
                                <h6 class="stat-value">
                                  {{ master1?.properties?.class_name?? '' }}
                                </h6>
                              </div>
                            </div>
                        </div>
                      </button>
                      <div v-else class="card-body stat-title">
                        None<br>
                        (Genesis Fighter)
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-6"
                    style=""
                  >
                    <div class="card product-card-container text-center">
                      <button v-if="fighter.properties.m2id !== null && fighter.properties.m2id !== '0'"
                          style="text-decoration: none;"
                          @click="redirectToFighter(master2?.properties?.token_id)"
                        >
                        <div style="display: flex; color: white; text-decoration: none;">
                          <div class="crop-container">
                              <img id="master2Id" :src="master2?.properties?.fighter_sheet_url?? ''" alt="master2 Image">
                          </div>
                          <div style="display: flex; justify-content: center; flex-direction: column;">
                            <h5 style="font-weight: bold;"> Fighter #{{ master2?.properties?.token_id?? '' }} </h5>
                            <div style="display: flex; width: 100%;">
                              <img
                                :src="master2?.classImgUrl?? ''"
                                alt="Product Icon"
                                style="width: 20px;height: 20px; margin-right: 6px;"
                              >
                              <h6 class="stat-value">
                                {{ master2?.properties?.class_name?? '' }}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </button>
                      <div v-else class="card-body stat-title">
                        None<br>
                        (Genesis Fighter)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="left-content default-row">
        <RecentActivity
          :token-id="$route.query.id"
          :asset-event="assetEvent"
        />
      </div>
      <div class="left-content default-row">
        <SaleHistory
          :token-id="$route.query.id"
          :asset-event="salesEvent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { getClassImageUrl ,getElementImageUrl } from '@/utils/imageUtils.js';
import SpriteAnimation from '@/components/SpriteAnimation.vue';
import { shortenedWalletAddress_rarible, shortenedWalletAddress_rarible2 } from '@/utils/walletUtils.js';
import SaleHistory from '@/components/SaleHistory.vue';
import RecentActivity from '@/components/RecentActivity.vue';
import ListForSalePopup from "@/components/popup/ListForSalePopup.vue";
import TransferPopup from "@/components/popup/TransferPopup.vue";
import ConfirmPurchasePopup from "@/components/popup/ConfirmPurchasePopup.vue";
import OfferPopup from "@/components/popup/OfferPopup.vue";
import ProfilePicturePopup from "@/components/popup/ProfilePicturePopup.vue";
import { convertToUSD } from '@/utils/exchangeUtils.js';
import { useExchangeStore } from '@/stores/exchangeRate.ts';
import { convertDateString } from '@/utils/timeUtils.js';
import { useStore } from '@/stores/index.ts';
import CardDetail from '@/components/CardDetail.vue';

import FighterERC721ABI from '@/contracts/FighterERC721ABI.json';
import { ethers } from 'ethers';

import { useCardStore } from '@/stores/card.ts';
import { useSkillStore } from '@/stores/skill.ts';

import { createRaribleSdk } from "@rarible/sdk";
import { toItemId, toOrderId } from "@rarible/types";

import {useToast} from 'vue-toast-notification';

export default {
  components: {
    SpriteAnimation,
    SaleHistory,
    RecentActivity,
    ListForSalePopup,
    TransferPopup,
    ConfirmPurchasePopup,
    CardDetail,
    OfferPopup,
    ProfilePicturePopup
  },
  data() {
    return {
      fighter: {},
      master1: {},
      master2: {},
      skillPositionImage: "",
      externalFighterClassUrl: "",
      rarityBgColor: '#ffffff',
      skillFsBgColor: '#ffffff',
      isOwner: false,
      showModal: true,
      ultimateSkill: {
        skill_name: "",
        description: "",
        image_url: "",
        required_position: "",
        target_position: ""
      },
      fighterBackgroundUrl: "url('src/assets/backgrounds/10.png')",
      assetEvent: {},
      salesEvent: {},
      offerEvent: {},
      currentPrice: 0,
      currentPriceCurrency: '',
      currentPriceCurrencyUsd: 0,
      isItemListed: false,
      cards: [],
      body_part: [],
      contract_addr: '',
      isListable: true,
      isHasSuperMove: true,
      currentHp:0,
      currentAttack: 0,
      currentSpeed: 0,
      isCancelLoading: false,
      isAcceptLoading: false,
      isBGLoading: true,
      isFighterLoading: true
    }
  },
  computed: {
      visibleCards() {
      const maxCardsToShow = 2;
      return this.cards.slice(0, maxCardsToShow);
      },
  },
  async mounted() {
    // Fetch image URL when the component is mounted
    try {
      await useCardStore().fetchCardData();
      // await useCardStore().fetchPartMapper();
      await useSkillStore().fetchMapSkillData();
    } catch (error) {
      console.error('Error fetching card data:', error);
    }

    this.fetchFighterData().then(() => {
      this.fetchNFTData();
      this.fetchEvent();
    });

  },
  methods: {
    GetElementImage(element){
        return getElementImageUrl(element);
    },
    ParseSpiritPoint(pointList){
        return JSON.parse(pointList);
    },
    redirectToFighter(id) {
      window.location.href = `https://testnet.kryptofighters.io/fighter?id=${id}`;
    },
    GetConcatUrl(base, end) {
      let path = base + end;
      return path;
    },
    GetSkillPositionUrl(type, Pos1, Pos2='') {
      
      let path = "";
      if(type == 'buff' && Pos1 != '' && Pos2 != ''){
        path = '../images/positions/'+Pos1+'-'+Pos2+'.png';
      }else if(type == 'none' && Pos2 != ''){
        path = '../images/positions/'+Pos2+'.png';
      }
      else{
        if(Pos1 != ''){
          path = "https://info.kryptofighters.io/images/position/" + type + "-" + Pos1 + ".png";
        }
      }
      //console.log("GetSkillPositionUrl "+ path);
      return path;
    },
    GetRarityBgColor(rarityId) {
      switch (rarityId) {
        case 1: this.rarityBgColor = '#B4B4B4'; break;
        case 2: this.rarityBgColor = '#29D403'; break;
        case 3: this.rarityBgColor = '#06D8DB'; break;
        case 4: this.rarityBgColor = '#B069FF'; break;
        case 5: this.rarityBgColor = '#DBC22D'; break;
      }
    },
    GetRarityBg(rarityId) {
      switch (rarityId) {
        case 1: this.fighterBackgroundUrl = new URL('/src/assets/backgrounds/2.1.png', import.meta.url); break;
        case 2: this.fighterBackgroundUrl = new URL('/src/assets/backgrounds/2.2.png', import.meta.url); break;
        case 3: this.fighterBackgroundUrl = new URL('/src/assets/backgrounds/4.1.png', import.meta.url); break;
        case 4: this.fighterBackgroundUrl = new URL('/src/assets/backgrounds/10.png', import.meta.url); break;
        case 5: this.fighterBackgroundUrl = new URL('/src/assets/backgrounds/9.png', import.meta.url); break;
      }

      const img = new Image();
        img.src = this.fighterBackgroundUrl;
        img.onload = () => {
          this.isBGLoading = false;
        };
        img.onerror = () => {
          this.isBGLoading = true;
        };
    },
    GetAccessoryImage(accId) {
      if(accId==156){
        return '../images/accessories/none.png';
      }
      if(accId==null){
        return '../images/accessories/none.png';
      }
      return accId ? `../images/accessories/Accessory_${accId}.png` : '';
    },
    GetCostumeImage(accId) {
      if(accId==156){
        return '../images/accessories/none.png';
      }
      if(accId==null){
        return '../images/accessories/none.png';
      }
      return accId ? `../images/costumes/Costume_${accId}.png` : '';
    },
    GetSkillFsBgColor(fs) {
      let color = '';
      console.log('fs', fs);
      if (fs.includes('-')) {
        color = '#BA1515';
      }
      else {
        if (fs > 0) {
          color = '#1C9D40';
        }
        else {
          color = '#696969';
        }
      }
      console.log('color', color);
      return color;
    },
    GetCards(){
      this.body_part = [this.fighter.bodyparts[0], this.fighter.bodyparts[2]];
      this.body_part.forEach((data, index) => {
        // console.log("part id"+data.id);
        // console.log("part name"+data.name);
        var _temp = useCardStore().getCardIdFromPart(data.id);
        this.cards.push(_temp);
        var _card = this.GetCardData(_temp);
        this.fighter.bodyparts.find(f=>f.id == data.id).skill = {
                                                                  skill_id : _card?.card_id?? 0,
                                                                  skill_code : '',
                                                                  skill_name: _card?.card_name?? '',
                                                                  description: _card?.card_description?? '',
                                                                  required_position: _card?.card_use_pos?? '',
                                                                  target_position: _card?.card_target_pos?? ''
                                                                };
      });

      console.log("cards" + this.cards.length);
    },
    GetUltimateSkill(className) {
      switch (className) {
        case "All-Rounder":
          this.ultimateSkill.skill_name = "Disarm";
          this.ultimateSkill.description = "Targets:</br> [DISARMED]x1";
          this.ultimateSkill.image_url = "http://guidebook.kryptofighters.io/wp-content/uploads/2023/02/imgonline-com-ua-resize-WOPE47S7mnAdkSeY.png";
          this.ultimateSkill.required_position = "oooo";
          this.ultimateSkill.target_position = "cccc";
          break;
        case "Bodyguard":
          this.ultimateSkill.skill_name = "Unbreakable Fortress";
          this.ultimateSkill.description = "DMG: - CRIT: - ACC: 200</br> [IMMORTAL]x3";
          this.ultimateSkill.image_url = "http://guidebook.kryptofighters.io/wp-content/uploads/2023/02/imgonline-com-ua-resize-1B4apvIbFRMpW5P.png";
          this.ultimateSkill.required_position = "xxoo";
          this.ultimateSkill.target_position = "oooo";
          break;
        case "Destroyer":
          this.ultimateSkill.skill_name = "Fist of God";
          this.ultimateSkill.description = "DMG: 50-100 CRIT: 10 ACC: 90";
          this.ultimateSkill.image_url = "http://guidebook.kryptofighters.io/wp-content/uploads/2023/02/DTY_ULT.png";
          this.ultimateSkill.required_position = "xxoo";
          this.ultimateSkill.target_position = "ooxx";
          break;
        case "Duelist":
          this.ultimateSkill.skill_name = "Gigantic Hurricane";
          this.ultimateSkill.description = "DMG: 5-10 CRIT: 0 ACC: 200</br>Target:</br> [SWAP]x4</br> [LOCKED]x3";
          this.ultimateSkill.image_url = "http://guidebook.kryptofighters.io/wp-content/uploads/2023/02/DUL_ULT.png";
          this.ultimateSkill.required_position = "oooo";
          this.ultimateSkill.target_position = "cccc";
          break;
        case "Acrobat":
          this.ultimateSkill.skill_name = "Dragon Claw";
          this.ultimateSkill.description = "DMG: 40-60 CRIT: 20 ACC: 95";
          this.ultimateSkill.image_url = "http://guidebook.kryptofighters.io/wp-content/uploads/2023/02/imgonline-com-ua-resize-5reA4w5OkHvmNskn.png";
          this.ultimateSkill.required_position = "ooxx";
          this.ultimateSkill.target_position = "ooxx";
          break;
        case "Stalker":
          this.ultimateSkill.skill_name = "Blockbuster bomb";
          this.ultimateSkill.description = "DMG: 20-25 CRIT: 200 ACC: 200";
          this.ultimateSkill.image_url = "http://guidebook.kryptofighters.io/wp-content/uploads/2023/02/STK_ULT.png";
          this.ultimateSkill.required_position = "oooo";
          this.ultimateSkill.target_position = "cccc";
          break;
        case "Brawler":
          this.ultimateSkill.skill_name = "Metal Fury";
          this.ultimateSkill.description = "DMG: - CRIT: - ACC: 200</br>Self:</br> [SHIELD]x3</br> [ATTACK_UP]x3</br> [CRIT]x3";
          this.ultimateSkill.image_url = "http://guidebook.kryptofighters.io/wp-content/uploads/2023/02/BRL_ULT.png";
          this.ultimateSkill.required_position = "oooo";
          this.ultimateSkill.target_position = "oooo";
          break;
        case "Trickster":
          this.ultimateSkill.skill_name = "Holy Rescue";
          this.ultimateSkill.description = "DMG: 0 CRIT: 0 ACC: 200</br> [HEAL]+999 HPCure";
          this.ultimateSkill.image_url = "http://guidebook.kryptofighters.io/wp-content/uploads/2023/02/imgonline-com-ua-resize-7fsd2UesKsdi.png";
          this.ultimateSkill.required_position = "oooo";
          this.ultimateSkill.target_position = "oooo";
          break;
      }
    },
    GetCardData(id){
      return useCardStore().getCardData(id);
    },
    GetCardDescription(id){
      var desc = this.GetCardData(id).card_description;
      //console.log("desc ", desc);
      return useSkillStore().getReplaceSkillDescription(desc);
    },
    UppercaseInBrackets(originalString) {
      return originalString.replace(/\[(.*?)\]/g, (match, p1) => {
        return `[${p1.toUpperCase()}]`;
      });
    },
    CalculateExtraStat(){
      var body_element = [
        this.fighter.properties.body_part_top_element, 
        this.fighter.properties.body_part_face_element,
        this.fighter.properties.body_part_body_element,
        this.fighter.properties.body_part_pant_element
      ];

      body_element.forEach(event => {
          if(event != null){
            var stats = this.GetExtraStat(event);
            if(stats != null){
              stats.forEach(s => {
                // console.log("stats " + stats.stat);
                switch(s.stat.toUpperCase()){
                  case 'ATTACK': this.currentAttack += s.magnitude; break;
                  case 'HP': this.currentHp += s.magnitude; break;
                  case 'SPEED': this.currentSpeed += s.magnitude; break;
                }
              });
            }
          }
      });
    },
    GetExtraStat(element){
      var result;
      switch(element){
        case 'FIRE': result = [
                                {stat: 'Attack', magnitude: 3},
                                {stat: 'HP', magnitude: 1}
                              ]; 
                      break;
        case 'WATER': result = [
                                {stat: 'HP', magnitude: 3},
                                {stat: 'Speed', magnitude: 1}
                              ]; 
                      break;
        case 'WIND': result = [
                                {stat: 'Speed', magnitude: 3},
                                {stat: 'Attack', magnitude: 1}
                              ]; 
                      break;
        case 'EARTH': result = [
                                {stat: 'HP', magnitude: 2},
                                {stat: 'Speed', magnitude: 1},
                                {stat: 'Attack', magnitude: 1}
                              ]; 
                      break;
        case 'ELECTRIC': result = [
                                {stat: 'Speed', magnitude: 2},
                                {stat: 'Attack', magnitude: 2}
                              ]; 
                      break;
      }
      return result;
    },
    async fetchFighterData() {
      try {
        const tokenId = this.$route.query.id;
        const response = await axios.get(`https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/fighter/${this.$route.query.id}.json`);
        const imageUrl = response.data.image;
        this.fighter = response.data;
        console.log('this.fighter:', response.data);
        const img = new Image();
        img.src = this.fighter.properties.fighter_sheet_url;
        img.onload = () => {
          this.isFighterLoading = false;
        };
        img.onerror = () => {
          this.isFighterLoading = true;
        };

        this.fighter.classImgUrl = getClassImageUrl(response.data.properties.class_id);
        this.fighter.elementUrl = getElementImageUrl(response.data.properties.element);
        const capitalizeFirstLetter = (string) =>((string).charAt(0)).toUpperCase() + string.slice(1).toLowerCase();
        this.fighter.element = (response.data.properties.element !== null) ? capitalizeFirstLetter(response.data.properties.element) : '-';
        this.fighter.sub_class_name = response.data.properties.sub_class_name;

        
        this.fighter.accessories = [
          {
            name: response.data.properties.accessory_name_id1,
            id: response.data.properties.accessory_id1
          },
          {
            name: response.data.properties.accessory_name_id2,
            id: response.data.properties.accessory_id2
          }
        ];

        this.fighter.bodyparts = [
          {
            id: response.data.properties.body_part_top_id,
            name: response.data.properties.body_part_top,
            element: response.data.properties.body_part_top_element,
            element_url: getElementImageUrl(response.data.properties.body_part_top_element),
            skill: {}
          },
          {
            id: response.data.properties.body_part_top_face_id,
            name: response.data.properties.body_part_top_face,
            element: response.data.properties.body_part_face_element,
            element_url: getElementImageUrl(response.data.properties.body_part_face_element),
            skill: {}
          },
          {
            id: response.data.properties.body_part_body_id,
            name: response.data.properties.body_part_body,
            element: response.data.properties.body_part_body_element,
            element_url: getElementImageUrl(response.data.properties.body_part_body_element),
            skill: {}
          },
          {
            id: response.data.properties.body_part_pant_id,
            name: response.data.properties.body_part_pant,
            element: response.data.properties.body_part_pant_element,
            element_url: getElementImageUrl(response.data.properties.body_part_pant_element),
            skill: {}
          }
        ];
        
        this.contract_addr = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;
        this.isHasSuperMove = true;
        this.isListable = true;

        if(this.fighter.properties.generation_id < 1){
          // this.fighter.properties.body_part_top_face_id = 0;
          // this.fighter.properties.body_part_top_id = 0;
          // this.fighter.properties.body_part_body_id = 0;
          // this.fighter.properties.body_part_pant_id = 0;
          this.isHasSuperMove = false;
          this.isListable = false;

          if(this.fighter.properties.generation_id == -1000){
            this.contract_addr = process.env.VITE_FIGHTER_GENX_CONTRACT_ADDRESS;
          }
        }

        this.currentHp = this.fighter.properties.status_health;
        this.currentAttack = this.fighter.properties.status_attack;
        this.currentSpeed = this.fighter.properties.status_speed;
        
        //this.GetCards([response.data.properties.body_part_top_face_id, this.fighter.bodyparts[1].name]);
        // this.body_part = [{id: this.fighter.properties.body_part_pant_id, name: this.fighter.properties.body_part_pant},
        //                 {id: this.fighter.properties.body_part_top_id, name: this.fighter.properties.body_part_top}];
        // this.cards = (response.data.properties.class_id === 2) ? [6] : null;

        this.skillPositionImage = "https://info.kryptofighters.io/images/position-" + response.data.properties.class_name + ".png";
        this.externalFighterClassUrl = "https://guidebook.kryptofighters.io/" + response.data.properties.class_name;
        this.GetRarityBgColor(response.data.properties.rarity_id);
        this.GetRarityBg(response.data.properties.rarity_id);
        this.GetUltimateSkill(response.data.properties.class_name);

        this.fighter.properties.skills.forEach(skill => {
          skill.description = useSkillStore().getReplaceSkillDescription(skill.description);
          //console.log("skill.description", skill);
        });

        this.fighter.properties.skills = this.fighter.properties.skills.slice(0, 3);

        //const _newSkill = [this.fighter.properties.skills[0]];
        if(this.fighter.properties.generation_id > 0){
          const _newSkill = [];
        
          const _firstSkill = useSkillStore().getDefaultSkillData(this.fighter.properties.class_id)[0];
          if((_firstSkill?.skill_code?? '') != ''){
            const _tempDefault = {
              skill_id : 0,
              skill_code : _firstSkill?.skill_code?? '',
              skill_name: _firstSkill?.skill_name?? '',
              description: useSkillStore().getReplaceSkillDescription(_firstSkill?.description?? ''),
              required_position: _firstSkill?.pos_use?? '',
              target_position: _firstSkill?.pos_target?? ''
            }

            _newSkill.push(_tempDefault);
          }
          // else{
          //   _newSkill.push(this.fighter.properties.skills[0]);
          // }

          const bodypartSkill = [this.fighter.bodyparts[1], this.fighter.bodyparts[3]];
          bodypartSkill.forEach((data, index) => {
            // console.log("part id"+index);
            // console.log("part name"+data.name);
            const _mapSkill = useSkillStore().getMapSkillData(data.id);
            if((_mapSkill?.skill_code?? '') != ''){
              const _temp = {
                skill_id : 0,
                skill_code : _mapSkill?.skill_code?? '',
                skill_name: _mapSkill?.skill_name?? '',
                description: useSkillStore().getReplaceSkillDescription(_mapSkill?.description?? ''),
                required_position: _mapSkill?.pos_use?? '',
                target_position: _mapSkill?.pos_target?? ''
              }

              _newSkill.push(_temp);

              this.fighter.bodyparts.find(f=>f.id == data.id).skill = _temp;
            }
            else{
              const _posSkill = useSkillStore().getDefaultSkillData(this.fighter.properties.class_id)[index+1];
              if((_posSkill?.skill_code?? '') != ''){
                const _posDefault = {
                  skill_id : 0,
                  skill_code : _posSkill?.skill_code?? '',
                  skill_name: _posSkill?.skill_name?? '',
                  description: useSkillStore().getReplaceSkillDescription(_posSkill?.description?? ''),
                  required_position: _posSkill?.pos_use?? '',
                  target_position: _posSkill?.pos_target?? ''
                }

                _newSkill.push(_posDefault);
                this.fighter.bodyparts.find(f=>f.id == data.id).skill = _posDefault;
              }
              // else{
              //   _newSkill.push(this.fighter.properties.skills[index+1]);
              //   this.fighter.bodyparts.find(f=>f.id == data.id).skill = _posDefault;
              // }
            }
          });

          this.fighter.properties.skills = _newSkill;

          this.CalculateExtraStat();
        }
        else{
          this.fighter.properties.skills = useSkillStore().getDefaultSkillData(this.fighter.properties.class_id);
        }

        this.GetCards();

        const provider = new ethers.providers.JsonRpcProvider(process.env.VITE_RPC_URL);
        const fighterContractAddress = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;
        const fighterContract = new ethers.Contract(fighterContractAddress, FighterERC721ABI, provider);

        const metadata = await fighterContract.getTokenMetadata(tokenId);
        console.log("Metadata:", metadata);
        this.fighter.properties.coaching_count  =  metadata.c_count;
        this.fighter.properties.season_id  =  metadata.sid;
        this.fighter.properties.m1id = metadata.m1id.toString();
        this.fighter.properties.m2id = metadata.m2id.toString();

        this.getMaster();
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    },
    async fetchNFTData() {
      const apiUrl = 'https://api.opensea.io/api/v2/chain/matic/contract/'+this.contract_addr+'/nfts/' + this.$route.query.id;
      const apiKey = process.env.VITE_OPENSEA_API_KEY;

      try {
        this.isOwner = false;
        // const response = await axios.get(apiUrl, {
        //   headers: {
        //     'Accept': 'application/json',
        //     'x-api-key': apiKey
        //   }
        // });

        const store = useStore();
        const accountAddress = store.getWalletAddress;
        const collection = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;
        const apiUrl = `https://api.rarible.org/v0.1/ownerships/byItem?itemId=KROMA:${collection}:${this.$route.query.id}`;
        const response = await axios.get(apiUrl, {
            headers: {
                'accept': 'application/json',
                'x-api-key': process.env.VITE_RARIBLE_API_KEY
            }
        });

        
        console.log("isOwner", response);

        this.fighter.owner_address = shortenedWalletAddress_rarible2(response.data.ownerships[0].owner);
        // console.log("isOwner" + accountAddress.toUpperCase());
        // console.log("isOwner" + shortenedWalletAddress_rarible(response.data.ownerships[0].owner).toUpperCase());
        if (accountAddress.toUpperCase().localeCompare(shortenedWalletAddress_rarible(response.data.ownerships[0].owner).toUpperCase(), undefined, { sensitivity: 'base' }) === 0) {
          this.isOwner = true;
          // console.log("isOwner" + this.isOwner);
        }

        // console.log("isOwner", this.isOwner);

        // console.log('Response data:', this.fighter.owner_address);
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async fetchEvent() {
      try {
        const collection = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;
        const apiKey = '25a60ca4f3004870b4094104969a2e9c';

        const apiUrl = `https://api.rarible.org/v0.1/items/KROMA:${collection}:${this.$route.query.id}`;
        const response = await axios.get(apiUrl, {
          headers: {
              'accept': 'application/json',
              'x-api-key': process.env.VITE_RARIBLE_API_KEY
          }
        });
        console.log("fetchEvent " , response);
        if(response.data.bestSellOrder){
          this.isItemListed = true;
          // this.currentPrice = parseFloat(response.data.bestSellOrder.makePrice);
          this.currentPriceCurrency = (response.data.bestSellOrder.take.type["@type"] !== 'ETH')? "JAB":"ETH";
          console.log(response.data.bestSellOrder.take.type["@type"]);
        }
        else{
          this.isItemListed = false;
        }

        const eventApiUrl = `https://api.rarible.org/v0.1/activities/byItem?type=&itemId=KROMA:${collection}:${this.$route.query.id}`;
        const eventResponse = await axios.get(eventApiUrl, {
          headers: {
              'accept': 'application/json',
              'x-api-key': process.env.VITE_RARIBLE_API_KEY
          }
        });
        console.log("eventResponse " , eventResponse);
        eventResponse.data.activities.sort((a, b) => {  
            const date1 = new Date(b.lastUpdatedAt);
            const date2 = new Date(a.lastUpdatedAt);
            return date1 - date2;
        });

        this.currentPrice = parseFloat(eventResponse.data.activities.find(f=>f["@type"] === "LIST")?.price?? 0);

        this.assetEvent = eventResponse.data.activities.filter(f => f['@type'] == 'SELL' || f['@type'] == 'TRANSFER').slice(0, 5).map(list => {
          const date = new Date(list.date);
          const unixTimestamp = Math.floor(date.getTime() / 1000);
          const type = (list['@type'] == 'SELL')? 'sale':list['@type'].toLowerCase();
          return {
            event_type: type,
            dateString: convertDateString(unixTimestamp),
            buyer: shortenedWalletAddress_rarible(list.buyer),
            payment: {symbol: 'JAB'},
            salePrice: list.price,
            from_address: shortenedWalletAddress_rarible(list.from),
            to_address: shortenedWalletAddress_rarible(list.owner)
          };
        });
        // console.log("assetEvent" , this.assetEvent);
        this.salesEvent = eventResponse.data.activities.filter(event => event['@type'] == 'SELL').slice(0, 5).map(list => {
          const date = new Date(list.date);
          const unixTimestamp = Math.floor(date.getTime() / 1000);
          
          return{
            buyer: shortenedWalletAddress_rarible(list.buyer),
            seller: shortenedWalletAddress_rarible(list.seller),
            payment: {symbol: 'JAB'},
            salePrice: list.price,
            dateString: convertDateString(unixTimestamp),
          }
        });

        // eventResponse.data.activities.sort((a, b) => {  
        //     const date1 = new Date(b.lastUpdatedAt);
        //     const date2 = new Date(a.lastUpdatedAt);
        //     return date1 - date2;
        // });
        // console.error('Error fetching image:', eventResponse);

        const transferIndex = eventResponse.data.activities.findIndex(item => item["@type"] === "LIST" || item["@type"] === "SELL" || item["@type"] === "TRANSFER");
        const filteredTransactions = transferIndex !== -1 ? eventResponse.data.activities.slice(0, transferIndex) : eventResponse.data.activities;
        // console.error('Error fetching image:', filteredTransactions);
        const cancelBidHashes = new Set(
          filteredTransactions
            .filter(tx => tx["@type"] === "CANCEL_BID")
            .map(tx => tx.hash)
        );

        const updatedTransactions = filteredTransactions.filter(tx => !cancelBidHashes.has(tx.hash));

        this.offerEvent = updatedTransactions
        .filter(event => event['@type'] == 'BID')
        .sort((a, b) => parseFloat(b.price) - parseFloat(a.price))
        .slice(0, 5).map(list => {          
          return{
            offerPrice: list.price,
            usdPrice: 0,
            maker: shortenedWalletAddress_rarible2(list.maker),
          }
        });

        // console.error(this.offerEvent);
      } catch (error) {
        console.error('Error fetching image:', error);
        // Handle error loading image if necessary
      }
    },
    async cancelListing(){
      try{
        this.isCancelLoading = true;
        var tokenAddress = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = await provider.getSigner();
        const apiKey = process.env.VITE_RARIBLE_API_KEY; 
        const raribleSdk = createRaribleSdk(signer, "prod", { apiKey });

        const item = await raribleSdk.apis.item.getItemById({ itemId: toItemId(`KROMA:${tokenAddress}:${this.$route.query.id}`) });

        const bodyRequest = {
          orderId: toOrderId(item.bestSellOrder.id),
        };

        console.log("bodyRequest:", bodyRequest);
        var order = await raribleSdk.order.cancel(bodyRequest);
        console.log("order:", order);
        var result = await order.wait()
        console.log("result:", result);

        useToast().open({
                        message: 'Successfully cancel.',
                        position: 'top-right',
                        type: 'success',
                    });
        this.isItemListed = false;
        this.isCancelLoading = false;
      }
      catch(error){
        console.error('Error cancelListing:', error);
        const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
        useToast().open({
                        message: 'Error: ' + errorMessage,
                        position: 'top-right',
                        type: 'error',
                    });
        this.isCancelLoading = false;
      }
    },
    async coach(){
      this.$router.push('/dojo');
    },
    async acceptOffer(){
      try{
        this.isAcceptLoading = true;
        var tokenAddress = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = await provider.getSigner();
        const apiKey = process.env.VITE_RARIBLE_API_KEY; 
        const raribleSdk = createRaribleSdk(signer, "prod", { apiKey });

        const item = await raribleSdk.apis.item.getItemById({ itemId: toItemId(`KROMA:${tokenAddress}:${this.$route.query.id}`) });

        console.log("bodyRequest: item", item);
        const bodyRequest = {
          orderId: item.bestBidOrder.id,
          amount: 1,
        };

        console.log("bodyRequest: item", item.bestBidOrder.id);

        console.log("bodyRequest:", bodyRequest);
        var order = await raribleSdk.order.acceptBid(bodyRequest);
        console.log("order:", order);
        var result = await order.wait()
        console.log("result:", result);

        useToast().open({
                        message: 'Successfully accept offer.',
                        position: 'top-right',
                        type: 'success',
                    });

        this.isOwner = false;
        this.isItemListed = false;
        this.isAcceptLoading = false;
      }
      catch(error){
        console.error('Error acceptOffer:', error);
        const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
        useToast().open({
                        message: 'Error: ' + errorMessage,
                        position: 'top-right',
                        type: 'error',
                    });
        this.isAcceptLoading = false;
      }
    },
    async getMaster(){
      const collection = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;

      const response1 = await axios.get(`https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/fighter/${this.fighter.properties.m1id}.json`);
      this.master1 = response1.data;
      this.master1.classImgUrl = getClassImageUrl(response1.data.properties.class_id);
      const apiUrl1 = `https://api.rarible.org/v0.1/ownerships/byItem?itemId=KROMA:${collection}:${this.fighter.properties.m1id}`;
      const res1 = await axios.get(apiUrl1, {
          headers: {
              'accept': 'application/json',
              'x-api-key': process.env.VITE_RARIBLE_API_KEY
          }
      });

      // console.log("isOwner", response1);
      this.master1.owner_address = res1.data.ownerships[0].owner;
      console.log("this.master1", this.master1);
      const master1Element = document.getElementById('master1Id');
      if (master1Element) {
        master1Element.style.top = this.getImageCropSize(this.master1.properties.class_id).top; 
        master1Element.style.left = this.getImageCropSize(this.master1.properties.class_id).left;
      }

      const response2 = await axios.get(`https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/fighter/${this.fighter.properties.m2id}.json`);
      this.master2 = response2.data;
      this.master2.classImgUrl = getClassImageUrl(response2.data.properties.class_id);
      const apiUrl2 = `https://api.rarible.org/v0.1/ownerships/byItem?itemId=KROMA:${collection}:${this.fighter.properties.m1id}`;
      const res2 = await axios.get(apiUrl2, {
          headers: {
              'accept': 'application/json',
              'x-api-key': process.env.VITE_RARIBLE_API_KEY
          }
      });

      // console.log("isOwner", response2);
      this.master2.owner_address = res2.data.ownerships[0].owner;
      console.log("this.master2", this.master2);
      const master2Element = document.getElementById('master2Id');
      if (master2Element) {
        master2Element.style.top = this.getImageCropSize(this.master2.properties.class_id).top; 
        master2Element.style.left = this.getImageCropSize(this.master2.properties.class_id).left;
      }
    },
    compareWallet(wallet){
      if (useStore().getWalletAddress.localeCompare(shortenedWalletAddress_rarible(wallet).toUpperCase(), undefined, { sensitivity: 'base' }) === 0) {
        return true;
      }
      else{
        return false;
      }
    },
    getImageCropSize(classId){
      switch(classId){
        case 1: // ard
        case 4: return { top: '-60px', left: '-60px'}; break; // stk
        case 2: return { top: '-35px', left: '-60px'}; break; // bdg
        case 7: return { top: '-45px', left: '-70px'}; break; // dty
        default: return { top: '-70px', left: '-60px'}; break;
      }
    },
    // async fetchEvent() {
    //   try {
    //     const apiUrl = 'https://api.opensea.io/api/v2/events/chain/matic/contract/'+this.contract_addr+'/nfts/' + this.$route.query.id + '?event_type=all';
    //     const apiKey = '25a60ca4f3004870b4094104969a2e9c';

    //     const response = await axios.get(apiUrl, {
    //       headers: {
    //         'Accept': 'application/json',
    //         'x-api-key': apiKey
    //       }
    //     });
    //     this.assetEvent = response.data.asset_events.slice(0, 5);
    //     console.log("assetEvent" , response.data.asset_events);
    //     this.salesEvent = response.data.asset_events.filter(event => event.event_type === "sale").slice(0, 5);
    //     this.salesEvent.forEach(event => {
    //       event.salePrice = parseFloat(event.payment.quantity) / Math.pow(10, event.payment.decimals);
    //       event.currency = event.payment.symbol;
    //       event.dateString = timeAgo(event.closing_date);
    //     });

    //     if (response.data.asset_events.length > 0) {
    //       const firstEvent = response.data.asset_events[0];
    //       if (firstEvent.event_type === "order" && firstEvent.payment && firstEvent.order_type === "listing") {
    //         this.currentPrice = parseFloat(firstEvent.payment.quantity) / Math.pow(10, firstEvent.payment.decimals);
    //         this.currentPriceCurrency = firstEvent.payment.symbol;
    //         this.isItemListed = true;

    //         const exchangeStore = useExchangeStore();
    //         exchangeStore.fetchExchangeRates();
    //         const ethToUsdExchangeRate = exchangeStore.getEthToUsdExchangeRate;
    //         const maticToUsdExchangeRate = exchangeStore.getMaticToUsdExchangeRate;
    //         this.currentPriceCurrencyUsd = convertToUSD(this.currentPrice, this.currentPriceCurrency, ethToUsdExchangeRate, maticToUsdExchangeRate);

    //       } else {
    //         this.isItemListed = false;
    //       }
    //     }


    //     // console.log(firstEvent);
    //   } catch (error) {
    //     console.error('Error fetching image:', error);
    //     // Handle error loading image if necessary
    //   }
    // },
    purchaseSuccess(flag){
      console.log("purchaseSuccess"+flag);
      this.isOwner = true;
      this.fetchEvent();
    }
  }
};
</script>

<style scoped>
.sprite-animation {
  width: 190px;
  /* Set the width according to your sprite sheet */
  height: 190px;
}

.badge-2 {
  color: #ebeef0;
  background-color: #198754;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
  justify-content: space-between;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.container {
  display: flex;
  align-items: left;
  padding-left: 0px;
  width: 100% !important;
  max-width: 1200px;
  margin-left: 0;
  margin-right: auto;
}

.left-container {
  max-width: 800px;
  min-width: 400px;
  min-height: 360px;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-content {
  max-width: 800px;
  width: 100%;
  display: flex;
  padding-right: 20px;
  justify-content: center;
  align-items: center;
}

.right-container {
  min-width: 360px;
  max-width: 400px;
  margin-left: 40px;
}

.col-md-3 {
  min-width: 160px;
}

.card-image {
  width: auto; 
  height: 350px;
  /* padding-top: 4px; */
}

.pos-image {
  width: 300px; 
  height: auto;
}

.image {
  width: 100%;
  height: auto;
  max-width: 800px;
}

.custom-dropdown-more-menu::after {
  /* Hide the dropdown triangle icon */
  border: none !important;
  display: none;
}

.accessories-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow-x: auto;
}

.accessory {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
}


.accessory-image {
  width: 100px;
  height: 100px;
  border-radius: 6px; /* Makes the image round */
  background-color: var(--light-background-color); /* Background color for the rounded area */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

.accessory-image img {
  height:auto; 
  width: 80px; 
}


.accessory-title {
  text-align: center;
  font-size: 14px; /* Adjust font size as needed */
  margin-top: 4px; /* Adjust spacing as needed */
}

.text-rarity-3 {
    color: paleturquoise; /* Set the text color to red for classId 1 */
}
.text-rarity-4 {
    color: rgb(222, 176, 245); /* Set the text color to red for classId 1 */
}
.text-rarity-5 {
    color: rgb(247, 232, 64); /* Set the text color to red for classId 1 */
}

.body-element-frame {
  position: absolute; 
  height: 100%; 
  width: 100%; 
  border: 2px solid; 
  border-radius: 8px;
}

.element-color-earth {
  color: #E6801A;
}

.element-color-fire {
  color: #b33111;
}

.element-color-wind {
  color: #33E680;
}

.element-color-water {
  color: #4C99FF;
}

.element-color-electric {
  color: #8033FF;
}

.crop-container {
    margin: 5px 25px;
    width: 80px; /* Width of the cropped area */
    height: 80px; /* Height of the cropped area */
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    border: 0.5px solid darkslateblue;
}
.crop-container img {
    position: absolute;
    top: -50px; /* Adjust this to position the image within the cropped area */
    left: -50px; /* Adjust this to position the image within the cropped area */
    width: auto; /* Ensure the image scales properly */
    height: 800%; /* Ensure the image scales properly */
    background-color: #2c2a3d;
}

.loader {
    border: 6px solid grey; /* Light grey background */
    border-top: 6px solid white; /* Blue color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column; /* Stack left and right containers vertically */
    align-items: center; /* Center both containers horizontally */
  }

  .left-container,
  .right-container {
    min-width: 100%;
    width: 100%; /* Take full width on smaller screens */
    margin-left: 0; /* Remove the margin between left and right containers */
  }

  .skill-row .col-md-3 {
    width: 50%;
    min-width: 50%;
  }

  .card-body .row {
    flex-wrap: wrap;
  }

  .ultilities-row .col-md-3 {
    flex: 3;
    min-width: 20%;
  }

  .bootster-row .col-md-4 {
    max-width: 50%;
    width: 50%;
  }
  
  .bootster-row .card-image {
    height: 100%;
    width: auto;
  }

  .card-text-title {
    height: auto;
    width: 100%;
  }

  .pos-image {
    width: 100%;
  }

  .accessories-container {
    align-items: unset;
    overflow-x: unset;
    flex-wrap: wrap;
  }

  .accessory {
    width: 50%;
  }

  .trait-row .col-md-3 {
    width: 50%;
  }
}

</style>



