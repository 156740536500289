<template>
  <div
    id="offerPopup"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="offerPopup"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            id="exampleModalLabel"
            class="modal-title"
          >
            Make Offer
          </h5>
          <button
            ref="Close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          />
        </div>
        <form @submit.prevent="offerNFTPrice">
          <div class="modal-body">
            <div class="mb-3">
              <label
                for="maticSalePrice"
                class="form-label"
              >Price in JAB</label>
              <input
                id="maticSalePrice"
                v-model="maticSalePrice"
                type="number"
                class="form-control"
                placeholder="Enter price in Matic"
                required
              />
            </div>
            <div
              v-if="showPriceAlert"
              class="alert alert-danger mt-3"
            >
              Price cannot be less than 0.
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="isLoading"
              style="min-width: 120px;"
            >
              <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin" /></span>
              <span v-else>Offer</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from '@/stores/index.ts';
import { ethers } from 'ethers';
import { createRaribleSdk } from "@rarible/sdk";
import { useToast } from 'vue-toast-notification';
import { getContractAddressByItemId } from '@/utils/contractUtils.js';
import { toCurrencyId, toItemId, toOrderId ,toCollectionId} from "@rarible/types"

export default {
  props: {
    showModal: Boolean,
    product: {},
    type: "fighter",
  },
  data() {
    return {
      maticSalePrice: 0,
      isLoading: false,
      showPriceAlert: false,
    };
  },
  methods: {
    closeModal() {
      this.$refs.Close.click();
    },
    async offerNFTPrice() {
      try {
        const store = useStore();
        const accountAddress = store.getWalletAddress;

        if (this.maticSalePrice <= 0) {
          this.showPriceAlert = true;
          return;
        } else {
          this.showPriceAlert = false;
        }

        this.isLoading = true;

        const price = parseFloat(this.maticSalePrice);

        if(price > parseFloat(store.getWalletBalance.jab)){
          this.isLoading = false;
          useToast().open({
            message: 'Error: JAB is not enough',
            position: 'top-right',
            type: 'error',
          });
          this.closeModal();
          return;
        }

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = await provider.getSigner();
        const apiKey = process.env.VITE_RARIBLE_API_KEY; 
        const raribleSdk = createRaribleSdk(signer, "prod", { apiKey });

        // Define the NFT asset details
        let tokenAddress = process.env.VITE_FIGHTER_CONTRACT_ADDRESS;
        let tokenId = 0;

        if (this.type === 'item') {
          tokenAddress = getContractAddressByItemId(parseInt(this.product.itemId));
          if(this.product.token_id >= 20000){
            tokenAddress = process.env.VITE_ARTIFACT_CONTRACT_ADDRESS;
          }
          else if(this.product.token_id >= 10000){
            tokenAddress = process.env.VITE_STONE_CONTRACT_ADDRESS;
          }
          tokenId = this.product.token_id;
        } else {
          tokenId = this.product.properties.token_id;
        }

        const request = {
          itemId: toItemId(`KROMA:${tokenAddress}:${tokenId}`),
          amount: 1, // amount of NFTs to sell
          price: price, // Use the price from the input
          currency: toCurrencyId(`KROMA:${process.env.VITE_JAB_CONTRACT_ADDRESS}`), // Ensure the environment variable is correct
        };

        console.log("request:", request);

        const orderId = await raribleSdk.order.bid(request);

        console.log("Listing successful:", orderId);

        this.isLoading = false;
        useToast().open({
          message: 'Success offer.',
          position: 'top-right',
          type: 'success',
        });
        if(this.type === 'fighter'){
          this.$emit('fetchEvent');
        }
        else{
          this.$emit('fetchEvent', tokenAddress);
        }
        this.closeModal();
      } catch (error) {
        console.error('Error listing NFT on Rarible:', error);
        this.isLoading = false;
        useToast().open({
          message: 'Error: ' + error.message,
          position: 'top-right',
          type: 'error',
        });
        this.closeModal();
      }
    },
  }
};
</script>
