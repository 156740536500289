import { db } from '@/firebase'; // Adjust the import according to your Firebase setup
import { ref as dbRef, set } from 'firebase/database';

export function maskWalletAddress(walletAddress) {
    if (walletAddress.length < 10) {
        return walletAddress; // Return as is if the address is too short
    }
    return walletAddress.slice(0, 6) + '...' + walletAddress.slice(-4); // Masking the address
}

export function sendMessage(wallet, msg) {
    return new Promise((resolve, reject) => {
        if (msg.trim() !== '') {
            // Reference to your messages node in the database
            const messagesRef = dbRef(db, 'messages'); // Change 'messages' to your actual messages path

            // Create a new message entry in the database
            set(messagesRef, {
                [Date.now()]: maskWalletAddress(wallet) + " " + msg // Use current timestamp as key
            })
            .then(() => {
                console.log(`Message sent: ${msg}`);
                resolve(msg); // Resolve the promise with the message
            })
            .catch((error) => {
                console.error('Error sending message:', error);
                reject(error); // Reject the promise with the error
            });
        } else {
            console.error('Message cannot be empty!');
            reject(new Error('Message cannot be empty!'));
        }
    });
}

export function shortenedWalletAddress(walletAddress) {
    if (walletAddress) {
      return `${walletAddress.slice(0, 6)}...${walletAddress.slice(-3)}`;
    }
    return '';
  }

export function shortenedWalletAddress_rarible(walletAddress) {
    if (walletAddress) {
      return `${walletAddress.slice(9)}`;
    }
    return '';
  }

  export function shortenedWalletAddress_rarible2(walletAddress) {
    if (walletAddress) {
      return `${walletAddress.slice(9, 15)}...${walletAddress.slice(-3)}`;
    }
    return '';
  }