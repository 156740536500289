<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li
        v-for="(crumb, index) in breadcrumbs"
        :key="index"
        :class="{ 'breadcrumb-item': true, 'last-item': index === breadcrumbs.length - 1 }"
      >
        <router-link
          :to="crumb.link"
          class="breadcrumb-link"
        >
          {{ crumb.title }}
        </router-link>
      </li>
    </ol>
  </nav>
</template>

<script>
import { useRoute } from 'vue-router';
import { computed, watch } from 'vue';

export default {
  setup() {
    const route = useRoute();

    // Define breadcrumbs computed property
    const breadcrumbs = computed(() => {
      if (route!==undefined && route.meta && route.meta.breadcrumbs) {
        return route.meta.breadcrumbs;
      } else {
        console.error('Error: Unable to retrieve breadcrumbs from route meta.');
        return [];
      }
    });

    // Watch for route changes and update breadcrumbs
    watch(route, () => {
      console.log('Route changed');
    });

    return {
      breadcrumbs
    };
  },
};
</script>

<style scoped>
.breadcrumb-link {
  text-decoration: none;
  color: var(--secondary-text-color);
}

.breadcrumb-separator {
  color: #333;
}

.last-item .breadcrumb-link {
  color: #ffffff;
  font-weight: bold;
}

/* Hide breadcrumbs on screens with a maximum width of 768px (adjust as needed) */
@media (max-width: 768px) {
  .breadcrumb-item:not(.last-item) {
    display: none;
  }
}
</style>
