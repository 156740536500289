<template>
  <div
    :id="modalId"
    class="modal fade"
    tabindex="-1"
    :aria-labelledby="'label' + modalId"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div
        class="modal-content"
        style="background-color: rgba(23, 21, 39, 1.0);"
      >
        <div
          class="row"
          style="height: 320px; margin: 0; "
        >
          <div
            class="col-5"
            style="margin-bottom: 1rem;margin-top: 1rem;"
          >
            <div
              class="card product-card-container"
              style="height: 100%;"
            >
              <div
                class="card-body"
                style="display: flex; height: inherit; align-items: center; justify-content: center;"
              >
                <img
                  :src="product.image"
                  alt="Product Image"
                  class="product-image"
                >
              </div>
            </div>
          </div>
                    
          <div class="col-7">
            <div
              class="row justify-content-end"
              style="margin-bottom: 0.5rem; margin-top: 1rem; margin-right: 0.2rem;"
            />

                        
            <div class="row">
              <div class="col-6 pl-0">
                <!-- Adjust the column size as needed -->
                <h4 class="start-text">
                  #{{ product.id }} {{ product.type }}
                </h4>
              </div>


              <div class="col-6 text-end">
                <!-- Adjust the column size as needed -->
                <button
                  ref="Close"
                  type="button"
                  class="btn-close circular-button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  @click="closeModal"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6 pl-0">
                <h6
                  class="secondary-text"
                  style="text-align: left;"
                >
                  Quantity: {{ product.amount }}
                </h6>
              </div>
            </div>
            <div class="row">
              <div class="col-12 pl-0">
                <div style="text-align: left; font-size: 14px; margin-top: 10px;">
                  {{ product.description }}
                </div>
              </div>
            </div>
          </div>

          <div class="position-absolute bottom-0 start-0 end-0 mb-3">
            <div
              class="d-flex justify-content-end"
              style="margin-right: 10px; margin-bottom: 10px;"
            >
              <button
                v-if="isUseButtonVisible"
                type="submit"
                class="btn btn-yellow me-5"
                :disabled="isLoading"
                style="width: 100px;"
                @click="useItemClick"
              >
                <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin" /></span>
                <span v-else>Open</span>
              </button>
              <button
                type="button"
                class="btn btn-secondary "
                :disabled="true"
                @click="handleLeftButtonClick"
              >
                Transfer
              </button>
            </div>
          </div>
        </div>       
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import Web3 from 'web3';
import { useStore } from '@/stores/index.ts';
import { ethers } from 'ethers';
import { OpenSeaSDK, Chain, Seaport } from "opensea-js";
import { ref } from 'vue';
import LootboxABI from '@/contracts/LootboxABI.json';

export default {
    components: {
    },
    props: {
        modalId: String,
        showModal: Boolean,
        product: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            showErrorAlert: false,
        };
    },
    computed: {
        isUseButtonVisible() {
            return this.product.id > 2;
        },
    },
    methods: {
        closeModal() {
            console.log('Closing modal'); // Add this line
            this.$refs.Close.click();
        },
        useItemClick() {
            console.log('useItemClick'); // Add this line
            if(this.product.id==3){
                this.openLootBox();
            }
        },
        async openLootBox(){
            try {
            
                this.isLoading = true;

                const contractAddress =  process.env.VITE_LOOTBOX_CONTRACT_ADDRESS; 
                
                const provider = new ethers.BrowserProvider(window.ethereum);
                const signer = await provider.getSigner();
                const contract = new ethers.Contract(contractAddress, LootboxABI, signer);

                const tx = await contract.open();
                await tx.wait();

                console.log(tx);
                const receipt = await provider.getTransactionReceipt(tx.hash);

                if (receipt && receipt.logs) {
                    // Filter logs for the "LootBoxOpened" event
                    const lootBoxOpenedLogs = receipt.logs.filter(log => log.topics[0] === contract.interface.getEventTopic("LootBoxOpened"));
                    
                    // Parse and access event data
                    lootBoxOpenedLogs.forEach(log => {
                        const eventData = contract.interface.parseLog(log);
                        console.log("LootBoxOpened:", eventData.args.user, eventData.args.rewards.toString());
                    });
                } else {
                    console.log("No logs found in the transaction.");
                }

                this.closeModal();
                this.isLoading = false;
            } catch (error) {
                console.error("Error:", error);
                this.showErrorAlert = true;
                this.isLoading = false;
            }
        }
    }
};
</script>

<style scoped>

.modal-dialog {
    max-width: 650px;
    max-height: 500px;
}

.modal-content {
    border: 0px;
}
.modal-body {
    padding: 0.5rem;
}

.heading-text {
    color: white;
}

.sub-topic-text {
    color: white;
}

.row {
    margin: 0;
    padding: 0;
}

.term-detail {
    padding: 1rem 0.5rem 1rem 0.5rem;
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    color: lightgray;
}
.circular-button {
    width: 15px;
    height: 15px;
    border-radius: 50%; 
    padding: 5px; 
    background-color: rgba(194, 28, 28, 0.2);
    text-align: center;
    font-size: 12px; 
}

.id-padding{
    width: 15px;
    height: 15px;
    border-radius: 20%; 
    padding: 4px; 
    background-color: rgba(190, 190, 190, 0.1);
    text-align: center;
    font-size: 12px;
    margin-left: 0.6rem;
}

a {
    text-decoration: none;
    color: white;
    font-weight: 500;
}

.create-account {
    color: cornflowerblue;
    font-weight: bold;
}
.left-content {
    display: flex;
    align-items: center;
}
.product-image {
    max-width: 100%;
    height: auto;
    width: 160px;
    object-fit: cover;

}
.start-text{
    text-align: left
}

.details-text{
    color: rgb(168, 168, 168);
}

.tag-context{
    background-color: brown;
    width: 200px;
    width: 380px;
}

.secondary-text {
    margin-top: 0px;
    font-size: 14px;
    color: var(--secondary-text-color);
}
</style>
  
