<template>
  <div class="page-content content-wrap">

    <div class="main-content">
      <div class="d-flex align-items-left">
        <img
          src="../assets/icon-dojo.png"
          alt="NFT Icon"
          class="header-icon mr-3"
          style="width:50px;"
        >
        <h1>Grand Dojo (Simulation)</h1>
      </div>
      <div
        class="no-space"
        style="display: flex; flex-direction: column;"
      >
        <form @submit.prevent="Coach">
          <div
            class="row no-space material-container"
            style="height: auto; width: 100%;"
          >
            <div
              v-if="token_master1 || token_master2 || token_master3 || token_master4"
              class="col-1 no-space info-text generation-box"
            >
              Gen 1
            </div>
            <div
              class="col no-space pair-master-box"
              style="display: flex; gap: 30px; width: 100%;"
            >
              <div class="col no-space pair-master-box">
                <div class="material-box no-space">
                  <div
                    class="info-text"
                    style="margin-bottom: 10px; color: white; font-size: 18px;"
                  >
                    Master 1
                  </div>
                  <div class="token-input-container">
                    <div style="width: auto; white-space: nowrap;">
                      Token ID
                    </div>
                    <form class="input-form">
                      <div
                        class=""
                        style="flex: 5 1;"
                      >
                        <div
                          class=""
                          style="box-sizing: border-box;"
                        >
                          <div
                            class=""
                            style="position: relative;"
                          >
                            <input
                              id="token_master1"
                              v-model="token_master1"
                              type="text"
                              class="input-field" 
                              placeholder="Enter ID"
                              required
                              @input="GenereateMasterImage(1)"
                            >
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="material-use material-use2">
                    <img
                      v-if="isShow_img_master1"
                      id="image_master1"
                      class="material-icon"
                      alt="master 1 image"
                    >
                    <div v-else>
                      <span v-if="isLoading_img_master1"><i class="fa fa-circle-o-notch fa-spin" /></span>
                    </div>
                    <div
                      v-if="master1 && Object.keys(master1).length > 0"
                      class="info-container"
                    >
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Class
                          </p>
                          <div style="display: flex;">
                            <img
                              v-if="master1?.properties?.class_name?? '' !== ''" 
                              :src="GetClassImage(master1.properties.class_id)"
                              alt="Class Icon" 
                              class="info-img-icon"
                              style="margin-right: 6px;"
                            >
                            <h6 class="no-space">
                              {{ master1?.properties?.class_name?? '' }}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Element
                          </p>
                          <div style="display: flex;">
                            <img
                              v-if="master1?.properties?.element?? '' !== ''" 
                              :src="GetElementImage(master1.properties.element)"
                              alt="Element Icon" 
                              class="info-img-icon"
                              style="margin-right: 6px;"
                            >
                            <h6 class="no-space">
                              {{ master1?.properties?.element?? '' }}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Spirit Socket
                          </p>
                          <div
                            class="info-img-display"
                            style="display: flex;"
                          >
                            <img
                              v-if="(master1?.properties?.spirit_socket_1?? 0 > 0) && (master1?.properties?.spirit_socket_1?? 0 < 6)" 
                              :src="`/images/runes/shape-${master1.properties.spirit_socket_1}.png`"
                              alt="Spirit socket 1 Icon"
                              class="info-img-icon"
                            >
                            <img
                              v-if="(master1?.properties?.spirit_socket_2?? 0 > 0) && (master1?.properties?.spirit_socket_2?? 0 < 6)" 
                              :src="`/images/runes/shape-${master1.properties.spirit_socket_2}.png`"
                              alt="Spirit socket 2 Icon"
                              class="info-img-icon"
                            >
                          </div>
                        </div>
                      </div>
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Spirit Point
                          </p>
                          <div
                            v-if="(master1?.properties?.spirit_points?? '') !== ''"
                            class="info-img-display"
                            style="display: flex;"
                          >
                            <div
                              v-for="(value, key) in ParseSpiritPoint(master1.properties.spirit_points)"
                              :key="key"
                            >
                              <div v-if="key !== ''">
                                <img
                                  v-if="GetElementImage(key) !== ''"
                                  :src="GetElementImage(key)" 
                                  alt="Spirit Socket Icon"
                                  class="info-img-icon"
                                >
                                <span> {{ value }} </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Normal Skill
                          </p>
                          <div
                            v-if="skills_master1.length > 0"
                            class="info-img-display"
                            style="justify-content: flex-end;"
                          >
                            {{ skills_master1[0]?.skill_name?? '' }},<br>
                            {{ skills_master1[1]?.skill_name?? '' }},<br>
                            {{ skills_master1[2]?.skill_name?? '' }}
                            <!-- <div v-for="(x, i) in skills_master1">
                                                                <img v-if="x" :src="`/images/skills/${x.skill_code?? ''}.png`" 
                                                                alt="Super Move Icon" class="info-img-icon" 
                                                                onerror="this.onerror=null; this.src='/images/cards/card/0.png';"/>
                                                            </div> -->
                          </div>
                        </div>
                      </div>
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Super Move
                          </p>
                          <div
                            v-if="cards_master1.length > 0"
                            class="info-img-display"
                            style="justify-content: flex-end;"
                          >
                            <div v-for="(x, i) in cards_master1">
                              <img
                                :src="`/images/cards/card/${x}.png`"
                                alt="Super Move Icon"
                                class="info-img-icon"
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="token_master1 || token_master2"
                  class="generation-box no-space"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#e8eaed"
                  ><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg>
                </div>
                <div class="material-box no-space">
                  <div
                    class="info-text"
                    style="margin-bottom: 10px; color: white; font-size: 18px;"
                  >
                    Master 2
                  </div>
                  <div class="token-input-container">
                    <div style="width: auto; white-space: nowrap;">
                      Token ID
                    </div>
                    <form class="input-form">
                      <div
                        class=""
                        style="flex: 5 1;"
                      >
                        <div
                          class=""
                          style="box-sizing: border-box;"
                        >
                          <div
                            class=""
                            style="position: relative;"
                          >
                            <input
                              id="token_master2"
                              v-model="token_master2"
                              type="text"
                              class="input-field" 
                              placeholder="Enter ID"
                              required
                              @input="GenereateMasterImage(2)"
                            >
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="material-use material-use2">
                    <img
                      v-if="isShow_img_master2"
                      id="image_master2"
                      class="material-icon"
                      alt="master 2 image"
                    >
                    <div v-else>
                      <span v-if="isLoading_img_master2"><i class="fa fa-circle-o-notch fa-spin" /></span>
                    </div>
                    <div
                      v-if="master2 && Object.keys(master2).length > 0"
                      class="info-container"
                    >
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Class
                          </p>
                          <div style="display: flex;">
                            <img
                              v-if="master2?.properties?.class_name?? '' !== ''" 
                              :src="GetClassImage(master2.properties.class_id)"
                              alt="Class Icon" 
                              class="info-img-icon"
                              style="margin-right: 6px;"
                            >
                            <h6 class="no-space">
                              {{ master2?.properties?.class_name?? '' }}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Element
                          </p>
                          <div style="display: flex;">
                            <img
                              v-if="master2?.properties?.element?? '' !== ''" 
                              :src="GetElementImage(master2.properties.element)"
                              alt="Element Icon" 
                              class="info-img-icon"
                              style="margin-right: 6px;"
                            >
                            <h6 class="no-space">
                              {{ master2?.properties?.element?? '' }}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Spirit Socket
                          </p>
                          <div
                            class="info-img-display"
                            style="display: flex;"
                          >
                            <img
                              v-if="(master2?.properties?.spirit_socket_1?? 0 > 0) && (master2?.properties?.spirit_socket_1?? 0 < 6)" 
                              :src="`/images/runes/shape-${master2.properties.spirit_socket_1}.png`"
                              alt="Spirit socket 1 Icon"
                              class="info-img-icon"
                            >
                            <img
                              v-if="(master2?.properties?.spirit_socket_2?? 0 > 0) && (master2?.properties?.spirit_socket_2?? 0 < 6)" 
                              :src="`/images/runes/shape-${master2.properties.spirit_socket_2}.png`"
                              alt="Spirit socket 2 Icon"
                              class="info-img-icon"
                            >
                          </div>
                        </div>
                      </div>
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Spirit Point
                          </p>
                          <div
                            v-if="(master2?.properties?.spirit_points?? '') !== ''"
                            class="info-img-display"
                            style="display: flex;"
                          >
                            <div
                              v-for="(value, key) in ParseSpiritPoint(master2.properties.spirit_points)"
                              :key="key"
                            >
                              <div v-if="key !== ''">
                                <img
                                  v-if="GetElementImage(key) !== ''"
                                  :src="GetElementImage(key)" 
                                  alt="Spirit Socket Icon"
                                  class="info-img-icon"
                                >
                                <span> {{ value }} </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Normal Skill
                          </p>
                          <div
                            v-if="skills_master2.length > 0"
                            class="info-img-display"
                            style="justify-content: flex-end;"
                          >
                            {{ skills_master2[0]?.skill_name?? '' }},<br>
                            {{ skills_master2[1]?.skill_name?? '' }},<br>
                            {{ skills_master2[2]?.skill_name?? '' }}
                            <!-- <div v-for="(x, i) in skills_master2">
                                                            <img v-if="x" :src="`/images/skills/${x.skill_code?? ''}.png`" 
                                                            alt="Super Move Icon" class="info-img-icon" 
                                                            onerror="this.onerror=null; this.src='/images/cards/card/0.png';"/>
                                                        </div> -->
                          </div>
                        </div>
                      </div>
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Super Move
                          </p>
                          <div
                            v-if="cards_master2.length > 0"
                            class="info-img-display"
                            style="justify-content: flex-end;"
                          >
                            <div v-for="(x, i) in cards_master2">
                              <img
                                :src="`/images/cards/card/${x}.png`"
                                alt="Super Move Icon"
                                class="info-img-icon"
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col no-space pair-master-box">
                <div class="material-box no-space">
                  <div
                    class="info-text"
                    style="margin-bottom: 10px; color: white; font-size: 18px;"
                  >
                    Master 3
                  </div>
                  <div class="token-input-container">
                    <div style="width: auto; white-space: nowrap;">
                      Token ID
                    </div>
                    <form class="input-form">
                      <div
                        class=""
                        style="flex: 5 1;"
                      >
                        <div
                          class=""
                          style="box-sizing: border-box;"
                        >
                          <div
                            class=""
                            style="position: relative;"
                          >
                            <input
                              id="token_master3"
                              v-model="token_master3"
                              type="text"
                              class="input-field" 
                              placeholder="Enter ID"
                              required
                              @input="GenereateMasterImage(3)"
                            >
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="material-use material-use2">
                    <img
                      v-if="isShow_img_master3"
                      id="image_master3"
                      class="material-icon"
                      alt="master 3 image"
                    >
                    <div v-else>
                      <span v-if="isLoading_img_master3"><i class="fa fa-circle-o-notch fa-spin" /></span>
                    </div>
                    <div
                      v-if="master3 && Object.keys(master3).length > 0"
                      class="info-container"
                    >
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Class
                          </p>
                          <div style="display: flex;">
                            <img
                              v-if="master3?.properties?.class_name?? '' !== ''" 
                              :src="GetClassImage(master3.properties.class_id)"
                              alt="Class Icon" 
                              class="info-img-icon"
                              style="margin-right: 6px;"
                            >
                            <h6 class="no-space">
                              {{ master3?.properties?.class_name?? '' }}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Element
                          </p>
                          <div style="display: flex;">
                            <img
                              v-if="master3?.properties?.element?? '' !== ''" 
                              :src="GetElementImage(master3.properties.element)"
                              alt="Element Icon" 
                              class="info-img-icon"
                              style="margin-right: 6px;"
                            >
                            <h6 class="no-space">
                              {{ master3?.properties?.element?? '' }}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Spirit Socket
                          </p>
                          <div
                            class="info-img-display"
                            style="display: flex;"
                          >
                            <img
                              v-if="(master3?.properties?.spirit_socket_1?? 0 > 0) && (master3?.properties?.spirit_socket_1?? 0 < 6)" 
                              :src="`/images/runes/shape-${master3.properties.spirit_socket_1}.png`"
                              alt="Spirit socket 1 Icon"
                              class="info-img-icon"
                            >
                            <img
                              v-if="(master3?.properties?.spirit_socket_2?? 0 > 0) && (master3?.properties?.spirit_socket_2?? 0 < 6)" 
                              :src="`/images/runes/shape-${master3.properties.spirit_socket_2}.png`"
                              alt="Spirit socket 2 Icon"
                              class="info-img-icon"
                            >
                          </div>
                        </div>
                      </div>
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Spirit Point
                          </p>
                          <div
                            v-if="(master3?.properties?.spirit_points?? '') !== ''"
                            class="info-img-display"
                            style="display: flex;"
                          >
                            <div
                              v-for="(value, key) in ParseSpiritPoint(master3.properties.spirit_points)"
                              :key="key"
                            >
                              <div v-if="key !== ''">
                                <img
                                  v-if="GetElementImage(key) !== ''"
                                  :src="GetElementImage(key)" 
                                  alt="Spirit Socket Icon"
                                  class="info-img-icon"
                                >
                                <span> {{ value }} </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Normal Skill
                          </p>
                          <div
                            v-if="skills_master3.length > 0"
                            class="info-img-display"
                            style="justify-content: flex-end;"
                          >
                            {{ skills_master3[0]?.skill_name?? '' }},<br>
                            {{ skills_master3[1]?.skill_name?? '' }},<br>
                            {{ skills_master3[2]?.skill_name?? '' }}
                            <!-- <div v-for="(x, i) in skills_master3">
                                                            <img v-if="x" :src="`/images/skills/${x.skill_code?? ''}.png`" 
                                                            alt="Super Move Icon" class="info-img-icon" 
                                                            onerror="this.onerror=null; this.src='/images/cards/card/0.png';"/>
                                                        </div> -->
                          </div>
                        </div>
                      </div>
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Super Move
                          </p>
                          <div
                            v-if="cards_master3.length > 0"
                            class="info-img-display"
                            style="justify-content: flex-end;"
                          >
                            <div v-for="(x, i) in cards_master3">
                              <img
                                :src="`/images/cards/card/${x}.png`"
                                alt="Super Move Icon"
                                class="info-img-icon"
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="token_master3 || token_master4"
                  class="generation-box no-space"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#e8eaed"
                  ><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg>
                </div>
                <div class="material-box no-space">
                  <div
                    class="info-text"
                    style="margin-bottom: 10px; color: white; font-size: 18px;"
                  >
                    Master 4
                  </div>
                  <div class="token-input-container">
                    <div style="width: auto; white-space: nowrap;">
                      Token ID
                    </div>
                    <form class="input-form">
                      <div
                        class=""
                        style="flex: 5 1;"
                      >
                        <div
                          class=""
                          style="box-sizing: border-box;"
                        >
                          <div
                            class=""
                            style="position: relative;"
                          >
                            <input
                              id="token_master4"
                              v-model="token_master4"
                              type="text"
                              class="input-field" 
                              placeholder="Enter ID"
                              required
                              @input="GenereateMasterImage(4)"
                            >
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="material-use material-use2">
                    <img
                      v-if="isShow_img_master4"
                      id="image_master4"
                      class="material-icon"
                      alt="master 4 image"
                    >
                    <div v-else>
                      <span v-if="isLoading_img_master4"><i class="fa fa-circle-o-notch fa-spin" /></span>
                    </div>
                    <div
                      v-if="master4 && Object.keys(master4).length > 0"
                      class="info-container"
                    >
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Class
                          </p>
                          <div style="display: flex;">
                            <img
                              v-if="master4?.properties?.class_name?? '' !== ''" 
                              :src="GetClassImage(master4.properties.class_id)"
                              alt="Class Icon" 
                              class="info-img-icon"
                              style="margin-right: 6px;"
                            >
                            <h6 class="no-space">
                              {{ master4?.properties?.class_name?? '' }}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Element
                          </p>
                          <div style="display: flex;">
                            <img
                              v-if="master4?.properties?.element?? '' !== ''" 
                              :src="GetElementImage(master4.properties.element)"
                              alt="Element Icon" 
                              class="info-img-icon"
                              style="margin-right: 6px;"
                            >
                            <h6 class="no-space">
                              {{ master4?.properties?.element?? '' }}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Spirit Socket
                          </p>
                          <div
                            class="info-img-display"
                            style="display: flex;"
                          >
                            <img
                              v-if="(master4?.properties?.spirit_socket_1?? 0 > 0) && (master4?.properties?.spirit_socket_1?? 0 < 6)" 
                              :src="`/images/runes/shape-${master4.properties.spirit_socket_1}.png`"
                              alt="Spirit socket 1 Icon"
                              class="info-img-icon"
                            >
                            <img
                              v-if="(master4?.properties?.spirit_socket_2?? 0 > 0) && (master4?.properties?.spirit_socket_2?? 0 < 6)" 
                              :src="`/images/runes/shape-${master4.properties.spirit_socket_2}.png`"
                              alt="Spirit socket 2 Icon"
                              class="info-img-icon"
                            >
                          </div>
                        </div>
                      </div>
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Spirit Point
                          </p>
                          <div
                            v-if="(master4?.properties?.spirit_points?? '') !== ''"
                            class="info-img-display"
                            style="display: flex;"
                          >
                            <div
                              v-for="(value, key) in ParseSpiritPoint(master4.properties.spirit_points)"
                              :key="key"
                            >
                              <div v-if="key !== ''">
                                <img
                                  v-if="GetElementImage(key) !== ''"
                                  :src="GetElementImage(key)" 
                                  alt="Spirit Socket Icon"
                                  class="info-img-icon"
                                >
                                <span> {{ value }} </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Normal Skill
                          </p>
                          <div
                            v-if="skills_master4.length > 0"
                            class="info-img-display"
                            style="justify-content: flex-end;"
                          >
                            {{ skills_master4[0]?.skill_name?? '' }},<br>
                            {{ skills_master4[1]?.skill_name?? '' }},<br>
                            {{ skills_master4[2]?.skill_name?? '' }}
                            <!-- <div v-for="(x, i) in skills_master4">
                                                            <img v-if="x" :src="`/images/skills/${x.skill_code?? ''}.png`" 
                                                            alt="Super Move Icon" class="info-img-icon" 
                                                            onerror="this.onerror=null; this.src='/images/cards/card/0.png';"/>
                                                        </div> -->
                          </div>
                        </div>
                      </div>
                      <div class="info-box">
                        <div style="justify-content: center;">
                          <p class="stat-title">
                            Super Move
                          </p>
                          <div
                            v-if="cards_master4.length > 0"
                            class="info-img-display"
                            style="justify-content: flex-end;"
                          >
                            <div v-for="(x, i) in cards_master4">
                              <img
                                :src="`/images/cards/card/${x}.png`"
                                alt="Super Move Icon"
                                class="info-img-icon"
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row no-space material-container"
            style="height: auto; padding-left: 3%; order: 20;"
          >
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="isLoading"
              style="width: 120px;"
            >
              <span v-if="isLoading"><i class="fa fa-circle-o-notch fa-spin" /></span>
              <span v-else> Coach </span>
            </button>
          </div>
        </form>

        <div
          v-if="(trainee1 && Object.keys(trainee1).length > 0) || (trainee2 && Object.keys(trainee2).length > 0)"
          class="row no-space material-container trainee-box"
        >
          <div class="col-1 no-space info-text generation-box">
            Gen 2
          </div>
          <div
            class="col no-space pair-master-box"
            style="display: flex; height: auto; width: auto; justify-content: space-around;"
          >
            <div
              v-if="trainee1 && Object.keys(trainee1).length > 0"
              class="material-use material-use2"
              style="height: auto; width: auto;"
            >
              <canvas
                ref="canvas"
                :width="canvasWidth_trainee1"
                :height="canvasHeight_trainee1"
                style="display:none"
              />
              <img
                v-if="mergedImage_trainee1"
                :src="mergedImage_trainee1"
                alt="Merged Image"
                style="width: 100%; height: auto;"
              >
              <div class="info-container">
                <div class="info-box">
                  <div style="justify-content: center;">
                    <p class="stat-title">
                      Class
                    </p>
                    <div style="display: flex;">
                      <img
                        v-if="trainee1?.class?? '' !== ''" 
                        :src="GetClassImage(trainee1.class)"
                        alt="Class Icon" 
                        class="info-img-icon"
                        style="margin-right: 6px;"
                      >
                      <h6 class="no-space">
                        {{ GetClassName(trainee1?.class?? 0) }}
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="info-box">
                  <div style="justify-content: center;">
                    <p class="stat-title">
                      Element
                    </p>
                    <div style="display: flex;">
                      <img
                        v-if="trainee1?.element?? '' !== ''" 
                        :src="GetElementImage(trainee1.element)"
                        alt="Element Icon" 
                        class="info-img-icon"
                        style="margin-right: 6px;"
                      >
                      <h6 class="no-space">
                        {{ trainee1?.element?? '' }}
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="info-box">
                  <div style="justify-content: center;">
                    <p class="stat-title">
                      Spirit Socket
                    </p>
                    <div
                      class="info-img-display"
                      style="display: flex;"
                    >
                      <img
                        v-if="(trainee1?.spirit_socket1?? 0 > 0) && (trainee1?.spirit_socket1?? 0 < 6)" 
                        :src="`/images/runes/shape-${trainee1.spirit_socket1}.png`"
                        alt="Spirit socket 1 Icon"
                        class="info-img-icon"
                      >
                      <img
                        v-if="(trainee1?.spirit_socket2?? 0 > 0) && (trainee1?.spirit_socket2?? 0 < 6)" 
                        :src="`/images/runes/shape-${trainee1.spirit_socket2}.png`"
                        alt="Spirit socket 2 Icon"
                        class="info-img-icon"
                      >
                    </div>
                  </div>
                </div>
                <div class="info-box">
                  <div style="justify-content: center;">
                    <p class="stat-title">
                      Spirit Point
                    </p>
                    <div
                      v-if="(trainee1?.spirit_points?? '') !== ''"
                      class="info-img-display"
                      style="display: flex;"
                    >
                      <div
                        v-for="(value, key) in trainee1.spirit_points"
                        :key="key"
                      >
                        <div v-if="key !== ''">
                          <img
                            v-if="GetElementImage(key) !== ''"
                            :src="GetElementImage(key)" 
                            alt="Spirit Socket Icon"
                            class="info-img-icon"
                          >
                          <span> {{ value }} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="info-box">
                  <div style="justify-content: center;">
                    <p class="stat-title">
                      Normal Skill
                    </p>
                    <div
                      v-if="skills_trainee1.length > 0"
                      class="info-img-display"
                      style="justify-content: flex-end;"
                    >
                      {{ skills_trainee1[0]?.skill_name?? '' }},<br>
                      {{ skills_trainee1[1]?.skill_name?? '' }},<br>
                      {{ skills_trainee1[2]?.skill_name?? '' }}
                      <!-- <div v-for="(x, i) in skills_trainee1">
                                                <img v-if="x" :src="`/images/skills/${x.skill_code?? ''}.png`" 
                                                alt="Super Move Icon" class="info-img-icon" 
                                                onerror="this.onerror=null; this.src='/images/cards/card/0.png';"/>
                                            </div> -->
                    </div>
                  </div>
                </div>
                <div class="info-box">
                  <div style="justify-content: center;">
                    <p class="stat-title">
                      Super Move
                    </p>
                    <div
                      v-if="cards_trainee1.length > 0"
                      class="info-img-display"
                      style="justify-content: flex-end;"
                    >
                      <div v-for="(x, i) in cards_trainee1">
                        <img
                          :src="`/images/cards/card/${x}.png`"
                          alt="Super Move Icon"
                          class="info-img-icon"
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="(trainee1 && Object.keys(trainee1).length > 0) || (trainee2 && Object.keys(trainee2).length > 0)"
              class="generation-box no-space"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#e8eaed"
              ><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" /></svg>
            </div>
            <div
              v-if="trainee2 && Object.keys(trainee2).length > 0"
              class="material-use material-use2"
              style="height: auto; width: auto;"
            >
              <canvas
                ref="canvas"
                :width="canvasWidth_trainee2"
                :height="canvasHeight_trainee2"
                style="display:none"
              />
              <img
                v-if="mergedImage_trainee2"
                :src="mergedImage_trainee2"
                alt="Merged Image"
                style="width: 100%; height: auto;"
              >
              <div class="info-container">
                <div class="info-box">
                  <div style="justify-content: center;">
                    <p class="stat-title">
                      Class
                    </p>
                    <div style="display: flex;">
                      <img
                        v-if="trainee2?.class?? '' !== ''" 
                        :src="GetClassImage(trainee2.class)"
                        alt="Class Icon" 
                        class="info-img-icon"
                        style="margin-right: 6px;"
                      >
                      <h6 class="no-space">
                        {{ GetClassName(trainee2?.class?? 0) }}
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="info-box">
                  <div style="justify-content: center;">
                    <p class="stat-title">
                      Element
                    </p>
                    <div style="display: flex;">
                      <img
                        v-if="trainee2?.element?? '' !== ''" 
                        :src="GetElementImage(trainee2.element)"
                        alt="Element Icon" 
                        class="info-img-icon"
                        style="margin-right: 6px;"
                      >
                      <h6 class="no-space">
                        {{ trainee2?.element?? '' }}
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="info-box">
                  <div style="justify-content: center;">
                    <p class="stat-title">
                      Spirit Socket
                    </p>
                    <div
                      class="info-img-display"
                      style="display: flex;"
                    >
                      <img
                        v-if="(trainee2?.spirit_socket1?? 0 > 0) && (trainee2?.spirit_socket1?? 0 < 6)" 
                        :src="`/images/runes/shape-${trainee2.spirit_socket1}.png`"
                        alt="Spirit socket 1 Icon"
                        class="info-img-icon"
                      >
                      <img
                        v-if="(trainee2?.spirit_socket2?? 0 > 0) && (trainee2?.spirit_socket2?? 0 < 6)" 
                        :src="`/images/runes/shape-${trainee2.spirit_socket2}.png`"
                        alt="Spirit socket 2 Icon"
                        class="info-img-icon"
                      >
                    </div>
                  </div>
                </div>
                <div class="info-box">
                  <div style="justify-content: center;">
                    <p class="stat-title">
                      Spirit Point
                    </p>
                    <div
                      v-if="(trainee2?.spirit_points?? '') !== ''"
                      class="info-img-display"
                      style="display: flex;"
                    >
                      <div
                        v-for="(value, key) in trainee2.spirit_points"
                        :key="key"
                      >
                        <div v-if="key !== ''">
                          <img
                            v-if="GetElementImage(key) !== ''"
                            :src="GetElementImage(key)" 
                            alt="Spirit Socket Icon"
                            class="info-img-icon"
                          >
                          <span> {{ value }} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="info-box">
                  <div style="justify-content: center;">
                    <p class="stat-title">
                      Normal Skill
                    </p>
                    <div
                      v-if="skills_trainee2.length > 0"
                      class="info-img-display"
                      style="justify-content: flex-end;"
                    >
                      {{ skills_trainee2[0]?.skill_name?? '' }},<br>
                      {{ skills_trainee2[1]?.skill_name?? '' }},<br>
                      {{ skills_trainee2[2]?.skill_name?? '' }}
                      <!-- <div v-for="(x, i) in skills_trainee2">
                                                <img v-if="x" :src="`/images/skills/${x.skill_code?? ''}.png`" 
                                                alt="Super Move Icon" class="info-img-icon" 
                                                onerror="this.onerror=null; this.src='/images/cards/card/0.png';"/>
                                            </div> -->
                    </div>
                  </div>
                </div>
                <div class="info-box">
                  <div style="justify-content: center;">
                    <p class="stat-title">
                      Super Move
                    </p>
                    <div
                      v-if="cards_trainee2.length > 0"
                      class="info-img-display"
                      style="justify-content: flex-end;"
                    >
                      <div v-for="(x, i) in cards_trainee2">
                        <img
                          :src="`/images/cards/card/${x}.png`"
                          alt="Super Move Icon"
                          class="info-img-icon"
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="grandTrainee && Object.keys(grandTrainee).length > 0"
          class="row no-space material-container trainee-box"
        >
          <div class="col-1 no-space info-text generation-box">
            Gen 3
          </div>
          <div
            class="col no-space pair-master-box"
            style="display: flex; height: auto; width: auto; justify-content: space-around;"
          >
            <div
              class="material-use material-use2"
              style="height: auto; width: auto;"
            >
              <canvas
                ref="canvas"
                :width="canvasWidth_grandTrainee"
                :height="canvasHeight_grandTrainee"
                style="display:none"
              />
              <img
                v-if="mergedImage_grandTrainee"
                :src="mergedImage_grandTrainee"
                alt="Merged Image"
                style="width: 100%; height: auto;"
              >
              <div class="info-container">
                <div class="info-box">
                  <div style="justify-content: center;">
                    <p class="stat-title">
                      Class
                    </p>
                    <div style="display: flex;">
                      <img
                        v-if="grandTrainee?.class?? '' !== ''" 
                        :src="GetClassImage(grandTrainee.class)"
                        alt="Class Icon" 
                        class="info-img-icon"
                        style="margin-right: 6px;"
                      >
                      <h6 class="no-space">
                        {{ GetClassName(grandTrainee?.class?? 0) }}
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="info-box">
                  <div style="justify-content: center;">
                    <p class="stat-title">
                      Element
                    </p>
                    <div style="display: flex;">
                      <img
                        v-if="grandTrainee?.element?? '' !== ''" 
                        :src="GetElementImage(grandTrainee.element)"
                        alt="Element Icon" 
                        class="info-img-icon"
                        style="margin-right: 6px;"
                      >
                      <h6 class="no-space">
                        {{ grandTrainee?.element?? '' }}
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="info-box">
                  <div style="justify-content: center;">
                    <p class="stat-title">
                      Spirit Socket
                    </p>
                    <div
                      class="info-img-display"
                      style="display: flex;"
                    >
                      <img
                        v-if="(grandTrainee?.spirit_socket1?? 0 > 0) && (grandTrainee?.spirit_socket1?? 0 < 6)" 
                        :src="`/images/runes/shape-${grandTrainee.spirit_socket1}.png`"
                        alt="Spirit socket 1 Icon"
                        class="info-img-icon"
                      >
                      <img
                        v-if="(grandTrainee?.spirit_socket2?? 0 > 0) && (grandTrainee?.spirit_socket2?? 0 < 6)" 
                        :src="`/images/runes/shape-${grandTrainee.spirit_socket2}.png`"
                        alt="Spirit socket 2 Icon"
                        class="info-img-icon"
                      >
                    </div>
                  </div>
                </div>
                <div class="info-box">
                  <div style="justify-content: center;">
                    <p class="stat-title">
                      Spirit Point
                    </p>
                    <div
                      v-if="(grandTrainee?.spirit_points?? '') !== ''"
                      class="info-img-display"
                      style="display: flex;"
                    >
                      <div
                        v-for="(value, key) in grandTrainee.spirit_points"
                        :key="key"
                      >
                        <div v-if="key !== ''">
                          <img
                            v-if="GetElementImage(key) !== ''"
                            :src="GetElementImage(key)" 
                            alt="Spirit Socket Icon"
                            class="info-img-icon"
                          >
                          <span> {{ value }} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="info-box">
                  <div style="justify-content: center;">
                    <p class="stat-title">
                      Normal Skill
                    </p>
                    <div
                      v-if="skills_grandTrainee.length > 0"
                      class="info-img-display"
                      style="justify-content: flex-end;"
                    >
                      {{ skills_grandTrainee[0]?.skill_name?? '' }},<br>
                      {{ skills_grandTrainee[1]?.skill_name?? '' }},<br>
                      {{ skills_grandTrainee[2]?.skill_name?? '' }}
                      <!-- <div v-for="(x, i) in skills_grandTrainee">
                                                <img v-if="x" :src="`/images/skills/${x.skill_code?? ''}.png`" 
                                                alt="Super Move Icon" class="info-img-icon" 
                                                onerror="this.onerror=null; this.src='/images/cards/card/0.png';"/>
                                            </div> -->
                    </div>
                  </div>
                </div>
                <div class="info-box">
                  <div style="justify-content: center;">
                    <p class="stat-title">
                      Super Move
                    </p>
                    <div
                      v-if="cards_grandTrainee.length > 0"
                      class="info-img-display"
                      style="justify-content: flex-end;"
                    >
                      <div v-for="(x, i) in cards_grandTrainee">
                        <img
                          :src="`/images/cards/card/${x}.png`"
                          alt="Super Move Icon"
                          class="info-img-icon"
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import Web3 from 'web3';
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import { getClassImageUrl ,getElementImageUrl } from '@/utils/imageUtils.js';
import { useCardStore } from '@/stores/card.ts';
import { useSkillStore } from '@/stores/skill.ts';
import {useToast} from 'vue-toast-notification';

export default {
    components: {
    }, data() {
        return {
            isLoading: false,
            master1: {},
            isLoading_img_master1: false,
            isShow_img_master1: false,
            token_master1: "",
            cards_master1: [],
            skills_master1: [],
            master2: {},
            isLoading_img_master2: false,
            isShow_img_master2: false,
            token_master2: "",
            cards_master2: [],
            skills_master2: [],
            master3: {},
            isLoading_img_master3: false,
            isShow_img_master3: false,
            token_master3: "",
            cards_master3: [],
            skills_master3: [],
            master4: {},
            isLoading_img_master4: false,
            isShow_img_master4: false,
            token_master4: "",
            cards_master4: [],
            skills_master4: [],
            trainee1: {},
            cards_trainee1: [],
            skills_trainee1: [],
            isShow_img_trainee1: false,
            isLoading_img_trainee1: false,
            mergedImage_trainee1: null,
            canvasWidth_trainee1: 0,
            canvasHeight_trainee1: 0,
            trainee2: {},
            cards_trainee2: [],
            skills_trainee2: [],
            isShow_img_trainee2: false,
            isLoading_img_trainee2: false,
            mergedImage_trainee2: null,
            canvasWidth_trainee2: 0,
            canvasHeight_trainee2: 0,
            grandTrainee: {},
            cards_grandTrainee: [],
            skills_grandTrainee: [],
            isShow_img_grandTrainee: false,
            isLoading_img_grandTrainee: false,
            mergedImage_grandTrainee: null,
            canvasWidth_grandTrainee: 0,
            canvasHeight_grandTrainee: 0,
        };
    },
    computed: {
    },
    async mounted() {
        try {
            await useCardStore().fetchCardData();
            //await useCardStore().fetchPartMapper();
            await useSkillStore().fetchMapSkillData();
        } catch (error){ 
            console.error('Error:', error);
        }
    },
    methods: {
        GetCards(selectedNo){
            var selectedFighter = {};
            switch(selectedNo){
                case -1: this.cards_trainee1 = [];
                        selectedFighter = this.trainee1;
                        break;
                case -2: this.cards_trainee2 = [];
                        selectedFighter = this.trainee2;
                        break;
                case -3: this.cards_grandTrainee = [];
                        selectedFighter = this.grandTrainee;
                        break;
                case 1: this.cards_master1 = [];
                        selectedFighter = this.master1;
                        break;
                case 2: this.cards_master2 = [];
                        selectedFighter = this.master2;
                        break;
                case 3: this.cards_master3 = [];
                        selectedFighter = this.master3;
                        break;
                case 4: this.cards_master4 = [];
                        selectedFighter = this.master4;
                        break;
            }

            var body_part = [];
            if(selectedNo > 0){
                body_part = [{id: selectedFighter.properties.body_part_top_id, name: selectedFighter.properties.body_part_top},
                            {id: selectedFighter.properties.body_part_body_id, name: selectedFighter.properties.body_part_body}];
            }
            else if(selectedNo < 0){
                body_part = [{id: selectedFighter.body_part_top_id, name: selectedFighter.body_part_top},
                            {id: selectedFighter.body_part_body_id, name: selectedFighter.body_part_body}];
            }

            body_part.forEach((data, index) => {
                // console.log("part idddddd"+data.id);
                // console.log("part nameeeee"+data.name);
                switch(selectedNo){
                    case -1: this.cards_trainee1.push(useCardStore().getCardIdFromPart(data.id));
                            break;
                    case -2: this.cards_trainee2.push(useCardStore().getCardIdFromPart(data.id));
                            break;
                    case -3: this.cards_grandTrainee.push(useCardStore().getCardIdFromPart(data.id));
                            break;
                    case 1: this.cards_master1.push(useCardStore().getCardIdFromPart(data.id));
                            break;
                    case 2: this.cards_master2.push(useCardStore().getCardIdFromPart(data.id));
                            break;
                    case 3: this.cards_master3.push(useCardStore().getCardIdFromPart(data.id));
                            break;
                    case 4: this.cards_master4.push(useCardStore().getCardIdFromPart(data.id));
                            break;
                }
            });

            // console.log("cards" + this.cards_master1.length);
        },
        GetSkills(selectedNo){
            var selectedFighter = {};
            switch(selectedNo){
                case -1: this.skills_trainee1 = [];
                        selectedFighter = this.trainee1;
                        break;
                case -2: this.skills_trainee2 = [];
                        selectedFighter = this.trainee2;
                        break;
                case -3: this.skills_grandTrainee = [];
                        selectedFighter = this.grandTrainee;
                        break;
                case 1: this.skills_master1 = [];
                        selectedFighter = this.master1;
                        break;
                case 2: this.skills_master2 = [];
                        selectedFighter = this.master2;
                        break;
                case 3: this.skills_master3 = [];
                        selectedFighter = this.master3;
                        break;
                case 4: this.skills_master4 = [];
                        selectedFighter = this.master4;
                        break;
            }

            const _newSkill = [];
        
            const _firstSkill = useSkillStore().getDefaultSkillData((selectedNo > 0)? selectedFighter.properties.class_id:selectedFighter.class);
            if((_firstSkill?.skill_code?? '') != ''){
                _newSkill.push(_firstSkill);
            }
            else{
                if(selectedNo > 0){
                    _newSkill.push(selectedFighter.properties.skills[0]);
                }
            }

            var body_part = [];
            if(selectedNo > 0){
                body_part = [{id: selectedFighter.properties.body_part_top_face_id, name: selectedFighter.properties.body_part_top_face},
                            {id: selectedFighter.properties.body_part_pant_id, name: selectedFighter.properties.body_part_pant}];
            }
            else{
                body_part = [{id: selectedFighter.body_part_face_id, name: selectedFighter.body_part_face},
                            {id: selectedFighter.body_part_pant_id, name: selectedFighter.body_part_pant}];
            }

            body_part.forEach((data, index) => {
                const _mapSkill = useSkillStore().getMapSkillData(data.id);
                if((_mapSkill?.skill_code?? '') != ''){
                    _newSkill.push(_mapSkill);
                }
                else{
                    if(selectedNo > 0){
                        _newSkill.push(selectedFighter.properties.skills[index+1]);
                    }
                }
            });

            // if(selectedNo > 0){
            //     var selectFighterSkill = selectedFighter.properties.skills;

            //     if(_newSkill.length < 3){
            //         const _diff = 3-_newSkill.length;
            //         for (let i=0; i<_diff; i++){
            //             _newSkill.push(selectFighterSkill[i]);
            //         }
            //     }
            // }

            switch(selectedNo){
                case -1: this.skills_trainee1 = _newSkill;
                        break;
                case -2: this.skills_trainee2 = _newSkill;
                        break;
                case -3: this.skills_grandTrainee = _newSkill;
                        break;
                case 1: this.skills_master1 = _newSkill;
                        break;
                case 2: this.skills_master2 = _newSkill;
                        break;
                case 3: this.skills_master3 = _newSkill;
                        break;
                case 4: this.skills_master4 = _newSkill;
                        break;
            }

            // body_part.forEach((data, index) => {
            //     // console.log("part idddddd"+data.id);
            //     // console.log("part nameeeee"+data.name);
            //     switch(selectedNo){
            //         case -1: this.skills_trainee1.push(useSkillStore().getMapSkillData(data.id));
            //                 break;
            //         case -2: this.skills_trainee2.push(useSkillStore().getMapSkillData(data.id));
            //                 break;
            //         case -3: this.skills_grandTrainee.push(useSkillStore().getMapSkillData(data.id));
            //                 break;
            //         case 1: this.skills_master1.push(useSkillStore().getMapSkillData(data.id));
            //                 break;
            //         case 2: this.skills_master2.push(useSkillStore().getMapSkillData(data.id));
            //                 break;
            //         case 3: this.skills_master3.push(useSkillStore().getMapSkillData(data.id));
            //                 break;
            //         case 4: this.skills_master4.push(useSkillStore().getMapSkillData(data.id));
            //                 break;
            //     }
            // });

            // console.log("cards" + this.cards_master1.length);
        },
        GetClassName(classId){
            var classList = ['AllRounder', 'Bodyguard', 'Duelist', 'Stalker', 'Acrobat', 'Trickster', 'Destroyer', 'Brawler'];
            return classList[classId-1];
        },
        GetClassImage(classId){
            return getClassImageUrl(classId);
        },
        GetElementImage(element){
            return getElementImageUrl(element);
        },
        ParseSpiritPoint(pointList){
            return JSON.parse(pointList);
        },
        ResetTrainee(){
            this.trainee1 = {};
            this.isShow_img_trainee1 = false;

            this.trainee2 = {};
            this.isShow_img_trainee2 = false;

            this.grandTrainee = {};
            this.isShow_img_grandTrainee = false;
        },
        async GenereateMasterImage(masterNo){
            var token;

            switch(masterNo){
                case 1: token = this.token_master1; 
                        this.isLoading_img_master1 = true;
                        break;
                case 2: token = this.token_master2; 
                        this.isLoading_img_master2 = true;
                        break;
                case 3: token = this.token_master3; 
                        this.isLoading_img_master3 = true;
                        break;
                case 4: token = this.token_master4; 
                        this.isLoading_img_master4 = true;
                        break;
            }

            this.ResetTrainee();

            if(token != ""){
                try {
                    switch(masterNo){
                        case 1: this.isShow_img_master1 = true;
                                break;
                        case 2: this.isShow_img_master2 = true;
                                break;
                        case 3: this.isShow_img_master3 = true;
                                break;
                        case 4: this.isShow_img_master4 = true;
                                break;
                    }
                    
                    const response = await axios.get(`https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/fighter/${parseInt(token)}.json`);

                    console.log('Response data:', response);

                    this.$nextTick(() => {
                        var imgId;
                        switch(masterNo){
                            case 1: this.master1 = response.data;
                                    imgId = 'image_master1';
                                    break;
                            case 2: this.master2 = response.data;
                                    imgId = 'image_master2';
                                    break;
                            case 3: this.master3 = response.data;
                                    imgId = 'image_master3';
                                    break;
                            case 4: this.master4 = response.data;
                                    imgId = 'image_master4';
                                    break;
                        }
                        this.GetCards(masterNo);
                        this.GetSkills(masterNo);

                        var imgElement = document.getElementById(imgId);
                        var imageUrl = response.data.image;
                        imageUrl = imageUrl.replace("image/4x/", "card/no-bg/");
                        imgElement.src = imageUrl;
                    });

                } catch (error) {
                    console.error('Error:', error);

                    useToast().open({
                        message: 'Fighter error : ' + error.message,
                        position: 'top-right',
                        type: 'error',
                    });
                }
            }
            else{
                // this.ResetTrainee();
                switch(masterNo){
                    case 1: this.master1 = {};
                            this.cards_master1 = [];
                            this.skills_master1 = [];
                            this.isShow_img_master1 = false;
                            break;
                    case 2: this.master2 = {};
                            this.cards_master2 = [];
                            this.skills_master2 = [];
                            this.isShow_img_master2 = false;
                            break;
                    case 3: this.master3 = {};
                            this.cards_master3 = [];
                            this.skills_master3 = [];
                            this.isShow_img_master3 = false;
                            break;
                    case 4: this.master4 = {};
                            this.cards_master4 = [];
                            this.skills_master4 = [];
                            this.isShow_img_master4 = false;
                            break;
                }
            }

            switch(masterNo){
                case 1: this.isLoading_img_master1 = false;
                        break;
                case 2: this.isLoading_img_master2 = false;
                        break;
                case 3: this.isLoading_img_master3 = false;
                        break;
                case 4: this.isLoading_img_master4 = false;
                        break;
            }
        },
        async Coach(){
            if(this.token_master1 != "" && this.token_master2 != "" && this.token_master3 != "" && this.token_master4 != ""){
                this.isLoading = true;
                this.isShow_img_trainee1 = false;
                this.isShow_img_trainee2 = false;
                this.isShow_img_grandTrainee = false;
                try {
                    const requestBody = {
                        master1_id: this.token_master1,
                        master2_id: this.token_master2,
                        master3_id: this.token_master3,
                        master4_id: this.token_master4,
                    };

                    const response = await axios.post(`https://reward-portal.kryptofighters.io/api/dojo/coach-grand`, requestBody);
                    console.log('Response data:', response);

                    this.trainee1 = response.data.trainee1;
                    this.GetCards(-1);
                    this.GetSkills(-1);
                    this.trainee2 = response.data.trainee2;
                    this.GetCards(-2);
                    this.GetSkills(-2);
                    this.grandTrainee = response.data.grand_trainee;
                    this.GetCards(-3);
                    this.GetSkills(-3);
                    
                    this.$nextTick(() => {
                        this.isShow_img_trainee1 = true;
                        this.isShow_img_trainee2 = true;
                        this.isShow_img_grandTrainee = true;

                        var discipleList = [this.trainee1, this.trainee2, this.grandTrainee];

                        discipleList.forEach((data, index) => {
                            var url1 = `https://asset.kryptofighters.io/fighter-bodypart/${data.body_part_top}.png`;
                            var url2 = `https://asset.kryptofighters.io/fighter-bodypart/${data.body_part_face}.png`;
                            var url3 = `https://asset.kryptofighters.io/fighter-bodypart/${data.body_part_skin}.png`;
                            var url4 = `https://asset.kryptofighters.io/fighter-bodypart/${data.body_part_body}.png`;
                            var url5 = `https://asset.kryptofighters.io/fighter-bodypart/${data.body_part_pant}.png`;

                            this.mergeImages((index*-1)-1, url1, url2, url3, url4, url5);
                        });
                    });

                } catch (error) {
                    console.error('Error:', error);

                    useToast().open({
                        message: 'Coach error : ' + error.message,
                        position: 'top-right',
                        type: 'error',
                    });

                    this.trainee1 = {};
                    this.cards_trainee1 = [];
                    this.skills_trainee1 = [];

                    this.trainee2 = {};
                    this.cards_trainee2 = [];
                    this.skills_trainee2 = [];

                    this.grandTrainee = {};
                    this.cards_grandTrainee = [];
                    this.skills_grandTrainee = [];
                }
                this.isLoading = false;
            }
        },
        async mergeImages(discipleNo, url1, url2, url3, url4, url5) {
            switch(discipleNo){
                case -1: this.isLoading_img_trainee1 = true;
                        break;
                case -2: this.isLoading_img_trainee2 = true;
                        break;
                case -3: this.isLoading_img_grandTrainee = true;
                        break;
            }

            try {
                const img1 = await this.loadImage(url1);
                const img2 = await this.loadImage(url2);
                const img3 = await this.loadImage(url3);
                const img4 = await this.loadImage(url4);
                const img5 = await this.loadImage(url5);

                // Create a canvas with the desired dimensions
                const canvas = this.$refs.canvas;
                const ctx = canvas.getContext('2d');

                // Clear the canvas before drawing the new image
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                const scaleFactor = 2;

                var _canvasWidth = img1.width/5 * scaleFactor;
                var _canvasHeight = img1.height/3 * scaleFactor;

                switch(discipleNo){
                    case -1: this.canvasWidth_trainee1 = _canvasWidth;
                            this.canvasHeight_trainee1 = _canvasHeight;
                            break;
                    case -2: this.canvasWidth_trainee2 = _canvasWidth;
                            this.canvasHeight_trainee2 = _canvasHeight;
                            break;
                    case -3: this.canvasWidth_grandTrainee = _canvasWidth;
                            this.canvasHeight_grandTrainee = _canvasHeight;
                            break;
                }

                // Apply the new canvas size
                canvas.width = _canvasWidth;
                canvas.height = _canvasHeight;

                // Disable image smoothing for pixel-perfect scaling
                ctx.imageSmoothingEnabled = false;

                // Scale the canvas
                ctx.scale(scaleFactor, scaleFactor);

                // Draw images on the canvas
                ctx.drawImage(img3, 0, 0);
                ctx.drawImage(img2, 0, 0);
                ctx.drawImage(img1, 0, 0);
                ctx.drawImage(img4, 0, 0);
                ctx.drawImage(img5, 0, 0);

                // Convert canvas to data URL and set to mergedImage
                switch(discipleNo){
                    case -1: this.mergedImage_trainee1 = canvas.toDataURL('image/png');
                            this.isLoading_img_trainee1 = false;
                            this.isShow_img_trainee1 = true;
                            break;
                    case -2: this.mergedImage_trainee2 = canvas.toDataURL('image/png');
                            this.isLoading_img_trainee2 = false;
                            this.isShow_img_trainee2 = true;
                            break;
                    case -3: this.mergedImage_grandTrainee = canvas.toDataURL('image/png');
                            this.isLoading_img_grandTrainee = false;
                            this.isShow_img_grandTrainee = true;
                            break;
                }
            } catch (error) {
                console.error('Error merging images:', error);
                this.isLoading_img_disciple = false;
            }
        },
        loadImage(src) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = "Anonymous";
                img.onload = () => resolve(img);
                img.onerror = reject;
                img.src = src;
            });
        }
    }
}
</script>
  
<style scoped>
.no-space {
    margin: 0;
    padding: 0;
}

.material-container {
    display: flex;
    justify-content: center;
    align-content: center;
    /* border: 1px solid green;  */
    padding-top: 20px;
}

.material-box {
    height: auto;
    width: 50%;
    justify-content: flex-start;
    /* padding: 0px 15px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.material-use {
    /* border: 1.5px solid lightslategrey;
    border-radius: 10px; */
    height: 70%;
    width: 79%;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.material-use2 {
    flex-direction: column;
    height: auto;
}

.pair-master-box {
    display: flex;
}

.token-input-container {
    width: 80%; 
    display: inline-flex; 
    justify-content: center; 
    gap: 10px; 
    align-items: center; 
    margin-bottom: 15px;
}

.material-icon {
    /* border: 1px solid red;  */
    height: 100%; 
    width: 100%; 
    margin: 0; 
    padding: 0;
}

.material-total {
    /* border: 1px solid red;  */
    height: 25%; 
    width: 100%; 
    text-align: center; 
    align-items: center; 
    display: flex; 
    justify-content: center;
    font-size: 100%;
    color: white;
}

.material-total .current-fragment {
    color: lightgreen;
}

.craft-btn {
    width: 10%;
}

.input-form{
    height: 40px;
    color: white;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: contents;
    align-items: center;
    width: auto;
}

.input-field {
    border: 1px solid #3c424d;
    appearance: none;
    background-clip: padding-box;
    border-radius: 8px;
    display: block;
    outline: none;
    padding: 5px 10px;
    transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    width: 100%;
}

.info-text {
    font-weight: bold; 
    font-size: 16px; 
    color: grey;
}

.info-row {
    width: 100%; 
    display: flex; 
    align-items: center; 
    font-size: 15px;
}

.info-img-icon {
    width: 20px;
    height: 20px;
}

.info-img-display {
    display: inline-flex; 
    gap: 6px;
}

.trait-row .col-md-3 {
    width: 50%;
}

.generation-box {
    width: auto; 
    white-space: nowrap; 
    display: grid; 
    place-items: center;
}

.trainee-box {
    height: auto; 
    display: flex; 
    width: 100%; 
    /* margin-top: -40px; */
}

.info-box {
    height: auto;
    /* width: 50%; */
    line-height: normal;
}

.info-container {
    display: grid; 
    grid-template-columns: 50% 50%; 
    width: 100%; 
    grid-row-gap: 5px;
}

@media (max-width: 768px) {
    .craft-btn {
        width: 30%;
    }

    .material-box {
        height: 300px;
        width: 300px;
        margin-bottom: 50px;
    }

    .material-use {
        height: 70%;
        width: auto;
        min-width: 49%;
        flex-direction: row;
        margin: 15px 0;
    }

    .material-container {
        flex-direction: column;
    }

    .pair-master-box {
        flex-direction: column;
    }

    .trainee-box { 
        margin-top: 0;
    }

    .info-container {
        display: flex; 
        grid-template-columns: none; 
        width: 100%; 
        grid-row-gap: 0;
        flex-direction: column;
        gap: 5px;
    }

    .generation-box {
        /* margin: 40px 0 10px 0; */
    }
}

</style>