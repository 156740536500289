<template>
    <div class="product-item">
        <div v-if="isLoading" style="margin-bottom: 50px;">
            <v-skeleton-loader
            :type="'card'"
            :animation="true"
            :width="200"
            :height="300"
            theme="dark"
            ></v-skeleton-loader>
        </div>
        <div v-else>
            <a
                :href="`https://app.kryptofighters.io/fighter?id=${product.id}`"
                target="_blank"
                class="link">
                <div class="card product-card-container">
                    <div class="card-body">
                        <div class="product-header">
                            <div class="left-content product-title">
                                <div class="icon-container red-image">
                                    <img :src="product.classImgUrl" alt="Product Icon" class="icon-class" />
                                </div>
                                <span :class="[ 'product-id']"># {{ product.id }}</span>
                            </div>
                            <img v-if="product.elementUrl !== ''" :src="product.elementUrl" alt="Product Icon" class="icon-element-right" />
                        </div>
                        <div class="image-container">
                            <img :src="product.image" alt="Product Image" class="product-image" />
                            <img src="../assets/shadow.png" class="fighter-shadow" alt="Image" />
                        </div>

                        <div v-if="product.price !== 0">
                            <h4 class="product-price">{{ product.currency }} {{ product.price.toFixed(2) }}</h4>
                            <h4 class="product-usd-price">$ {{ product.usdPrice.toFixed(2) }}</h4>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

  
<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { getClassImageUrl, getElementImageUrl } from '@/utils/imageUtils.js';
import { kfServiceStore } from '@/stores/kfServiceStore.ts';
export default {
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isLoading: true,
        }
    },
    methods: {

        async fetchImage() {
            try {
                const response = await axios.get(`https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/fighter/${this.product.id}.json`);
                var imageUrl = response.data.image;
                //classIconUrl.value = response.data.properties.class_id;
                imageUrl = imageUrl.replace("image/4x/", "card/no-bg/");

                // Update the product object with the obtained image URL
                //console.log(response.data.properties);
                //console.log(' fetching elementUrl:' + this.product.id + " - " + response.data.properties.element + " - " + this.elementUrl);
                this.product.image = imageUrl;
                this.product.class_id = response.data.properties.class_id;
                this.product.element_id = response.data.properties.element;

                this.product.classImgUrl = getClassImageUrl(response.data.properties.class_id);
                this.product.elementUrl = getElementImageUrl(response.data.properties.element);
                const image = new Image();

                image.onload = () => {
                    this.product.image = imageUrl;
                    this.isLoading = false;
                };
                image.src = imageUrl;
            } catch (error) {
                console.error('Error fetching image:', error);
                this.mintNewFighter();
                
                // Handle error loading image if necessary
            }
        },
        async mintNewFighter(){
            const store = kfServiceStore();
            await store.fetchAccessToken();

            const url = process.env.VITE_KF_SERVICE_BASE_URL + '/fighter/mint';
            const data = {
                tokenId: this.product.id,
                ticketType: 0,
            };
            const mintResponse = await axios.post(url, data, {
                    headers: {
                        Authorization: `Bearer ${store.accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });
            
        },
        navigateToFighter() {
            // Navigate to the '/fighter' route and pass the product data
            this.$router.push({
                name: 'fighter', // Assuming you have a named route for the '/fighter' route
                params: {
                    id: this.product.id,
                }
            });
        }
    },
    mounted() {
        // Fetch image URL when the component is mounted
        this.fetchImage();
    }
};
</script>
  
<style scoped>
.product-item {
    text-align: center;
    margin-bottom: 0px;
}

.product-image {
    width: 160px;
    object-fit: cover;
    margin-bottom: 10px;
}

.product-name {
    font-size: 16px;
    margin-top: 5px;
    text-align: left;
    display: inline-flex;
    /* Set display to inline-flex */
    align-items: center;
    /* Center items vertically */
    justify-content: space-between;
    width: 100%;
}


.product-usd-price {
    margin-top: 0px;
    font-size: 14px;
    color: var(--secondary-text-color);
}

.product-price {
    color: #ffffff;
}

.product-time {
    margin-top: 0px;
    padding-top: 0px;
    font-size: 14px;
    color: var(--secondary-text-color);
}



.product-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.with-class-icon {
    background-image: url('../assets/icon-stats-matic.png');
    background-size: 20px 20px;
    /* Set the size of your icon */
    background-repeat: no-repeat;
    padding-left: 30px;
    /* Adjust the padding to make space for the icon */
}



.icon-element-right {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    /* Adjust margin to create space between the two icons */
}

.left-content {
    display: flex;
    align-items: center;
}

.link {
    text-decoration: none;
}

.image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Margin between product image and shadow */
.shadow-image {
    margin-top: 10px;
    /* Adjust margin as needed */
    width: 100px;
    height: 10px;
    object-fit: contain;
    /* Ensure the shadow image fits within its container */
}


.product-card-container:hover {
    background-color: rgba(255, 255, 255, 0.089); /* New border color on hover */
}

.product-card-container {
    background: linear-gradient(180deg, rgba(13, 10, 22, 0.8), rgba(255, 255, 255, 0.07));  /* New border color on hover */
    border-color: rgba(255, 255, 255, 0);
}

.fighter-shadow {
    position: relative; 
    top: -30px;
    width: 100px; 
    height: 10px;
}

.product-title{
    background-color: #232323;
    padding: 2px 6px;
    border-radius: 5px;
}

.text-element-ROCK {
    color: rgb(232, 163, 36);
}
.text-element-WATER {
    color: rgb(55, 118, 226);
}

.text-element-DARK {
    /* -webkit-text-stroke-width: 0.1px; 
    -webkit-text-stroke-color: white; */
    color: rgb(158, 84, 208)}
.text-element-CHAOS {
    color: rgb(211, 80, 211);
}
.text-element-FIRE {
    color: rgb(234, 102, 62);
}
.text-element-METAL {
    color: rgb(179, 187, 198);
}
.text-element-WIND {
    color: rgb(72, 197, 220);
}
.text-element-EARTH {
    color: rgb(73, 208, 73);
}
.text-element-LIGHT {
    color: rgb(235, 233, 233);
}

.red-image {
  filter: hue-rotate(45deg); /* Change the hue to red (45 degrees) */
}


</style>