<template>
  <div class="page-content content-wrap" :style="{ display: isLoading ? 'none' : 'block' }">
    <div class="main-content">
      <div
        class="d-flex align-items-left"
        style="margin-bottom: 40px;"
      >
        <!--<img
          :src="require('@/assets/Icon-stats-owners.png')"
          alt="NFT Icon"
          class="header-icon mr-3"
          style="width:50px;"
        >-->
        <h1>Rewards</h1>
      </div>

      <div style="width: 100%; height: 240px; overflow: hidden; display: flex; align-items: center; position: relative; border-radius: 10px;">
        <img
          :src="imageUrls['banner']" 
          @load="onImageLoad" 
          @error="onImageError"
          alt="BG Image"
          style="width: 100%; height: auto;"
        >
        <div
          class="image-gradient"
          style="position: absolute; left: 0; top: 0; width: 100%; height: 100%; padding: 18px 24px;"
        >
          <div style="height: 100%; display: flex; flex-direction: column; justify-content: end;">
            <h3
              class=""
              style="margin-bottom: 5px;"
            >
             Complete Quests to Unlock Rewards!
            </h3>
            <div
              class=""
              style="margin-bottom: 10px; font-size: 14px; font-weight: 200;"
            >
              Join an exclusive airdrop for users who supported KryptoFighters from the beginning, contributing to its early development and success.
            </div>
            <div style="">
              <a
                href="https://zealy.io/cw/kryptofighters/questboard"
                class="btn btn-primary"
                style="margin-right: 10px;"
              >Get Started</a>
              <a
                href="https://zealy.io/cw/kryptofighters/questboard"
                class="btn btn-secondary"
              >Learn more</a>
            </div>
          </div>
        </div>
      </div>

      <div>
        <RewardTab :amounts="amounts" @updateLoadedRewards="updateLoadedRewards"/>
      </div>
    </div>
  </div>
  <div v-if="isLoading" class="loading-content" style="/*padding: 50px 0px;*/" >
      <div class="loading-bar"></div>
  </div>
</template>

<script>

import Web3 from 'web3';
import { timeAgo, convertToTimestamp } from '@/utils/timeUtils.js';
import { convertToUSD } from '@/utils/exchangeUtils.js';
import { useExchangeStore } from '@/stores/exchangeRate.ts';
import { useStore } from '@/stores/index.ts';

import RewardTab from '@/components/reward/RewardTab.vue';

import { ref, onMounted, watch } from 'vue';
import axios from 'axios';

export default {
    components: {
        RewardTab,
    }, data() {
        return {
            amounts: {},
            isLoading: true,
            imageUrls: {'banner': require('@/assets/backgrounds/airdrop.png')},
            totalImages: 0,
            loadedImages: 0
        };
    },
    async mounted() {
      this.totalImages = Object.keys(this.imageUrls).length;
      this.loadedImages = 0;
    },
    methods: {
      updateLoadedRewards(isAllSuccess){
        if(isAllSuccess){
          this.checkAllImagesLoaded(true);
        }
      },
      onImageLoad() {
          this.loadedImages += 1;
          this.checkAllImagesLoaded();
      },
      onImageError() {
          this.loadedImages += 1;
          this.checkAllImagesLoaded();
      },
      async checkAllImagesLoaded(isLoadedRewardSuccess = false) {
          console.log(`loaded object: ${this.loadedImages}/${this.totalImages}, isLoadedRewardSuccess: ${isLoadedRewardSuccess}`);
          if ((this.loadedImages === this.totalImages) ) {
              await new Promise(resolve => setTimeout(resolve, 1000));
              this.isLoading = false;
          }
      }
        // formattedFrameAmount(amount) {
        //     if (amount > 0) {
        //         return `(${amount})`;
        //     } else {
        //         return "";
        //     }
        // },
        // activateTab(tabName) {
        //     this.activeTab = tabName;
        // }
    }
}
</script>
  
  
<style scoped>
/* Add your component-specific styles here */

.nav-tabs {
    color: gray;
    border: 0;
    border-bottom: 1px solid var(--primary-border-color);
}

.nav-tabs .nav-link.active {
    background-color: var(--soft-background-color);
}

.nav-tabs .nav-link.inactive {
    background-color: var(--soft-background-color);
}


/* Change the background color of inactive tabs */
.nav-tabs .nav-link :hover {
    border-color: var(--primary-border-color);

}

.image-gradient{
    background: linear-gradient(to top, rgb(16, 0, 32, 0.8), rgba(11, 0, 32, 0.6), rgba(61, 4, 86, 0) 80%);
}

.loading-bar {
    width: 100%;
    height: 2px;
    background-color: var(--primary-background-color);
    position: relative;
    overflow: hidden;
    border-radius: 0px; 
}

.loading-bar:before {
    content: "";   
    position: absolute;
    top: 0;
    left: -100%;             
    width: 100%;            
    height: 100%;           
    background-image: linear-gradient(100deg, #df4bd3 50%, #8322ce 100%);
    animation: loading 2s linear infinite;
}

.loading-content {
    min-height: 650px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    position: relative;
    z-index: 1;
    /* top: -650px; */
    background-color: #100f17;
}

/* Animation definition */
@keyframes loading {
  0% {
    left: -100%;            /* Start off-screen to the left */
  }
  100% {
    left: 100%;             /* Move fully to the right */
  }
}
</style>