<!-- FightersTab.vue -->
<template>
  <div>
    <div class="d-flex align-items-center justify-content-between default-row">
      <h4>{{ products.length }} Suits </h4>
    </div>
    <div class="card">
      <div class="card-body">
        <div v-if="products.length > 0">
          <div class="product-list">
            <ProductItem
              v-for="(product, index) in products"
              :key="index"
              :product="product"
            />
          </div>
        </div>
        <div v-else>
          <img
            src="~@/assets/no-data.png"
            alt="no data"
            style="display: block; margin-left: auto; margin-right: auto; width: 160px;"
          >
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import Web3 from 'web3';
import ProductItem from '@/components/NftItem.vue'; // Update the path based on your project structure
import { timeAgo, convertToTimestamp } from '@/utils/timeUtils.js';
import { convertToUSD } from '@/utils/exchangeUtils.js';
import { useExchangeStore } from '@/stores/exchangeRate.ts';
import { useStore } from '@/stores/index.ts';
import NFTERC1155ABI from '@/contracts/NFTERC1155ABI.json';

import { ref, onMounted, watch } from 'vue';
import axios from 'axios';



export default {
    components: {
        ProductItem,
    },
    props: {
        amounts: {}
    }, data() {
        return {
            products: [],
            store: null
        };
    },
    mounted() {
        this.store = useStore();
        if(this.store.getWalletAddress!=''){
            this.fetchData();
        }else{
            watch(() => this.store.getWalletAddress, () => {
                this.fetchData();
            });
        }
        //this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                const store = this.store;
                const accountAddress = store.getWalletAddress;
                
                const collection = "0xd333D2901627fadA9B71897E9De04B473093b8F4";
                const requestBody = {
                    size: 50,
                    filter: {
                        blockchains: ['KROMA'],
                        owners: [`ETHEREUM:${accountAddress}`],
                        collections: [`KROMA:${collection}`]
                    }
                };
                const aApiUrl = `https://api.rarible.org/v0.1/ownerships/search`;
                const headers = {
                        'accept': 'application/json',
                        'x-api-key': process.env.VITE_RARIBLE_API_KEY
                    };
                const response = await axios.post(aApiUrl, requestBody, {headers});
                console.log("suitsResponse ",response);

                var totalAmount = 0;
                const combinedData = [...response.data.ownerships];
                
                this.products = await Promise.all(combinedData.map(async (nft) => {
                    try{
                        totalAmount += parseInt(nft.value);
                    }catch(error){}
                    
                    try {
                        const response = await axios.get(`https://api.rarible.org/v0.1/items/KROMA:${collection}:${nft.tokenId}`, {headers});
                        console.log("response ",response);
                        return {
                            id: nft.tokenId, 
                            itemId: -1,
                            price: 0,
                            currency: '',
                            listDate: '', 
                            dateString: '',
                            usdPrice: 0, 
                            image: response.data.meta.content[0].url,
                            type: response.data.meta.name,
                            description: response.data.itemCollection.name,
                            amount: response.data.supply,
                            isOwner: true
                        };
                    } catch (error) {
                        console.error(`Error fetching metadata for tokenId ${0}:`, error);
                        return null;
                    }
                }));
                this.products = this.products.filter(f=>f && f != null);
                console.log("Suits products" ,this.products);

                console.log("suits" + totalAmount);
                this.amounts["suits"] = totalAmount;
                return;

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }
}
</script>

<style scoped>
/* Add your component-specific styles here */

.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}
</style>
  