<template>
  <div class="page-content content-wrap">

    <div class="main-content">
      <div class="d-flex align-items-center justify-content-between default-top-space head-title">
        <div>
          <h1>KryptoFighters: The Endless Fight</h1>
          <p>Light Strategy RPG</p>
        </div>

        <div class="d-flex align-items-center justify-content-between default-top-space button-play">
          <button
            class="btn btn-secondary"
            style="margin-right: 8px; margin-left: auto; height: 40px; width: 48px; align-items: center; display: flex;"
            type="button"
            @click="navigateToiOS"
          >
            <i
              class="fa fa-brands fa-apple"
              style="width: 20px;font-size: 20px;"
            />
          </button>
          <button
            class="btn btn-secondary"
            style="margin-right: 8px; margin-left: auto; height: 40px; width: 48px; align-items: center; display: flex;"
            type="button"
            @click="navigateToAndroid"
          >
            <i
              class="fa fa-brands fa-android"
              style="width: 20px;font-size: 20px;"
            />
          </button>
                   
          <button
            class="btn btn-primary"
            style="margin-right: 8px; height: 40px; width: 120px; "
            type="button"
            @click="navigateToUrl"
          >
            Play
          </button>
        </div>
      </div>

      <div class="card">
        <div class="card-body cover-video video-container">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/AegsqHfl4Ck"
            frameborder="0"
            allowfullscreen
          />
        </div>
      </div>

      <div class="row default-top-space">
        <div class="col-md-7">
          <h5 class="stat-value">
            Overview
          </h5>
          <div class="card">
            <div class="card-body">
              Forge your legacy in the arena of 2D pixel street fighting with cutting-edge battle strategy NFT-based game. Battle and collect your unique fighter from a diverse range of NFT characters! Join our Discord channel for free access to the Private Beta! 
              <div>
                <br>
                <strong>Guildbook: </strong><br>
                <a
                  href="https://guidebook.kryptofighters.io/"
                  target="_blank"
                >https://guidebook.kryptofighters.io</a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-5">
          <h5 class="stat-value">
            Game Info
          </h5>
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-between ">
                <div class="text-solf-light">
                  Platform
                </div>
                <vue-feather
                  type="smartphone"
                  style="width: 20px; margin-left: auto;"
                />
                <vue-feather
                  type="monitor"
                  style="width: 20px;margin-left: 6px;"
                />
              </div>
              <hr>
              <div class="d-flex align-items-center justify-content-between">
                <div class="text-solf-light">
                  Developer
                </div>
                <div>Maxma Game</div>
              </div>
              <hr>
              <div class="d-flex align-items-center justify-content-between">
                <div class="text-solf-light">
                  Recommended age
                </div>
                <div>12</div>
              </div>
              <hr>
              <div class="d-flex align-items-center justify-content-between">
                <div class="text-solf-light">
                  Official Website:
                </div>
                <div>https://kryptofighters.io</div>
              </div>

              <div class="d-flex align-items-lef  default-top-space">
                <div class="rounded-border">
                  TURN-BASED
                </div>
                <div class="rounded-border">
                  STRATEGY
                </div>
                <div class="rounded-border">
                  ADVENTURE
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
<div class="card">
                <div class="card-body cover-image">
                    <img src="../assets/images/kf-cover-01.jpeg" alt="">
                    
                </div>
            </div>
*/
export default {
    methods: {
        navigateToUrl() {
            window.location.href = 'https://elixir.games/browse/krypto-fighters';
        },
        navigateToiOS() {
            window.location.href = 'https://testflight.apple.com/join/odnfQIkv';
        },
        navigateToAndroid() {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.maxma.kryptofighters';
        },
    },
};
                    
</script>

<style scoped>
.cover-image img {
    width: 100%;
    height: auto;
    max-height: 100%;
    /* Ensures the image doesn't exceed the parent's height */
}

.rounded-border {
  border: 1px solid #ccc; /* Add a border with a color of your choice */
  border-radius: 6px; /* Adjust the border radius as needed */
  padding: 5px 10px; /* Add padding to create space around the text */
  margin-right: 10px; /* Add margin between items */
}

.video-container {
    position: relative;
    padding-top: 56.25%; /* 16:9 aspect ratio (height / width) */
    overflow: hidden;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.app-button {
    height: 40px;
    transition: filter 0.3s;
}
.app-button:hover{
    filter: brightness(80%);
}

@media (max-width: 768px) {
    .head-title {
        flex-wrap: wrap;
        padding-bottom: 20px;
    }

    .button-play {
        width: 100%;
        justify-self: end;
    }

    .rounded-border {
        font-size: 12px;
    }
}


</style>