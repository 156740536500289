<!-- FightersTab.vue -->
<template>
  <div>
    <div class="d-flex align-items-center justify-content-between default-row">
      <h4>{{ products.length }} Tickets </h4>
    </div>


    <div class="card">
      <div class="card-body">
        <div v-if="products.length > 0">
          <div class="product-list">
            <ProductItem
              v-for="(product, index) in products"
              :key="index"
              :product="product"
              :type="product.type"
            />
          </div>
        </div>
        <div v-else>
          <img
            src="~@/assets/no-data.png"
            alt="no data"
            style="display: block; margin-left: auto; margin-right: auto; width: 160px;"
          >
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>

import Web3 from 'web3';
import ProductItem from '@/components/NftItem.vue'; // Update the path based on your project structure
import { timeAgo, convertToTimestamp } from '@/utils/timeUtils.js';
import { convertToUSD } from '@/utils/exchangeUtils.js';
import { useExchangeStore } from '@/stores/exchangeRate.ts';
import { useStore } from '@/stores/index.ts';

import NFTERC721ABI from '@/contracts/NFTERC721ABI.json';
import FighterTicketERC721ABI from '@/contracts/FighterTicketERC721ABI.json';

import { ref, onMounted, watch } from 'vue';
import { ethers } from 'ethers';
import axios from 'axios';

export default {
    components: {
        ProductItem,
    },
    props: {
        amounts: {}
    }, data() {
        return {
            products: [],
        };
    },
    mounted() {
        this.store = useStore();
        if(this.store.getWalletAddress!=''){
            this.fetchData();
        }else{
            watch(() => this.store.getWalletAddress, () => {
                this.fetchData();
            });
        }
    },
    methods: {
        async fetchNFTData(baseURI, itemList) {

            const provider = new ethers.providers.JsonRpcProvider(process.env.VITE_RPC_URL);
            const ticketContractAddress = process.env.VITE_TICKET_CONTRACT_ADDRESS;
            const ticketContract = new ethers.Contract(ticketContractAddress, FighterTicketERC721ABI, provider);
            // console.log("ticket tab:", nftResponse);
            for (const row of itemList) {
                try {

                    const apiUrl = `https://api.rarible.org/v0.1/items/KROMA:${ticketContractAddress}:${row.tokenId}`;

                    const headers = {
                        'accept': 'application/json',
                        'X-API-KEY': process.env.VITE_RARIBLE_API_KEY,
                        };

                        
                    const nftResponse = await axios.get(apiUrl, {headers}  );
                    // console.log("ticket tab:", nftResponse);
                    //const tokenURI = await ticketContract.tokenURI(row.tokenId);
                    //console.log("Metadata:", tokenURI);

                    /*const headers = {
                        "Access-Control-Allow-Origin": "*", // Required for CORS support to work
                    };*/

                    //const response = await axios.get(tokenURI);
                    //const response = await axios.get(tokenURI);
                    //console.log("Response Headers:", response.headers);
                    const product = {
                        id: row.tokenId,
                        itemId: 100,
                        price: 0,
                        currency: '',
                        listDate: '',
                        dateString: '',
                        usdPrice: 0,
                        image: nftResponse.data.meta.content[0].url,
                        type: nftResponse.data.meta.name,
                        description: nftResponse.data.meta.description,
                        isOwner: true,
                        isListing: Boolean(nftResponse.data.bestSellOrder)
                    };

                    this.products.push(product);
                } catch (error) {
                    console.error(`Error fetching data for token ID ${row.tokenId}:`, error);
                }
            }
        },

        async fetchData() {
            try {
                console.log("fetchData ");
                const store = this.store;
                const accountAddress = store.getWalletAddress;

                //const web3 = new Web3(window.ethereum);
                // const products = [];

                // try{
                //     const baseURI1 = 'https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/ticket/gen1_normal_ticket.json';
                //     const contract1 = new web3.eth.Contract(NFTERC721ABI, process.env.VITE_TICKET_CONTRACT_ADDRESS);
                //     await this.fetchNFTData(100, contract1, baseURI1, accountAddress, products);
                //     console.error("Contract1 :", contract1);
                // } catch (error) {
                //     console.error("Contract1 Error:", error);
                // }
                
                // try{
                //     const contract2 = new web3.eth.Contract(NFTERC721ABI, process.env.VITE_TICKET_V2_CONTRACT_ADDRESS);
                //     const baseURI2 = await contract2.methods.baseURI().call();
                //     console.error("Contract2 Error baseURI2:", baseURI2);
                //     await this.fetchNFTData(102,contract2, baseURI2, accountAddress, products);
     
                // } catch (error) {
                //     console.error("Contract2 Error:", error);
                // }

                // const TicketResponse = await axios.get(`https://api.opensea.io/api/v2/chain/matic/account/${accountAddress}/nfts?collection=kf-rare-genesis`, {
                //     headers: {
                //         'accept': 'application/json',
                //         'x-api-key': process.env.VITE_OPENSEA_API_KEY
                //     }
                // });

                // console.log("TicketResponse ",TicketResponse);

                // const contract1 = new web3.eth.Contract(NFTERC721ABI, process.env.VITE_TICKET_CONTRACT_ADDRESS);

                // Genesis 1
                const baseURI1 = 'https://s3.ap-southeast-1.amazonaws.com/metadata.kryptofighters.io/ticket/gen1_normal_ticket.json';
                // Genesis 2
                //const contract2 = new web3.eth.Contract(FighterTicketERC721ABI, process.env.VITE_TICKET_CONTRACT_ADDRESS);
                //const baseURI2 = await contract2.methods.baseURI().call();


                //console.log("baseURI2 ",baseURI2);
                // Rare Genesis
                //const baseURI3 = 'https://api.opensea.io/api/v2/chain/matic/account/${accountAddress}/nfts?collection=kf-rare-genesis';

                try{
                    const generalCollection = process.env.VITE_TICKET_CONTRACT_ADDRESS;
                    const generalRequestBody = {
                        size: 50,
                        filter: {
                            blockchains: ['KROMA'],
                            owners: [`ETHEREUM:${accountAddress}`],
                            collections: [`KROMA:${generalCollection}`]
                        }
                    };
                    const generalApiUrl = `https://api.rarible.org/v0.1/ownerships/search`;
                    const generalResponse = await axios.post(generalApiUrl, generalRequestBody, {
                        headers: {
                            'accept': 'application/json',
                            'x-api-key': process.env.VITE_RARIBLE_API_KEY
                        }
                    });
                    console.log("generalResponse ",generalResponse);

                    await this.fetchNFTData(baseURI1, generalResponse.data.ownerships);
                } catch (error) {
                    console.error("Contract1 Error:", error);
                }

                // Now you have the products from both contracts in the same products array.
                // console.log("All Products:", this.products.length);
                // this.products = products;
                this.amounts["ticket"] = this.products.length;

                // this.products = TicketResponse.data.nfts.map(nft => {
                //     const tokenID = nft.identifier;
                //     const dateString = timeAgo(convertToTimestamp(nft.updated_at));
                //     const price = 0;
                //     const usdPrice = 0;
                    
                    
                //     return {
                //         id: tokenID,
                //         price: price,
                //         currency: '',
                //         listDate: '',
                //         dateString: dateString,
                //         usdPrice: usdPrice,
                //         isOwner: true
                //     };
                // });
                

            } catch (error) {
                console.error("Error:", error);
            }
        }
    }
}
</script>

<style scoped>
/* Add your component-specific styles here */

.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}
</style>
  