<template>
  <div
    id="editScholarshipPopup"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="editScholarshipPopup"
    aria-hidden="true"
    @click.self="closeModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="exampleModalLabel" class="modal-title">{{ localData?.name?? 'Name' }}</h5>
          <button
            ref="Close"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="updateEditData" class="d-flex justify-content-center align-items-center" style="flex-direction: column; gap: 15px;">
            <div class="d-flex justify-content-center" style="gap: 10px; width: 100%;">
              <!-- <div id="edit_name" class="d-flex align-items-center" style="gap: 10px; width: 100%;">
                <span class="text-field" style="">Name : </span>
                <input
                  id="new_name"
                  v-model="localData.name"
                  type="text"
                  class="input-field" 
                  placeholder=""
                  required
                >
              </div> -->
              <div id="edit_wallet" class="d-flex align-items-center" style="gap: 10px; width: 100%;">
                <span class="text-field" style="">Wallet : </span>
                <input
                  id="new_wallet"
                  v-model="localData.wallet"
                  type="text"
                  class="input-field" 
                  placeholder=""
                  required
                >
              </div>
            </div>
            <button class="btn btn-primary" type="submit" style="width: fit-content;">Confirm</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
      return {
        showModal: false,
        localData: { ...this.data }
      };
  },
  watch: {
    data: {
        handler(newVal) {
          console.log('Message prop updated:', newVal);
          this.localData = { ...newVal };
        },
        deep: true
      }
  },
  methods: {
    closeModal() {
      console.log('closeModal');
      this.$refs.Close.click();
    },
    updateEditData(){
      this.$emit('updateEditData', this.localData);
      this.closeModal();
    }
  },
};
</script>
  
<style scoped>
/* .modal-overlay {
display: none;
}

.modal.fade.show .modal-overlay {
display: block;
} */
.input-field {
    border: 1px solid #3c424d;
    appearance: none;
    background-clip: padding-box;
    border-radius: 8px;
    display: block;
    outline: none;
    padding: 5px 10px;
    transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    width: 100%;
}

.text-field {
  font-weight: 500;
  white-space: nowrap;
}
</style>
  