<template>
  <div class="page-content content-wrap">
    <div class="main-content">
      <div class="d-flex align-items-left">
        <h1>Account Settings</h1>
      </div>
      <div class="general-setting">
        <div class="sub-setting-title">
          General Settting
        </div>
        <div class="general-container">
          <div class="left-side">
            <div class="left-side-container">
              <div class="general-setting-blog">
                <div class="title-blog">
                  Name
                </div>
                <form class="input-form">
                  <div
                    class=""
                    style="flex: 5 1;"
                  >
                    <div
                      class=""
                      style="box-sizing: border-box;"
                    >
                      <div
                        class=""
                        style="position: relative;"
                      >
                        <input
                          class="input-field"
                          value="Fighterian #"
                        >
                      </div>
                    </div>
                  </div>
                  <button
                    disabled
                    class="btn btn-primary"
                  >
                    Save Change
                  </button>
                </form>
              </div>
              <div class="general-setting-blog">
                <div class="title-blog">
                  Email
                </div>
                <div class="input-form">
                  <div
                    class=""
                    style="flex: 5 1;"
                  >
                    <div
                      class=""
                      style="position: relative;"
                    >
                      <input
                        id="linked-email"
                        disabled=""
                        class="input-field show-data-field"
                        value=""
                      >
                    </div>
                  </div>
                  <button
                    v-if="isLinkAccountDisabled"
                    class="btn default-button"
                    @click="UnbindEmail"
                  >
                    Unlink email
                  </button>
                  <button
                    v-else
                    data-bs-toggle="modal"
                    data-bs-target="#linkAccountPopup"
                    class="btn btn-primary"
                    @click="openModal"
                  >
                    Link email
                  </button>
                  <LinkAccountPopup
                    :access-token="accessToken"
                    @refreshData="refreshData"
                  />
                </div>
              </div>
              <div class="general-setting-blog">
                <div class="blog-title">
                  Password
                </div>
                <div>
                  <button
                    class="btn btn-primary"
                    :disabled="true"
                  >
                    <svg
                      viewBox="0 0 24 24"
                      width="20"
                      height="20"
                      style="fill: currentcolor;"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.26 14.159a.529.529 0 0 1 .595-.098 6.002 6.002 0 0 0 6.732-9.704 6 6 0 0 0-9.674 6.796.529.529 0 0 1-.096.6l-1.834 1.835a1 1 0 0 0-.293.69l-.013.756a.5.5 0 0 1-.491.492l-1.499.025a.5.5 0 0 0-.492.491l-.025 1.499a.5.5 0 0 1-.491.492l-1.499.025a.5.5 0 0 0-.492.491l-.038 2.287a.5.5 0 0 0 .508.509l1.545-.026a1 1 0 0 0 .69-.293l6.867-6.867ZM17.89 8.6a1.8 1.8 0 1 1-2.546-2.546A1.8 1.8 0 0 1 17.89 8.6Z"
                        fill="currentColor"
                      />
                    </svg>Change password
                  </button>
                </div>
              </div>
              <div class="general-setting-blog">
                <h5 class="sub-setting-title">
                  Notification settings
                </h5>
                <div>
                  <input
                    type="checkbox"
                    class="switch-module_input__YKZRj"
                    checked=""
                  >
                  Send me an email whenever I successfully sell an asset.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import Web3 from 'web3';
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import { useStore } from '@/stores/index.ts';

import { kfServiceStore } from '@/stores/kfServiceStore.ts';

import { useToast } from 'vue-toast-notification';
import { getSignature } from '@/utils/web3Utils';
import LinkAccountPopup from '@/components/popup/LinkAccountPopup.vue';

export default {
    components: {
        LinkAccountPopup,
    }, data() {
        return {
            showModal: false,
            baseUrl: process.env.VITE_KF_SERVICE_BASE_URL,
            accessToken: '',
            isLinkAccountDisabled: true,
            linkedEmail: ''
        };
    },
    mounted() {
        this.Authen().then(() => {
            this.store = useStore();
            if(this.store.getWalletAddress!=''){
                this.RegistrationInquiry();
            }else{
                watch(() => this.store.getWalletAddress, () => {
                    this.RegistrationInquiry();
                });
            }
        });
    },
    methods: {
        openModal() {
            this.showModal = true;
        },
        RandomRefTransID(){
            let refID = '';

            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            for (let i = 0; i < 5; i++) {
                const randomIndex = Math.floor(Math.random() * characters.length);
                refID += characters.charAt(randomIndex);
            }

            const currentDate = new Date();
            const formattedDate = currentDate.toLocaleDateString('en-GB', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }).replace(/\//g, '');

            refID += formattedDate;
            refID += useStore().getWalletAddress.slice(-5);
            
            console.log(refID);

            return refID;
        },
        async Authen() {
            const store = kfServiceStore();
            await store.fetchAccessToken();
            this.accessToken = store.accessToken;
            //console.log("accessToken" , this.accessToken);
        },
        async RegistrationInquiry() {
            try {
                const apiUrl = 'https://service.kryptofighters.io/api/user/registration-inquiry';

                console.log(useStore().getWalletAddress);

                const requestBody = {
                    walletAddress: useStore().getWalletAddress,
                    refTransId: this.RandomRefTransID()
                };

                console.log('requestBody:', requestBody);

                const response = await axios.post(apiUrl, requestBody, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.accessToken}`
                    }
                });

                console.log('Response data:', response.data.reponseCode);
                var inputEmail = document.getElementById("linked-email");

                if(response.data.reponseCode === '200'){
                    console.log('Response data: 200');
                    this.isLinkAccountDisabled = false;
                    inputEmail.value = "No email";
                }
                else{
                    console.log('Response data:', response.data.email);
                    this.isLinkAccountDisabled = true;
                    this.linkedEmail = response.data.email;
                    inputEmail.value = response.data.email;
                }

                console.log('Response data:', response.data);
            } catch (error) {
                console.error('Error:', error);
            }
        },
        refreshData() {
            this.Authen().then(() => {
                this.store = useStore();
                if(this.store.getWalletAddress!=''){
                    this.RegistrationInquiry();
                }else{
                    watch(() => this.store.getWalletAddress, () => {
                        this.RegistrationInquiry();
                    });
                }
            });
        },
        async UnbindEmail(email, OTPCode) {
            try {
                
                //const apiUrl = this.baseUrl +'/user/unlink-email';
                const { signature, challengeMessage, address } = await getSignature();

                const apiUrl = 'https://reward-portal.kryptofighters.io/api/protect/unbind-email';

                const requestBody = {
                    signature,
                    address,
                    challengeMessage,
                    walletAddress: useStore().getWalletAddress,
                    email: this.linkedEmail,
                    refTransId: this.RandomRefTransID(),
                };

                const response = await axios.post(apiUrl, requestBody, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.accessToken}`
                    }
                });

                this.refreshData();
                
                useToast().open({
                        message: 'Unlink email success!',
                        position: 'top-right',
                        type: 'success',
                    });

                //console.log('Response data:', response.data);
            } catch (error) {
                //console.error('Error:', error);

                const errorMessage = error.message.length > 64 ? error.message.slice(0, 64) + '...' : error.message;
                useToast().open({
                        message: 'Unlink email error : ' + errorMessage,
                        position: 'top-right',
                        type: 'error',
                    });
            }
        },
    }
}
</script>
  
<style scoped>
.sub-setting-title {
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 1.5rem;
}

.general-container .left-side {
    display: flex;
    align-items: flex-start;
    gap: 20px 24px;
}

.general-container .left-side .left-side-container{
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: min(100%,440px);
    flex: 1 1;
}

.general-setting-blog {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.title-blog {
    color: white;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.input-form{
    display: flex;
    gap: 12px;
    height: 40px;
    color: white;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.input-field {
    border: 0.5px solid #3c424d;
    appearance: none;
    background-clip: padding-box;
    border-radius: 8px;
    display: block;
    outline: none;
    padding: 10px 12px;
    transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    width: 100%;
}



.show-data-field {
    color: #8e97a8;
    background-color: #282c34;
    border-color: #3c424d;
}

.change-password-button {
    display: flex;
    gap: 8px;
    background: #282c34;
    color: white;
    cursor: pointer;
    border-radius: 8px;
    font-size: 14px;
    height: 40px;
    line-height: 20px;
    padding: 0 16px;
    align-items: center;
    border: 1px transparent;
    font-weight: 500;
    justify-content: center;
    position: relative;
    text-align: center;
    touch-action: manipulation;
}
</style>